import React from 'react'
import cascading from '../assests/css/all.module.css';
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { getTokenPricesFromFarm } from '../hooks/useFarms';
import { getNativeAddress } from '../hooks/useNativeToken';
import { useState } from 'react';
import { dateTimeFormat } from '../lib/DateFormat'
import { useNavigate } from 'react-router-dom';
import { MdOutlineArrowForward } from "react-icons/md";


export default function Launchpaddashcard(props) {
    console.log(props, "Launchpaddashcard");
    const navigate = useNavigate()


    const {datas,allocBal} =  props
    const [totalShareInUsd,settotalShareInUsd] = useState('')
    const [XinUsd,setXinUsd] = useState('')



    useEffect(()=>{
        setUSDPrice()
    },[datas,allocBal])

    const setUSDPrice = async()=>{
        try{
            let farms  =  localStorage.getItem('FARMS')
            let price =  await getTokenPricesFromFarm(farms)
            console.log("farms________price",price)
            let nativeAddress  =  getNativeAddress()
            console.log("nativeAddress",nativeAddress)
            let XinUsd  =  price[nativeAddress.toLowerCase()]
            console.log("XinUsd",XinUsd)
            setXinUsd(parseFloat(XinUsd))
            let totalShareInUsd  =  parseFloat(XinUsd)*parseFloat(props.allocBal)
            console.log("totalShareInUsd",totalShareInUsd,props.allocBal)
            settotalShareInUsd(parseFloat(totalShareInUsd))
        }catch(err){
            console.log(err,'setUSDPrice__err')
        }
    }
    return (
        <div className={`${cascading.dashcard} mb-4`} onClick={props.navi}>
            <div className={`${cascading.topsec} mb-3`}>
                <div className={`${cascading.imgsec}`}>
                    <img src={props?.datas?.logo} alt='img' className={`img-fluid ${cascading.dashimg}`} />
                </div>
                <div>
                    <p className={`${cascading.datelaunchpad}`}>{dateTimeFormat(props?.datas?.endTime*1000)}</p>

                </div>

            </div>
            <div>
                <p className={`${cascading.name}`}>{props?.datas?.name}</p>
                <p className={`${cascading.desc}`}>{props?.datas?.description}</p>
                <div className={`${cascading.innercard}`}>
                    <div>
                        <p className={`${cascading.labelname}`}>Your WL allocation</p>
                        <p className={`${cascading.value}`}>${isNaN(totalShareInUsd) ? 0 : totalShareInUsd}</p>
                    </div>
                    <div>
                        <p className={`${cascading.labelname}`}>Guaranteed WL allocation ratio</p>
                        <p className={`${cascading.value}`}>${XinUsd}/RICE</p>
                    </div>
                    {/* <div>
                <p  className={`${cascading.labelname}`}>Snapshot date</p>
                <p className={`${cascading.value}`}>{props.datas.fees}</p>
            </div> */}
                </div>
            </div>
             <div className='text-center mt-4'>
             <button className={`${cascading.connect_btn_theme}`} onClick={()=>{navigate(`/launchpaddetail/${props?.datas?.saleAddress}`)}}>Launch Detail <MdOutlineArrowForward className='ms-2'/></button>
             </div>
            {/* <p className={`${cascading.datelaunchpad} mt-4`}>The presale of the $MNY will be held across two stages, $1,320,000 will be raised, with the first stage only accessible by whitelisted participants. In addition to Arcton's own whitelist, xGRAIL holders who allocate to the 'Launchpad' plugin will get <span className={`${cascading.datelaunchpadtheme} mt-4`}>exclusive whitelist access</span> and receive a 50% bonus of the $ARC sharedrop.</p>

            <p className={`${cascading.datelaunchpad}`}>1. xGRAIL allocators will receive a guaranteed share of $600,000 MNY allocations during the first stage (48h duration), with no cap per wallet. The first $200,000 invested on the Arcton platform will receive a 20% discount on the $MNY, the next $400,000 a 10% discount.</p>

            <p className={`${cascading.datelaunchpad}`}>2. XGRAIL allocators will receive a <span className={`${cascading.datelaunchpadtheme} mt-4`}>1.5x multiplier bonus</span> on the $ARC sharedrop allocation. The whitelisted amount is proportional to the total amount of xGRAIL allocated, and effectively invested on Arcton.</p>

            <p className={`${cascading.datelaunchpad}`}>3. The Sale of the $MNY takes place on the Arcton platform and participation in the first stage requires the opening of a user account until the Snapshot date.</p>

            <p className={`${cascading.datelaunchpad}`}>4. The last stage (1 week duration) will be completely open to the public on a FCFS basis for the remaining allocations, if any.</p>

            <p className={`${cascading.datelaunchpad}`}> Since an individuals' sharedrop allocation is proportional to the total amount of xGRAIL allocated to the plugin, and invested on Arcton, remember that your ratio could change over time as the total allocations vary.</p>
            <p className={`${cascading.datelaunchpad}`}>Presale and public sale participants will be able to claim their tokens simultaneously after the end of the TGE.</p> */}
        </div>
    )
}
