

// import constant
import {
    CHART_DATA
} from '../constants';

const initialValue = {
    chartData: []
}

//  console.log("initialValue----->>>",initialValue);
const chartPriceReducer = (state = initialValue, action) => {

    switch (action.type) {
        case CHART_DATA:
            return {
                ...state,
                chartData: action.payload
            };
   
        default:
            return state;
    }
}

export default chartPriceReducer;