import React, { useState ,useEffect} from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/apycalculatorstyle.module.css';
import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";




import { IoMdClose } from "react-icons/io";
import { FaArrowRight } from 'react-icons/fa';

//
import {useSelector} from 'react-redux'

//lib
import { toFixedNumber } from '../lib/FixedNumber';
import { isEmpty } from '../lib/isEmpty';


//validation
import { validateDeallocation } from '../validation/LaunchValidation'
//hooks
import { roundToSignificant } from '../hooks/useCommon';
import { UnBoostPosition,getYeildAddress ,GetExpectBoostMultipler, GetPoolTotalAllocation,GetBoostMultipler } from '../hooks/useyeildBooster';
import { GetMultiplierByBoostPoints ,GetBounsApr} from '../hooks/useNFTpool';
export default function Unboostmodal(props) {



  const [unboostmodal, setUnboostmodal] = useState(true);
  const { onSuccess, SetsuccessLoader, setError, record } = props
  const [poolInfo, setPoolInfo] = useState({})
  const [amount, setAmount] = useState('')
  const [errors, setErrors] = useState('')
  const [multiper, setmultiper] = useState('0')
  const [Exmultiper, setExmultiper] = useState('0')
  const [bonusPerc, setbonusPerc] = useState('0')
  const [bonusApr, setbonusApr] = useState('0')
  const [totalApr, settotalApr] = useState('0')
  const { nftpooldata } = useSelector((state) => (state.nftpool))
  const { allocationDetails } = useSelector((state) => (state.yield))
  useEffect(()=>{
    let poolInfo  =  nftpooldata.find((val)=>(val?.poolAddress?.toLowerCase() == record?.poolAddress?.toLowerCase()))
    setPoolInfo(poolInfo)
  },[nftpooldata,record])
  useEffect(()=>{
    CalculateBonusApr()
  },[bonusPerc,poolInfo])
  useEffect(()=>{
    let bonusPerc = record?.boostMultiplier*100
    setbonusPerc(bonusPerc)
  },[record])
  const CalculateBonusApr = ()=>{
    try{
      if(!isEmpty(poolInfo) && !isEmpty(record)){
        let bonusPercentage = parseFloat(record.lockMultiplier)*100 + bonusPerc
        let BonusApr =  GetBounsApr({
          annualInterestRate:poolInfo.FramBaseApr,
          bonusPercentage:bonusPercentage
        })
        BonusApr = isNaN(BonusApr) ? 0: BonusApr
        setbonusApr(BonusApr)
        let totalApr = parseFloat(BonusApr)+parseFloat(poolInfo.FramBaseApr)+parseFloat(poolInfo.SwapFeeApr)
        settotalApr(totalApr)
      }
      
    }catch(err){
      console.log('CalculateBonusApr__err')
    }
  }
  // useEffect(()=>{
  //   setMultipler()
  // },[poolInfo,record])
  //onChange func
  const SetMax = () => {
    try {
      setAmount(record.boostPoints)
      setExpectMultipler(record.boostPoints)
    } catch (err) {
      console.log(err, 'SetMax__err')
    }
  }
  const handleChange = (e) => {
    try {
      setErrors({})
      const { name, value } = e.target
      var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
      if (!numbers.test(value) && value !== "") {
        return false
      }
      if (name == 'amount') {
        setAmount(value)
        setExpectMultipler(value)
      }
    } catch (err) {
      console.log(err, 'onChange__err')
    }
  }


  const UnBoost = async () => {
    try {
      let value = {
        amount: amount,
        allocBal: record.boostPoints
      }
      let validation = validateDeallocation(value)
      if (!isEmpty(validation)) {
        setErrors(validation);
        return false
      }
      let Amount = toFixedNumber(amount * 10 ** 18)
      Amount = parseFloat(Amount).toFixed(0)
      let loaderText = 'Unboosting position.....'
      onSuccess(loaderText, 'Unboost position')
      let result = await UnBoostPosition(record.poolAddress, record.tokenId, Amount)
      if (result) {
        let successLoaderText = `You've successfully deallocated ${amount} RICE to your ${record.lpsymbol} position`
        SetsuccessLoader(successLoaderText, result.transactionHash)
      } else {
        let errorText = `Error ! When Unboost to position`
        setError(errorText)
      }
    } catch (err) {
      console.log(err, 'Boost__err')
    }
  }

  const setMultipler = async()=>{
    try{
      let poolAddress  = record.poolAddress
      let maxBoostMultiplier = toFixedNumber(poolInfo.maxBoostMultiplier*10000)
      let lpAmount =  toFixedNumber(record.amount *10**18)
      let totalLpSupply = poolInfo.totalLpSupply.toString()
      let userAllocation = toFixedNumber(allocationDetails.UserTotalAllocation *10**18)
      let multiperVal =  await GetBoostMultipler(poolAddress,maxBoostMultiplier.toString(),lpAmount.toString(),userAllocation.toString(),totalLpSupply.toString())
      console.log(multiperVal,'multiperVal',poolInfo.lpSupply)
      setmultiper(multiperVal)
    }catch(err){
      console.log(err,'setMultipler__err')
    }
  }

  const setExpectMultipler = async (amount) => {
    try {
      let lpAmount = toFixedNumber(record.amount * 10 ** 18)
      lpAmount = lpAmount.toString()
      let boostPoint = parseFloat(record.boostPoints) - parseFloat(amount)
      boostPoint = toFixedNumber(parseFloat(boostPoint) * 10 ** 18)
      boostPoint = boostPoint.toString()
      console.log('setExpectMultipler', boostPoint, amount, record?.boostPoints)
      let boostmultiplier = await GetMultiplierByBoostPoints(record.poolAddress, lpAmount, boostPoint)
      setExmultiper(boostmultiplier)
      setbonusPerc(boostmultiplier * 100)
    } catch (err) {
      console.log(err, 'setExpectMultipler___err')
    }
  }
  // const setExpectMultipler = async(amount)=>{
  //   try{
  //     let poolAddress  = record.poolAddress
  //     let maxBoostMultiplier = toFixedNumber(poolInfo.maxBoostMultiplier*10000)
  //     let lpAmount =  toFixedNumber(record.amount *10**18)
  //     let totalLpSupply = poolInfo.totalLpSupply.toString()
  //     let userAllocation = toFixedNumber(allocationDetails.UserTotalAllocation *10**18)
  //     let poolAllocation  =  await GetPoolTotalAllocation(poolAddress)
  //     userAllocation = userAllocation - toFixedNumber((amount)*10**18)
  //     poolAllocation =  parseFloat(poolAllocation) -toFixedNumber((amount)*10**18)
  //     console.log(poolAllocation,'poolAllocation',poolInfo.lpSupply)
  //     let multiperVal =  await GetExpectBoostMultipler(maxBoostMultiplier.toString(),lpAmount.toString(),userAllocation.toString(),totalLpSupply.toString(),poolAllocation.toString())
  //     console.log(multiperVal,'setExpectMultipler',poolInfo.lpSupply)
  //     setExmultiper(multiperVal)
  //   }catch(err){
  //     console.log(err,'setMultipler__err')
  //   }
  // }
  
  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={unboostmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Unboost your position</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>
              <p className={`${cascading.innerheadwhite}`}>Amount
              </p>

              <div className={`${cascading.inputsec} my-3`}>
                <div className={`input-group mb-3`}>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="0"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    name='amount'
                    value={amount}
                    onChange={(e) => { handleChange(e) }}
                  />
                  <div className={`input-group-append`} >
                    <button className={` ${cascading.inputspan}`} id="basic-addon1" onClick={() => { SetMax() }}>
                      Max
                    </button>
                  </div>
                </div>
                <p className="text-danger f-12 d-block text-left">{errors?.amount}</p>
                <p className={`${cascading.balsec}`}>allocated : {record.boostPoints} RICE</p>
              </div>

              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
              </p>


              <div className='row mt-3'>
                <div className='col-8 col-md-7'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Boost multiplier</p>
                </div>
                <div className='col-4 col-md-5'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>x{record.boostMultiplier} <FaArrowRight className='px-1' />x{Exmultiper}</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-7'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                </div>
                <div className='col-4 col-md-5'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(poolInfo.FramBaseApr,4)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-7'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Bonus APR</p>
                </div>
                <div className='col-4 col-md-5'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(bonusApr,6)}% 
                  {/* <FaArrowRight className='px-1' />0% */}
                  </p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-7'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Earned fees APR</p>
                </div>
                <div className='col-4 col-md-5'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(poolInfo.SwapFeeApr,4)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-7'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                </div>
                <div className='col-4 col-md-5'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(totalApr,4)}% 
                  {/* <FaArrowRight className='px-1' />
                  20.09% */}
                  </p>
                </div>
              </div>


              {isEmpty(amount) ?
                <Button className={`${cascading.btnstyle} mt-4`} disabled={true}>
                  Enter a amount
                </Button>
                :
                <Button className={`${cascading.btnstyle} mt-4`} onClick={() => { UnBoost()}}>
                  Unboost
                </Button>}

              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>


            </div>












            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}







          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

