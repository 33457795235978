// import constant
import {
    USER_TOKEN
} from '../constants';

const initialValue = {
   usertokens:[]

}

//  console.log("initialValue----->>>",initialValue);
const tokenReducer = (state = initialValue, action) => {

    switch (action.type) {
        case USER_TOKEN:
            return {
                ...state,
                usertokens: action.payload
            };
        default:
            return state;
    }
}

export default tokenReducer;