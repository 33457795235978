import React from 'react'
import {Modal,Button} from 'react-bootstrap';
import cascading from '../assests/css/walletstyle.module.css';
import { IoMdClose } from "react-icons/io";

import metaicon from '../assests/images/metamask.png';
import walleticon from '../assests/images/walletconnect.png';
import { useDispatch ,useSelector} from 'react-redux';
// import { walletControl } from '../Redux/action';
import { walletControl } from '../lib/localStorage';
import { MetamaskWallet, WalletConnectfun } from "../hooks/useWallet"
export default function Walletmodal(props) {
  const dispatch = useDispatch()

  //   const walletconnect =() =>{
  //     console.log('walletconnect')
  //     dispatch(walletControl('connect')) 
  //     localStorage.setItem('walletStatus','connect')
  // }
  const walletconnect = () => {
    dispatch(walletControl('connect'))
    sessionStorage.setItem('walletStatus', 'connect')
  }

 
  const MetamaskConnectWallet = async () => {
    try {
      const connection = await MetamaskWallet(dispatch);
      console.log("connection",connection)
      if(connection){
        console.log("connection",connection)
        walletconnect()
      }
    } catch (e) {
      console.log("MetamaskConnectWallet", e)
    }
  }
  const ConnectWalletConnect = async () => {
    try {
      const connection = await WalletConnectfun(dispatch);
      if(connection){
        walletconnect()
      }
    } catch (e) {
      console.log("MetamaskConnectWallet", e)
    }
  }
  return (
    <div className={`${cascading.walletmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`}>
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Connect Wallet</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />
          {/* <button className={`${cascading.closebtn}`} onClick={ props.onHide}>X</button> */}
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
           <div className={`${cascading.walletrow} text-center`}>
            <img src={metaicon} alt='metamask' className={`img-fluid ${cascading.walletimg}`} onClick={() => { MetamaskConnectWallet(); props.onHide() }}/>
            <p className={`${cascading.label} mt-3`}>Metamask</p>
           </div>
           <div className={` ${cascading.walletrow} text-center`}>
            <img src={walleticon} alt='metamask' className={`img-fluid ${cascading.walletimg}`} onClick={() => { ConnectWalletConnect(); props.onHide() }}/>
            <p className={`${cascading.label} mt-3`}>Walletconnect</p>
           </div>

        </Modal.Body>
        <Modal.Footer className={`${cascading.modalclr} ${cascading.modalfooter}`}>
            <p className={`${cascading.footerdesc}`}>Haven't got a crypto wallet yet?</p>
          <Button className={`${cascading.btnstyle}`} onClick={props.onHide}>
           Learn How to Connect
          </Button>
       
        </Modal.Footer>
      </Modal>
    </div>
  )
}

 