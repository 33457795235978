import { isEmpty } from '../src/lib/isEmpty';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { WALLET_STATUS, WALLET_ADDRESS, NATIVE_BALANCE, XTOKEN_BALANCE, SLIPPAGE, MULTI_HOPS, DEADLINE, EXPERT_MODE, NETWORK_SWITCH, GAS_PRICE, FARMS_DATA, NFTPOOL_DATA, POSITION_LIST, TOTAL_HOLDING, SPNFT_Reward, IS_LOAD_NFTPOOL, NFTPOOL_DEPOSITS, NFTPOOL_REWARD, REDEEM_ALLOCATION, REDEEM_INFO } from "../src/constants"
import { getDeadLine, getExpertMode, getGasPrice, getMultiHops, getSlippage, getWalletAddress, removeWallteAddress, setDeadLine_local, setExpertMode, setMultiHops, setSlippage } from "../src/lib/localStorage"
import { consolelog } from './lib/consolelog';
import { CHAINS } from './config/env';
import { walletControl } from '../src/lib/localStorage';

//contract 
import { BalanceNative } from './hooks/useNativeToken'
import { XBalance, GetReedDetails } from './hooks/useXtoken';

//hooks
import { getFarmsInfo } from './hooks/useFarms';
import { GetChainIndex } from './hooks/useWeb3';
import { GetPoolListInfo } from './hooks/useNFTpool';
import { GetuserPositions } from './hooks/useNFTpool'
import { GetBlockTimeStamp } from './hooks/useCommon';
import { getDividendDetails } from './hooks/useDividends';
import { getAllocationDetails } from './hooks/useyeildBooster';
import { GetRecipitsAllocation } from './hooks/useXlaunch';
import { MetamaskWallet } from "./hooks/useWallet";

//Actions 
import { createuserhook } from './Actions/User'
import { getTransactionData } from './Actions/LaunchActions'

const HelperRoute = () => {
    let dispatch = useDispatch()

    const { walletaddress } = useSelector((state) => state.wallet)
    const { nftpooldata } = useSelector((state) => (state.nftpool))
    const { nativebalance } = useSelector((state) => state.native)
    const { xtokenbalance } = useSelector((state) => (state.xtoken))
    useEffect(() => {
        setSilppageVal()

        setMultiHopsVal()

        setDeadLineVal()

        setExpertModeVal()

        setwalletStatus()

        setNetworkVal()

        setGasPriceVal()

        GetBlockTimeStamp()

    }, [])

    useEffect(() => {
        if (getWalletAddress()) {
            userLogin()
        }
    }, [walletaddress])

    useEffect(() => {
        if (localStorage.getItem('FarmInterval')) {
            let oldInterval = localStorage.getItem('FarmInterval')
            clearInterval(oldInterval);
        }
        const interval = setInterval(async () => {
            GetFarmInfo()
        }, 3000)
        localStorage.setItem('FarmInterval', interval)
    }, [])

    useEffect(() => {
        GetNFTPool()
        getRedeemDetails()
        getNativebaln()
        getXtoken()
        getDividendDetails(dispatch)
        getAllocationDetails(dispatch)
        GetRecipitsAllocation(dispatch)
    }, [walletaddress, xtokenbalance, nativebalance])

    useEffect(() => {
        console.log('getPositionList1')
        getPositionList()
    }, [nftpooldata, walletaddress, xtokenbalance])

    const getPositionList = async () => {
        try {
            console.log('getPositionList')
            if (isEmpty(getWalletAddress())) {
                dispatch({
                    type: POSITION_LIST,
                    payload: []
                })
                dispatch({
                    type: TOTAL_HOLDING,
                    payload: 0
                })
                dispatch({
                    type: SPNFT_Reward,
                    payload: 0
                })
                dispatch({
                    type: NFTPOOL_DEPOSITS,
                    payload: 0
                })
                dispatch({
                    type: NFTPOOL_REWARD,
                    payload: 0
                })
            }
            if (!isEmpty(nftpooldata) && !isEmpty(walletaddress)) {
                console.log('getPositionList1')
                let { positionsList, totalHoldings, totalSPNFT } = await GetuserPositions(nftpooldata, dispatch)
                console.log('getPositionList', positionsList, totalHoldings, totalSPNFT)
            }
        } catch (err) {
            console.log(err, 'getPositionList__err')
        }
    }
    const GetNFTPool = async () => {
        try {
            dispatch({
                type: IS_LOAD_NFTPOOL,
                payload: true
            })
            GetPoolListInfo(dispatch)
        } catch (err) {
            console.log(err, 'GetNFTPool__err')
        }
    }
    const GetFarmInfo = async () => {
        try {
            console.log(window.location.pathname.split('/')[2], 'GetFarmInfo_')
            if (window.location.pathname.split('/')[2]) {
                let chartData = await getTransactionData({ saleaddress: window.location.pathname.split('/')[2] })
                console.log("chartData", chartData)
            }
            console.log("GetFarmInfo_")
            let Farms = await getFarmsInfo()
            console.log(Farms, "GetFarmInfo_")
            dispatch({
                type: FARMS_DATA,
                payload: Farms
            })
        } catch (err) {
            console.log(err, "GetFarmInfo__Err")
        }
    }

    const getNativebaln = () => {
        try {
            if(isEmpty(getWalletAddress())){
                dispatch({
                    type: NATIVE_BALANCE,
                    payload: 0
                })
            }else{
                BalanceNative(getWalletAddress(), dispatch)
            }
            
        } catch (err) {
            consolelog('getNativebaln__err', err, true)
        }
    }
    const getXtoken = () => {
        try {
            if(isEmpty(getWalletAddress())){
                dispatch({
                    type: XTOKEN_BALANCE,
                    payload: 0
                })
            }else{
                XBalance(getWalletAddress(), dispatch)
            }
            
        } catch (err) {
            consolelog('getXtoken__err', err, true)
        }
    }

    const setwalletStatus = () => {
        try {
            if (getWalletAddress()) {
                dispatch({
                    type: WALLET_ADDRESS,
                    payload: getWalletAddress()
                })
            }
            if (!getWalletAddress()) {
                sessionStorage.setItem('walletStatus', 'disconnect')
            }
            dispatch({
                type: WALLET_STATUS,
                payload: isEmpty(getWalletAddress()) ? 'disconnect' : sessionStorage.getItem('walletStatus')
            })
        } catch (err) {
            console.log(err, 'setwalletStatus__err')
        }
    }

    const setSilppageVal = () => {
        try {
            if (!getSlippage()) {
                setSlippage(0.5)
                dispatch({
                    type: SLIPPAGE,
                    payload: 0.5
                })
            } else if (getSlippage()) {
                dispatch({
                    type: SLIPPAGE,
                    payload: getSlippage()
                })
            }
        } catch (err) {
            console.log(err, 'setSilppageVal__err')
        }
    }

    const setMultiHopsVal = () => {
        try {
            if (getMultiHops() == undefined) {
                setMultiHops(true)
                dispatch({
                    type: MULTI_HOPS,
                    payload: true
                })
            } else {
                dispatch({
                    type: MULTI_HOPS,
                    payload: JSON.parse(getMultiHops())
                })
            }
        } catch (err) {
            console.log(err, 'setMultiHopsVal__err')
        }
    }

    const setDeadLineVal = () => {
        try {
            if (!getDeadLine()) {
                setDeadLine_local(10)
                dispatch({
                    type: DEADLINE,
                    payload: 10
                })
            } else if (getDeadLine()) {
                dispatch({
                    type: DEADLINE,
                    payload: getDeadLine()
                })
            }
        } catch (err) {
            console.log(err, 'setDeadLineVal__err')
        }
    }

    const setExpertModeVal = () => {
        try {
            if (getExpertMode() == undefined) {
                setExpertMode(false)
                dispatch({
                    type: EXPERT_MODE,
                    payload: false
                })
            } else {
                dispatch({
                    type: EXPERT_MODE,
                    payload: getExpertMode()
                })
            }
        } catch (err) {
            console.log('getExpertModeVal__err', err)
        }
    }

    const setGasPriceVal = () => {
        try {
            localStorage.setItem('gasPrice', '5000000000')
            let data = {
                gasPrice: '5000000000',
                type: 'standard'
            }
            dispatch({
                type: GAS_PRICE,
                payload: data
            })
        } catch (err) {
            console.log(err, 'setGasPriceVal__err')
        }
    }
    const setNetworkVal = () => {
        try {
            dispatch({
                type: NETWORK_SWITCH,
                payload: GetChainIndex()
            })
            localStorage.setItem("CHAIN", GetChainIndex())
        } catch (err) {
            console.log(err, 'setNetworkVal__err')
        }
    }

    const userLogin = async () => {
        try {
            let user = await createuserhook({ walletaddress: getWalletAddress().toLocaleLowerCase() })
            console.log("user_wallet", user)
        } catch (err) {
            consolelog('getUser__err', err)
        }
    }

    const getRedeemDetails = async () => {
        try {
            console.log('getRedeemDetails')
            if(isEmpty(getWalletAddress())){
                dispatch({
                    type: REDEEM_ALLOCATION,
                    payload: 0
                })
                dispatch({
                    type: REDEEM_INFO,
                    payload: []
                })
            }else{
                GetReedDetails(getWalletAddress(), dispatch)
            }
            
        } catch (err) {
            console.log(err, 'getRedeemDetails__err')
        }
    }

    const walletconnect = () => {
        dispatch(walletControl("connect"));
        sessionStorage.setItem("walletStatus", "connect");
    };

    const walletdis = () => {
        dispatch(walletControl("disconnect"));
        dispatch({
            type: WALLET_ADDRESS,
            payload: ''
        })
        removeWallteAddress()
        sessionStorage.setItem("walletStatus", "disconnect");
    };

    window.addEventListener("load", async (event) => {
        console.log("loaddd");
        if (sessionStorage.getItem("wallet") == "metamask") {
            console.log('session_');
            if (window.ethereum) {
                window.ethereum.on("accountsChanged", async function (accounts) {
                    try {
                        console.log('hiiiiiiiii')
                        const connection = await MetamaskWallet(dispatch);
                        console.log("connection", connection);
                        if (connection) {
                            walletconnect();
                        }
                        else {
                            walletdis();
                        }
                    } catch (e) {
                        console.log("MetamaskConnectWallet", e);
                    }
                });
            }
        }
    });
}



export default HelperRoute;