import { CURRENT_CHAIN_ID, UseWeb3, useWeb3 } from "./useWeb3"
import BigNumber from "bignumber.js"

import YeildABI from '../config/abi/yeildbooster.json'
import contractAddress from '../config/constant/contract'

//lib
import { consolelog } from "../lib/consolelog"
import { getWalletAddress } from "../lib/localStorage"
//hooks
import { EstGas } from "./useCommon"
import { multicall } from "./useMultiCall"
import { toFixedNumber } from "../lib/toFixedOf"
import { Allocate, Deallocate, GetDeallocateFee } from "./useXtoken"
import { isEmpty } from "../lib/isEmpty"

//constant
import { YEILD_ALLOCATION_DETAILS } from "../constants"


export const getYeildAddress = () => {
    try {
        const CHAINID = CURRENT_CHAIN_ID()
        let xtokenAddress = contractAddress.yeildBooster[`${CHAINID}`]
        return xtokenAddress
    } catch (err) {
        consolelog('getXAddress__err', err, true)
    }
}


export const UseYieldContract = async () => {
    const web3 = await useWeb3()
    try {
        let YeildAddress = getYeildAddress()
        let contract = new web3.eth.Contract(YeildABI, YeildAddress)
        return contract
    } catch (err) {
        consolelog('Xcontract__err', err, true)
        return false
    }
}
export const BoostPosition = async (poolAddress, tokenId, amount) => {
    try {
        const web3 = await useWeb3()
        let usageData = web3.eth.abi.encodeParameters(['address', 'uint256'], [poolAddress, tokenId]);
        let result = await Allocate(getYeildAddress(), amount, usageData, getWalletAddress())
        return result
    } catch (err) {
        console.log(err, 'BoostPosition__err')
        return false
    }
}

export const UnBoostPosition = async (poolAddress, tokenId, amount) => {
    try {
        const web3 = await useWeb3()
        let usageData = web3.eth.abi.encodeParameters(['address', 'uint256'], [poolAddress, tokenId]);
        let result = await Deallocate(getYeildAddress(), amount, usageData, getWalletAddress())
        return result
    } catch (err) {
        console.log(err, 'UnBoostPosition__err')
        return false
    }
}

export const GetUserTotalAllocation = async () => {
    try {
        const web3 = await useWeb3()
        const contract = await UseYieldContract()
        const account = getWalletAddress()
        let result = await contract.methods.getUserTotalAllocation(web3.utils.toChecksumAddress(account)).call()
        return result
    } catch (err) {
        console.log(err, 'GetUserTotalAllocation__err')
        return false
    }
}

export const GetTotalAllocation = async () => {
    try {
        const web3 = await useWeb3()
        const contract = await UseYieldContract()
        let result = await contract.methods.totalAllocation().call()
        return result
    } catch (err) {
        console.log(err, 'GetTotalAllocation__err')
        return false
    }
}

export const getAllocationDetails = async (dispatch) => {
    try {
        console.log('getAllocationDetails')
        let yieldAddress = getYeildAddress()
        let calls = [{
            address: yieldAddress,
            name: 'totalAllocation'
        }]
        if (getWalletAddress()) {
            calls.push({
                address: yieldAddress,
                name: 'getUserTotalAllocation',
                params: [getWalletAddress()]
            })
        }
        const [totalAllocation, UserTotalAllocation] = await multicall(YeildABI, calls)
        let deallocationFee  = await GetDeallocateFee(yieldAddress)
        console.log(new BigNumber(totalAllocation[0]._hex).toNumber(), UserTotalAllocation,'getAllocationDetails')
        let obj = {
            totalAllocation: new BigNumber(totalAllocation[0]._hex).toNumber()/10**18,
            UserTotalAllocation: isEmpty(getWalletAddress()) ? 0 : new BigNumber(UserTotalAllocation[0]._hex).toNumber()/10**18,
            deallocationFee:deallocationFee/100
        }
        dispatch({
            type: YEILD_ALLOCATION_DETAILS,
            payload: obj
        })
    } catch (err) {
        console.log(err, 'getAllocationDetails__err')
    }
}

export const GetBoostMultipler =async(poolAddress,maxBoostMultiplier,lpAmount,userAllocation,totalLpSupply)=>{
    try{
        const contract = await UseYieldContract()
        let multiper =  await contract.methods.getMultiplier(poolAddress,maxBoostMultiplier,lpAmount,totalLpSupply,userAllocation).call()
        return multiper/10**4
    }catch(err){
        console.log(err,'GetBoostMultipler__err')
        return 0 
    }
}


export const GetExpectBoostMultipler =async(maxBoostMultiplier,lpAmount,userAllocation,totalLpSupply,poolTotalAllocation)=>{
    try{
        const contract = await UseYieldContract()
        let multiper =  await contract.methods.getExpectedMultiplier(maxBoostMultiplier,lpAmount,totalLpSupply,userAllocation,poolTotalAllocation).call()
        return multiper/10**4
    }catch(err){
        console.log(err,'GetBoostMultipler__err')
        return 0 
    }
}

export const GetPoolTotalAllocation =async(poolAddress)=>{
    try{
        const contract = await UseYieldContract()
        let TotalAllocation =  await contract.methods.getPoolTotalAllocation(poolAddress).call()
        return TotalAllocation
    }catch(err){
        console.log(err,'GetBoostMultipler__err')
        return 0 
    }
}
export const GettotalAllocationFloor =async()=>{
    try{
        const contract = await UseYieldContract()
        let TotalAllocationFloor =  await contract.methods.totalAllocationFloor().call()
        return TotalAllocationFloor
    }catch(err){
        console.log(err,'GetBoostMultipler__err')
        return 0 
    }
}

export const GetMaxBonus = (multiper,lpSupply,maxBoostMultiplier,lpAmount,poolAllocation,totalAllocationFloor)=>{
    try{
        console.log(multiper,lpSupply,maxBoostMultiplier,lpAmount,poolAllocation,totalAllocationFloor,'GetMaxBonus')
        if(poolAllocation < totalAllocationFloor) {
            poolAllocation = totalAllocationFloor
        };
        let userAllocation = ((multiper/lpSupply)/maxBoostMultiplier) * (lpAmount * poolAllocation )
        console.log(userAllocation,'GetMaxBonus')
        return toFixedNumber(userAllocation/10**18)
    }catch(err){
        console.log(err,'GetMaxBonus__err')
    }
}