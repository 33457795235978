import { CURRENT_CHAIN_ID, CURRENT_RPC_URL, useWeb3 } from "./useWeb3";
import { getChainId } from './useWeb3'

import toast, { Toaster } from 'react-hot-toast';
import { iconTheme, position, style } from "./useToast";

//Lib
import { getWalletAddress } from '../lib/localStorage'

//config
import ERC20_ABI from '../config/abi/ERC20.json'
import PROXY_SALE_ABI from '../config/abi/PROXY_ABI.json'
import SALE_ABI from '../config/abi/SALE_ABI.json'
import { CHAINS } from '../config/env'
import { PROXY } from "../config/constant/contract";
import STANDARD_TOKEN_ABI from '../config/abi/STANDARD_TOKEN_ABI.json'
import LIQUIDITY_TOKEN_ABI from '../config/abi/LIQUIDITY_TOKEN_ABI.json'

//Actions 
// import { launchpadhook } from "../Actions/LaunchActions";


var CheckToast = true


export const sleep = (ms) => {
    try {
        new Promise(resolve => setTimeout(resolve, ms))
    }
    catch (e) {
        console.log("sleep", e)
    }
}

export const UseERC20 = async (VALUE) => {
    console.log("value", VALUE)
    const web3 = await useWeb3();
    try {
        const contract = new web3.eth.Contract(ERC20_ABI, VALUE);
        return contract;
    }
    catch (err) {
        console.log("UseERC20", err.code)
    }
}


export const UseTokenInfo = async (TOKEN) => {
    try {
        console.log("token", TOKEN);
        const token = await UseERC20(TOKEN);
        const name = await token.methods?.name().call(); console.log("name", name);
        const symbol = await token.methods?.symbol().call();
        const decimals = await token.methods?.decimals().call();
        const totalSupply = await token.methods?.totalSupply().call();
        const data = {
            name: name,
            symbol: symbol,
            decimals: decimals,
            totalSupply: totalSupply
        }
        console.log("data", data);
        return data;
    }
    catch (err) {
        console.log("UseTokenInfo", err.code)
    }
}


export const Checkaddress = async (address) => {
    try {
        const web3 = await useWeb3();
        const account = getWalletAddress();

        let errors = {}
        const data = web3.utils.isAddress(address)
        if (data) {
            console.log("checkaddress")
            let tokeninfo = await UseTokenInfo(address);
            console.log("tokeninfo", tokeninfo);
            const newad = web3.utils.toChecksumAddress(address);
            const contract = new web3.eth.Contract(ERC20_ABI, newad);
            const allowance = await contract.methods.allowance(account, CHAINS[getChainId()].LOCK_ADDRESS).call();
            const approvebutton = allowance > 0 ? true : false
            console.log("checkaddress", tokeninfo);
            return {
                isValid: true,
                tokeninfo: tokeninfo,
                allowance: approvebutton
            }
        }
        else {
            errors.tokenaddress = "Invalid address !"
            return {
                isValid: false,
                errors: errors
            };
        }
    }
    catch (e) {
        console.log("Checkaddress", e)
    }
}

export const Approvetoken = async (address, amount) => {
    console.log("address", address, amount);
    const web3 = await useWeb3();
    try {

        const account = getWalletAddress();
        const contract = new web3.eth.Contract(ERC20_ABI, address);
        const approve = contract.methods.approve(CHAINS[getChainId()].LOCK_ADDRESS, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account })
        await toast.promise(approve, {
            loading: 'Approving...',
            success: 'Approved Successfully',
            error: 'Try Again',
        });
        await sleep(1000)
        return true;
    }
    catch (e) {
        console.log("Approvetoken", e)
        return false
    }
}


export const depositTokens = async (tokenAddress, saleAddress, value, account) => {
    try {
        console.log("value", value);

        const erc20 = await UseERC20(tokenAddress)
        const data = erc20.methods.transfer(saleAddress, value).send({ from: account })
        await toast.promise(data, {
            loading: 'Depositing Tokens ...',
            success: 'Tokens Deposited Successfully',
            error: 'Error ! When Depositing Tokens',
        }
        // , 
        // {
        //     position: position.position,
        //     style: style,
        //     iconTheme: iconTheme
        // }
        );
        const balance = await erc20.methods.balanceOf(saleAddress).call();
        if (balance >= parseInt(value)) {
            return true;
        } else {
            return false;
        }
    }
    catch (err) {
        console.log("depositTokens", err.code)
    }
}


export const checkChainId = (chainId) => {
    try {
        const CHAIN_ID = CURRENT_CHAIN_ID();
        if (parseInt(chainId) != parseInt(CHAIN_ID)) {
            if (CheckToast) {

                toast.error(`Connected to Wrong Network !`,
                    // {
                    //     position: position.position,
                    //     style: style,
                    //     iconTheme: iconTheme
                    // }
                    )
            }
        }
        CheckToast = false
        return true;
    }
    catch (err) {
        console.log("checkChainId", err.code)
    }
}

export const getProxyAddress = () => {
    try {
        const CHAIN_ID = CURRENT_CHAIN_ID();
        return PROXY[CHAIN_ID];
    }
    catch (err) {
        console.log("getProxyAddress", err.code,err)
    }
}

export const UseProxySale = async () => {
    try {
        const web3 = await useWeb3();
        const chainId = await web3.eth.getChainId();
        checkChainId(chainId);
        const proxysale = getProxyAddress();

        const contract = new web3.eth.Contract(PROXY_SALE_ABI, proxysale);
        console.log("proxysale", contract);
        return contract;
    }
    catch (err) {
        console.log("UseProxySale", err.code,err)
    }
}


export const getSaleAddress = async (tokenAddress) => {
    try {
        // console.log("token address inside get sale address",tokenAddress);
        const proxyContract = await UseProxySale();
        const sale = await proxyContract.methods.getSale(tokenAddress).call()
        // console.log("sale in get sale address",sale);
        return sale;
    }
    catch (err) {
        console.log("getSaleAddress", err.code,err)
    }
}

export const getProxyOwner = async () => {
    try {
        const proxy = await UseProxySale();
        const owner = await proxy.methods.owner().call();
        return owner;
    }
    catch (err) {
        console.log("getProxyOwner", err.code,err)
    }
}

// export const createPresale = async (token, values, setters, details, account) => {
//     try {
//         console.log("createsale");
//         let presale = await InitiatePresale(token, values, setters, details, account);
//         console.log("presale",presale)
//         console.log("token[0] in create presale",token[0],token,values, setters, details, account);
//         let sale = await getSaleAddress(token[0]);
//         console.log("sale in createpresale",sale);
//         if (sale == "0x0000000000000000000000000000000000000000") {
//             sale = await getSaleAddress(token[0]);
//             let wallet = getWalletAddress();
//             wallet = wallet.toString();
//             wallet = wallet.toLowerCase();
//             console.log("wallet", wallet);
//             let payload = {
//                 chain: CHAINS[getChainId()].CHAIN_ID,
//                 walletaddress: wallet,
//                 saleaddress: sale,
//                 whitelist: setters[3]
//             }
//             let createlaunchpad = await launchpadhook(payload);
//             console.log("create_launchpadhook", createlaunchpad);
//             return sale;
//         }
//         else {
//             let wallet = getWalletAddress();
//             wallet = wallet.toString();
//             wallet = wallet.toLowerCase();
//             console.log("wallet", wallet);
//             let payload = {
//                 walletaddress: wallet,
//                 saleaddress: sale,
//                 whitelist: setters[3]
//             }
//             let createlaunchpad = await launchpadhook(payload);
//             console.log("create_launchpadhook2222", createlaunchpad);
//             return sale;
//         }
//     }
//     catch (err) {
//         console.log("createPresale", err.code,err)
//     }
// }

// export const InitiatePresale = async (token, values, setters, details, account) => {
//     try {
//         const proxyContract = await UseProxySale();
//         let deploymentFee
//         const owner = await getProxyOwner()
//         if (owner !== account) {
//             deploymentFee = await proxyContract.methods.getDeploymentFee().call();
//         }
//         else {
//             deploymentFee = 0
//         }
//         const data = proxyContract.methods.createPresale(token, values, setters, details).send({ from: account, value: deploymentFee })
//         console.log("data in initiate presale", data);
//         await toast.promise(data, {
//             loading: 'Creating New Presale ...',
//             success: 'Presale Created Successfully',
//             error: (err) => `Error : ${err.toString()}`,
//         }
//         );
//     }
//     catch (err) {
//         console.log("InitiatePresale", err.code,err)
//     }
// }

export const Usestandardtoken = async (VALUE) => {
    try {
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(STANDARD_TOKEN_ABI, VALUE);
        return contract;
    }
    catch (err) {
        console.log("Usestandardtoken", err)
    }
}

export const Useliquiditytoken = async (VALUE) => {
    try {
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(LIQUIDITY_TOKEN_ABI, VALUE);
        return contract;
    }
    catch (err) {
        console.log("Useliquiditytoken", err)
    }
}

export const GetAdminfee = async () => {
    try {
        console.log("calll");
        let contract = await UseProxySale();
        const tokencontract = await Usestandardtoken(CHAINS[getChainId()].STANDARD_TOKEN_ADDRESS);
        const liquidityToken = await Useliquiditytoken(CHAINS[getChainId()].LIQUIDITY_TOKEN_ADDRESS);
        var deploymentfee = await contract?.methods?.getDeploymentFee().call();
        var tokenfee = await tokencontract?.methods?._depolymentFee().call();
        var liquidityTokenFee = await liquidityToken?.methods?.fee().call();
        return {
            deploymentfee: deploymentfee,
            tokenfee: tokenfee,
            liquidityTokenFee: liquidityTokenFee
        }
    }
    catch (err) {
        console.log("GetAdminfee", err)
    }

}

export const SetDeploymentfee = async (value) => {
    try {
        let contract = await UseProxySale();
        let wallet = getWalletAddress();
        let fee = await contract.methods.setDeploymentFee((value * 10 ** 18).toString()).send({ from: wallet });
      
        await sleep(1000);
        
        // window.location.reload();
        if(fee){
            return true
        }
        
       
    }
    catch (err) {
        console.log("SetDeploymentfee", err)
        return false
    }
}


export const isFloat = (x) => {
    try {
        if (!!(x % 1)) {
            toast.error(`Decimal Values are not accepted !`,
                {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                });
        }
        return !!(x % 1);
    }
    catch (err) {
        console.log("isFloat", err)
    }
}


export const UseSale = async (VALUE) => {
    try {
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(SALE_ABI, VALUE);
        console.log("contract", contract)
        return contract;
    }
    catch (err) {
        console.log("UseSale", err)
    }
}


export const editDescription = async (ADDRESS, data) => {
    try {
        console.log("editDescription", ADDRESS, data)
        const account = getWalletAddress();
        const saleContract = await UseSale(ADDRESS);
        const Info = await saleContract?.methods?.updateTokenInfo(data).send({ from: account });
        return Info;
    }
    catch (err) {
        console.log("editDescription", err)
    }
}


export const getTotalSalesInfo = async () => {
    try {
        const proxy = await UseProxySale();
        let data = await proxy.methods.getTotalSales().call();
        console.log("proxy inside getTotalSales info:", data);
        // const initial = [{
        //     _sale  : "0xA802274ba245171A37d39beC1Fd01cF459A8aeA8",
        //     _start : 142108977862384,
        //     _end : 142116442822384,
        //     _name : "Metaverse Lab"
        // }];
        // data = (initial).concat(data);

        const reverseArray = [...data].reverse();
        return (reverseArray);
    }
    catch (e) {
        console.log("getTotalSalesInfo", e);
    }
}


export const getSaleInfoCard = async (saleAddress) => {
    try {
        const saleContract = await UseSale(saleAddress);
        const data = await saleContract.methods.getSaleInfo().call();
        console.log("new", data);
        return data;
    }
    catch (e) {
        console.log("getSaleInfoCard_err", e)
    }

}



export const getlaunchpaddatalimit = async (cards, start, end) => {
    try {
        let SaleInfoCards = [];
        console.log("card", cards, start, end, cards.length);
        if (cards.length > 0 && cards.length > start) {
            for (var i = start; i < end; i++) {
                console.log("forlop", i);
                if (cards.length > i) {
                    console.log("card[i]", cards[i], i);
                    if (cards[i]?.isdb == true || cards[i]?.isdb == "true") {
                        SaleInfoCards.push(cards[i]);

                    }
                    else {
                        var saleInfo = await getSaleInfoCard(cards[i]._sale ? cards[i]._sale : cards[i].saleaddress);
                        
                        console.log("saleInfo2", saleInfo)
                        if (saleInfo?.useWithToken == "0x0000000000000000000000000000000000000000") {
                            saleInfo = { ...saleInfo, ...{ coin: "BNB" } };
                            console.log("saleInfo_saleInfo", saleInfo)

                            SaleInfoCards.push(saleInfo);
                            console.log("bnb", SaleInfoCards);
                        }
                        else {
                            const newdata = await UseTokenInfo(saleInfo.useWithToken);
                            saleInfo = { ...saleInfo, ...{ coin: newdata.symbol } };
                            SaleInfoCards.push(saleInfo);
                        }
                    }
                }
                else {
                    console.log("other", SaleInfoCards);
                    return SaleInfoCards;
                }
            }
        }
        console.log("other", SaleInfoCards);
        return SaleInfoCards;
    } catch (err) {
        console.log(err, "getlaunchpaddatalimit__err")
    }
}


export const checkbalnceTokens = async (tokenAddress, saleAddress) => {
    try {
        const erc20 = await UseERC20(tokenAddress)
        const balance = await erc20.methods.balanceOf(saleAddress).call();
        return balance;
    }
    catch (err) {
        console.log("checkbalnceTokens", err.code)
    }
}

export const Getliquiditytokenamount = async (address) => {
    try {
        console.log("addressaddress", address);
        const proxyContract = await UseSale(address);
        const data = await proxyContract.methods.getLiquidityTokenAmount().call();
        console.log("data in withdraw", data);
        return data;
    }
    catch (e) {
        console.log("Getliquiditytokenamount", e)
    }
}

export const Getunsoldtoken = async (address) => {
    try {
        try {
            const proxyContract = await UseSale(address);
            const data = await proxyContract.methods.getUnsoldTokensBalance().call();
            console.log("data in withdraw", data);
            return data;
        }
        catch (err) {
            console.log("Getunsoldtoken", err)
        }
    }
    catch (e) {
        console.log("Getunsoldtoken", e)
    }
}

export const isSaleLive = (start, end, isOpen) => {
    try {
        return (Date.now() >= (start * 1000) && Date.now() <= (end * 1000)) && isOpen;
    }
    catch (e) {
        console.log("isSaleLive", e);
    }
}

export const isUpcoming = (start, end) => {
    try {
        return (Date.now() < (start * 1000));
    }
    catch (e) {
        console.log("isUpcoming", e);
    }
}

export const isSaleEnded = (start, end, isOpen) => {
    try {
        return (Date.now() >= (end * 1000)) || !isOpen
    }
    catch (e) {
        console.log("isSaleEnded", e);
    }
}



export const UpcomingDiffernce = (start) => {
    try {
        return ((start * 1000) - Date.now());
    }
    catch (err) {
        console.log(err, "UpcomingDiffernce")
    }
}

export const Salediffernce = (end) => {
    try {
        return ((end * 1000) - Date.now());
    }
    catch (err) {
        console.log(err, "Salediffernce")
    }
}


export const GetSalePerAccount = async (account, saleAddress) => {
    try {
        console.log("User data " ,account,saleAddress  )
        const saleContract = await UseSale(saleAddress);
        const userData = await saleContract.methods.getUserInfo(account).call();
        console.log("User data1" ,userData )

        return userData;
    }
    catch (err) {
        console.log("GetSalePerAccount", err.code)
    }
}

export const getAllocationInfo = (actualBalance, userBalance, vestingInterval, vestingPercentage, initalClaimed) => {
    try {
        console.log("Vesting Balance : ", actualBalance, vestingInterval, vestingPercentage, initalClaimed)

        let vestingCount = parseInt(100 / (parseInt(vestingPercentage) / 100));
        const vestingBalance = parseInt(actualBalance) - (parseInt(actualBalance * vestingPercentage / 10000) * vestingCount)
        console.log("Vesting Balance counyt :", vestingCount, vestingBalance)
        if (initalClaimed <= 0)
            initalClaimed = Date.now() / 1000;

        let allocations = [];
        for (var i = 0; i < vestingCount; i++) {
            const data = {
                no: i + 1,
                allocation: parseInt(actualBalance * vestingPercentage / 10000),
                unlockon: (i == 0) ? initalClaimed : parseInt(initalClaimed) + (vestingInterval * (i)),
                isClaimed: (i == 0) ? (initalClaimed * 1000 == Date.now()) : ((Date.now() >= parseInt(parseInt(initalClaimed) + (vestingInterval * (i))) * 1000)),
                isDid: (i == 0) ? (parseInt(actualBalance * vestingPercentage / 10000) <= (actualBalance - userBalance)) : (parseInt(actualBalance * vestingPercentage / 10000) * (i + 1) <= (actualBalance - userBalance))
            };
            allocations.push(data);
        }
        if (vestingBalance > 0) {
            const data = {
                no: (allocations.length) + 1,
                allocation: vestingBalance,
                unlockon: initalClaimed + (vestingInterval * (allocations.length) + 1),
                isClaimed: (Date.now() >= parseInt(initalClaimed + (vestingInterval * (allocations.length) + 1)) * 1000),
                isDid: (parseInt(actualBalance * vestingPercentage / 10000) * (allocations.length) + 1) <= (actualBalance - userBalance)
            };
            allocations.push(data);
        }

        console.log("Allocation : ", allocations)
        return allocations;
    }
    catch (e) {
        console.log("getAllocationInfo", e);
    }
}

export const getAffliateInfo = async (ADDRESS) => {
    try {
        const saleContract = await UseSale(ADDRESS);
        const AffliateInfo = await saleContract.methods.getAffliateInfo().call();
        console.log(AffliateInfo,'AffliateInfo')
        return AffliateInfo;
    }
    catch (err) {
        console.log("getAffliateInfo", err.code)
    }
}
export const UsegetChildrenOf = async (saleAddress, useraddress) => {
    try {

        const web3 = await useWeb3();
        const proxysale = getProxyAddress();

        const proxyContract = new web3.eth.Contract(PROXY_SALE_ABI, proxysale);
        const data = await proxyContract.methods.getChildrenOf(saleAddress, useraddress).call();
        console.log("getAffParentOf", data);

        return data;
    }
    catch (err) {
        console.log("UsegetChildrenOf", err)
    }

}

export const UsegetParentOf = async (saleAddress, useraddress) => {
    try {

        const web3 = await useWeb3();
        const proxysale = getProxyAddress();

        const proxyContract = new web3.eth.Contract(PROXY_SALE_ABI, proxysale);
        const data = await proxyContract.methods.getParentOf(saleAddress, useraddress).call();
        console.log("UsegetParentOf", data);

        return data;
    }
    catch (err) {
        console.log("UsegetParentOf_err", err)
    }

}


export const checkIsApproved = async (account, token, saleaddress) => {
    try {
        const erc20contract = await UseERC20(token);
        const allow = await erc20contract.methods.allowance(account, saleaddress).call();

        return (parseInt(allow) > 0) ? true : false;
    } catch (e) {
        console.log("checkIsApproved", e)

    }
}


export const DeletePresale = async (saleAddress, account) => {
    try {
        const proxyContract = await UseProxySale();
        const data = proxyContract.methods.deleteSalePresale(saleAddress).send({ from: account })
        await toast.promise(data, {
            loading: 'Deleting the Sale...',
            success: 'Sale Deleted Successfully',
            error: 'Error ! When Delete sale',
        })
        await sleep(1000);
        window.location.reload();
    }
    catch (err) {
        console.log("DeletePresale", err.code)
    }
}