import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/completedsupplystyle.module.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaRegQuestionCircle } from "react-icons/fa";
import { TiInfo } from "react-icons/ti";

import coinicon from '../assests/images/cardlogo.png';

import { IoMdClose } from "react-icons/io";
import { Link } from 'react-router-dom';
import { FaArrowUp } from "react-icons/fa";
export default function ComingSoonmodal(props) {


  return (

    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader} ${cascading.closeonlymodalheader}`}>
        
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody} pt-0`}>

        <div className='text-center'>
            <img src={coinicon} className='img-fluid'/>
            </div>
            <h4 className={`${cascading.modaltitlebody} mt-3`}>Coming Soon</h4>

          <p className={`${cascading.vallabel} mt-4`}>The action you are performing will be coming soon</p>
          {/* <Link to={`${CHAINS.TRANSACTION}/${localStorage.getItem("addLiquidity")}`} className={`${cascading.declabel}`}>View on BscScan</Link> */}
          {/* <button className={`${cascading.declabel}`} onClick={()=>{window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${props.txn.transactionHash}`)}} >View on BscScan</button> */}
        </Modal.Body>

      </Modal>
    </div>
  )
}

