import React, { useEffect, useState } from 'react';
import cascading from '../../assests/css/all.module.css';
import Footer from '../../common/footer'
import Dashcard from '../../components/dashcard';
import Yourallocation from '../../components/yourallocation';
import Yourdividents from '../../components/yourdividents';
import Connectcard from "../../components/connectcard";
import Currentepoch from "../../components/currentepoch";
import Nextepoch from "../../components/nextepoch";
import Pendingrewards from "../../components/pendingrewards";
import Timercard from "../../components/timercard";


import Balancecard from '../../components/balancecard';
import Header from '../../common/header';
import { useNavigate } from 'react-router-dom';

import { useSelector,useDispatch } from 'react-redux';
import logohome from '../../assests/images/coinicon.png';

//hooks
import { getDividendDetails } from '../../hooks/useDividends';
import { isEmpty } from '../../lib/isEmpty';

import DividendsSuccessmodal from '../../modals/DividendsSuccessmodal';
import { roundToSignificant } from '../../hooks/useCommon';
import { getWalletAddress } from '../../lib/localStorage';

export default function Dividents() {
    const navigate = useNavigate()
    const dispatch =  useDispatch()

    const {walletaddress} =  useSelector((state)=>(state.wallet))
    const {dividendsdetails,isdiviload} =  useSelector((state)=>(state.dividends))
    console.log(dividendsdetails,'dividendsdetails') 
    const [isconnect, setIsconnect] = useState(true);
    const [loaded, setisloaded] = useState(false);
    const [detailcard, setDetailcard] = useState([
        {
            label: "Total allocations",
            value: "0 RICE",
            img: require("../../assests/images/total_icon.png"),
            key:'total'

        },
        {
            label: "Deallocation fee",
            value: "0.5%",
            img: require("../../assests/images/fee_icon.png"),
            key:'fee'

        },
        {
            label: "Current epoch",
            value: "$0",
            img: require("../../assests/images/current_icon.png"),
            key:'currentepoch'

        },
        // {
        //     label: "Epoch earnings / RICE",
        //     value: "$7.88",
        //     img: require("../../assests/images/current_icon1.png")
        // },
        {
            label: "APY",
            value: "0%",
            img: require("../../assests/images/apy_icon.png"),
            key:'apy'

        }
    ])
    const [dashdetail, setDashdetail] = useState([
        {
            name: "Yield booster",
            desc: "Boost your staking yields by up to +100% by adding RICE to any eligible position.",
            img: require("../../assests/images/folder.png"),
            mybal: "0",
            total: "427",
            fees: "0.5",
            path: "/yieldbooster"

        },
        {
            name: "Launchpad",
            desc: "Get perks and benefits from every project on PretzelSwap's launchpad by staking your RICE here.",
            img: require("../../assests/images/folder.png"),
            mybal: "0",
            total: "376",
            fees: "0.5",
            path: "/launchpad"

        },
    ])

    useEffect(()=>{
        if(!isEmpty(dividendsdetails)){
            SetDividendsCard()
        }
        
    },[dividendsdetails])

    useEffect(() => {
        if (isEmpty(dividendsdetails)) {
            setloader()
        } else if (!isEmpty(dividendsdetails)) {
            Hideloader()
        }
    }, [isdiviload])
    const setloader = () => {
        try {
            setisloaded(true)
        } catch (err) {
            console.log(err, 'setloader__Err')
        }
    }
    const Hideloader = () => {
        try {
            setisloaded(false)
        } catch (err) {
            console.log(err, 'setloader__Err')
        }
    }

    const SetDividendsCard = () => {
        try {
            console.log(dividendsdetails, 'allocationDetails')
            let CardDetail = [...detailcard]
            for (let i = 0; i < CardDetail.length; i++) {
                if (CardDetail[i].key == 'total') {
                    CardDetail[i].value = `${parseFloat(dividendsdetails.totalAllocation)} RICE`
                }
                if (CardDetail[i].key == 'fee') {
                    CardDetail[i].value =  `${dividendsdetails.deallocationFee}%`
                }
                if (CardDetail[i].key == 'currentepoch') {
                    CardDetail[i].value = `$ ${dividendsdetails.currentepoch}`
                }
                if (CardDetail[i].key == 'apy') {
                    CardDetail[i].value =isEmpty(dividendsdetails?.apy) ? 0: `${roundToSignificant(dividendsdetails.apy,6)}%`
                }
                if (i == CardDetail.length - 1) {
                    console.log(CardDetail, 'SetAllocationCard')
                    setDetailcard(CardDetail)
                }
            }
        } catch (err) {
            console.log(err, 'SetAllocationCard__err')
        }
    }
    return (
        <>
        <div className={loaded ? 'loader_div' : 'loader_div d-none'} id="loader_div">
                <div className="circle">
                </div>
                <div className="circle_img">
                    <img src={logohome} className='img-fluid' />
                </div>
            </div>
            <Header />
            <div className={loaded ? 'logo_overlay' : 'logo_overlay_none'}>
            <div className={`${cascading.innerpages} ${cascading.YUMpage}`}>
                <div className={`container`}>
                    <div className={`row mx-0 ${cascading.headrow}`}>
                        <p className={`${cascading.pagehead}`}>Dividends</p>
                        <p className={`${cascading.pagedesc}`}>Allocate RICE here to earn a share of protocol earnings in the form of real yield.</p>
                    </div>
                    <div className={`row mx-0 ${cascading.balancesec}`}>
                        {detailcard.map((e) => (

                            <div className={`col-lg-4 col-xl-3 mb-4 ${cascading.col_3custom}`} >
                                <Balancecard datas={e} />

                            </div>

                        ))}


                    </div>
                    <div className={`row mx-0`}>
                        <div className={`col-lg-5 col-xl-4`}>
                        {!isEmpty(walletaddress) && !isEmpty(getWalletAddress()) ?
                                <>
                                    <Yourallocation />
                                    <Yourdividents />
                                </>
                                :
                                <Connectcard />
                            }
                        </div>
                        <div className={`offset-xl-1 col-lg-7 col-xl-7`}>
                            <Currentepoch />
                            {/* <Nextepoch /> */}
                            <Timercard />
                            {/* <Pendingrewards /> */}
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <Footer />
            
        </>
    )
}
