import React, { useEffect, useState } from 'react'
import { Modal, Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import cascading1 from '../assests/css/all.module.css';
import cascading from '../assests/css/importtokenstyle.module.css';
import bnblogo from '../assests/images/bnb.png';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { IoMdClose } from "react-icons/io";

import Walletmodal from './walletmodal';

//lib
import { isEmpty } from "../lib/isEmpty";
import { getWalletAddress, walletControl } from '../lib/localStorage';
import fileObjectUrl from '../lib/img'
import { toFixedNumber } from "../lib/FixedNumber"
import BigNumber from 'bignumber.js';

//hooks
import { getTokenBalance, GetCurrencyBalance } from '../hooks/useTokens'
import { getTokens, multicall } from '../hooks/useTokens'
import { GetallPairsLength, GetLiquidityETH } from '../hooks/useFactory'
import { IsCurrency, Significant } from '../hooks/useCommon'

//abi 
import pair_ABI from '../config/abi/pairAbi.json'
import ERC20_ABI from "../config/abi/ERC20.json";
import { TokenInfo } from '../hooks/useErc20';


const tokendetail = {
  results: [{
    coinimg: bnblogo,
    tokename: "BNBSSS",
    tokendesc: "BNB",
    balance: "0.666"

  },
  {
    coinimg: bnblogo,
    tokename: "BUSD",
    tokendesc: "BUSD Token",
    balance: "0.0"
  },
  {
    coinimg: bnblogo,
    tokename: "BNB",
    tokendesc: "BNB",
    balance: "0.0"
  },
  {
    coinimg: bnblogo,
    tokename: "BUSD",
    tokendesc: "BUSD Token",
    balance: "0"
  }]


};
export default function Selecttoken(props) {
  console.log(props, "props")
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [activebtn, setActivebtn] = useState("")
  const [balance, setBalance] = useState(0)

  const [token, setTokens] = useState([])
  const [Loading, setLoading] = useState(false)
  const { walletaddress } = useSelector((state) => (state.wallet))

  const [walletmodalshow, setWalletmodalshow] = useState(false)

  const handleChange = e => {
    setSearchTerm(e.target.value);
    if (!isEmpty(e.target.value)) {
      let regx = new RegExp(e.target.value, 'i')
      let results = token.filter((val) => {
        return (val?.lpSymbol?.match(regx) ||val?.lpaddress?.match(regx) )
      })
      setSearchResults(results);
    } else {
      setSearchResults(token);
    }
    console.log(searchResults, "search")
  };


  useEffect(() => {
    if (!isEmpty(getWalletAddress())) {
      getPair()
    }
  }, [walletaddress])
  const getPair = async () => {
    try {
      console.log("getPair__call")
      var data = await GetallPairsLength()
      console.log("GetallPairsLength12345", data, Array(data))
      let result = []
      if (data > 0) {
        setLoading(true)
        for (let i = 0; i < data; i++) {
          let address = await GetLiquidityETH(i)
          let { reserveA, reserveB, tokenA, tokenB, lpBalance, tokenAbal, tokenBbal, totalSupply } = await getcall(address)
          console.log("tokenA", toFixedNumber(reserveA), toFixedNumber(reserveB))
          let token1 = getTokens().find((val) => (val?.address?.toLowerCase() == tokenA?.toLowerCase()));
          token1 = isEmpty(token1) ? await TokenInfo(tokenA) : token1
          console.log("token1", token1, totalSupply)
          let token2 = getTokens().find((val) => (val?.address?.toLowerCase() == tokenB?.toLowerCase()));
          token2 = isEmpty(token2) ? await TokenInfo(tokenB) : token2
          let currencyBal
          if (IsCurrency(token1) || IsCurrency(token2)) {
            currencyBal = await GetCurrencyBalance()
          }
          let obj = {
            reserveA: reserveA,
            reserveB: reserveB,
            token1: IsCurrency(token1) ? { ...token1, 'balance': currencyBal } : { ...token1, 'balance': tokenAbal },
            token2: IsCurrency(token2) ? { ...token2, 'balance': currencyBal } : { ...token2, 'balance': tokenBbal },
            lpBalance: lpBalance,
            totalSupply: totalSupply,
            lpAddress: address,
            lpSymbol: `${token1.symbol}-${token2.symbol}`,
            poolShare: (lpBalance * 100 / totalSupply).toFixed(2)
          }
          if (obj.lpBalance > 0) {
            result.push(obj)
          }

          if (i == data - 1) {
            console.log()
            setSearchResults(result)
            setTokens(result)
            setLoading(false)

          }
          console.log("token2", token2)
        }
      } else {
        setLoading(false)
      }

    }
    catch (err) {
      console.log("liquidity_errr", err)
    }
  }

  const getcall = async (address) => {
    let calls = [{
      address: address,
      name: 'getReserves'
    }, {
      address: address,
      name: 'token0'
    }, {
      address: address,
      name: 'token1'
    },
    {
      address: address,
      name: 'totalSupply'
    }
    ]
    if (!isEmpty(getWalletAddress())) {
      calls.push({
        address: address,
        name: 'balanceOf',
        params: [getWalletAddress()]
      })
    }
    console.log("calls_callscalls", calls)
    var pair = await multicall(pair_ABI, calls)
    console.log("pair", pair)
    let call = [{
      address: pair[1][0],
      name: 'balanceOf',
      params: [getWalletAddress()]
    }, {
      address: pair[2][0],
      name: 'balanceOf',
      params: [getWalletAddress()]
    }]
    var tokenbalance = await multicall(ERC20_ABI, call)
    console.log("tokenbalance", tokenbalance)
    // var bal = await Balance(address)
    // console.log("bal", bal)

    return {
      reserveA: new BigNumber(pair[0][0]._hex).toNumber() / 10 ** 18,
      reserveB: new BigNumber(pair[0][1]._hex).toNumber() / 10 ** 18,
      tokenA: pair[1][0],
      tokenB: pair[2][0],
      totalSupply: new BigNumber(pair[3][0]._hex).toNumber() / 10 ** 18,
      // userlpBalance: new BigNumber(pair[4]._hex).toNumber() / 10 ** 18,
      lpBalance: isEmpty(getWalletAddress()) ? 0 : new BigNumber(pair[4][0]._hex).toNumber() / 10 ** 18,
      tokenAbal: isEmpty(getWalletAddress()) ? 0 : new BigNumber(tokenbalance[0]).toNumber() / 10 ** 18,
      tokenBbal: isEmpty(getWalletAddress()) ? 0 : new BigNumber(tokenbalance[1]).toNumber() / 10 ** 18,
      lpAddress : address

    }
  }

  const [tokenbtn, setTokenbtn] = useState([{
    img: bnblogo,
    tokenname: "BNB"

  },
  {
    img: bnblogo,
    tokenname: "BNB"

  },
  {
    img: bnblogo,
    tokenname: "BNB"

  },
  {
    img: bnblogo,
    tokenname: "BNB"

  }]);
  return (

    <div className={`${cascading.walletmodalstyle}`}>
      {console.log(props, "checking")}
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Select a Token</h4>

          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />


        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          {walletaddress && !isEmpty(walletaddress) ?
            Loading && Loading ?
              <div className={`${cascading.commonsec}`}>
                <p className={`${cascading.labels}`}>Loading...</p>
              </div> :
              <>
                <div className={`${cascading.topsearchsec}`}>
                  <input
                    type="text"
                    placeholder="Search name or paste address"
                    value={searchTerm}
                    onChange={handleChange}
                    className={`${cascading.searchinput}`}
                  />
                </div>
                {props.commontoken &&
                  <div className={`${cascading.commonsec}`}>
                    <p className={`${cascading.labels}`}>Common bases<OverlayTrigger
                      className={`${cascading.tooltip_style}`}
                      key="right"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          These tokens are commonly paired with other tokens.
                        </Tooltip>
                      }
                    >
                      <span className={`mx-2`} ><IoIosHelpCircleOutline fill='#fff' fontSize={18} /></span>
                    </OverlayTrigger></p>
                    <div className={`${cascading.btngrpsec}`}>
                      <ButtonGroup aria-label="Basic example">
                        {tokenbtn.map((e, i) => (
                          <Button variant="secondary" className={activebtn == i ? `${cascading.activebtn}` : ""} id={i} onClick={() => setActivebtn(i)}><img src={e.img} alt='img' className={`img-fluid ${cascading.tokenimgs}`} /> {e.tokenname}</Button>

                        ))}
                      </ButtonGroup>
                    </div>

                  </div>
                }

                <ul className={`${cascading.tokenlists}`}>
                  {searchResults.map((e, i) => (
                    <li key={i} onClick={() => {
                      console.log(e, 'SetToken')
                      let obj = { ...e }
                      // obj['balance'] = balance[i]
                      props.SetToken(obj)
                      props.onHide()
                    }}>
                      <div className={`${cascading.tokenrow}`}>
                        {/* <div className={`${cascading1.imgsec} ${cascading1.cursor_pointer_column}`}>
                          <div className={`${cascading1.leftimgsec}`}>
                            <img src={isEmpty(e.token1) ? bnblogo : fileObjectUrl(e.token1.image)} alt='img' className={`img-fluid ${cascading1.fromimg}`} />
                            <img src={isEmpty(e.token2) ? bnblogo : fileObjectUrl(e.token2.image)} alt='img' className={`img-fluid  ${cascading1.toimg}`} />
                          </div>
                        </div> */}
                        <div className={`${cascading.twoimgsec}`}>
                        <img src={isEmpty(e.token1) ? bnblogo : fileObjectUrl(e.token1.image)} alt='coin' className={`img-fluid ${cascading.fromimg}`} />
                        <img src={isEmpty(e.token2) ? bnblogo : fileObjectUrl(e.token2.image)} alt='coin' className={`img-fluid ${cascading.toimg}`} />
                        </div>
                        <div>
                          <p className={`${cascading.name}`}>{e?.lpSymbol}</p>
                          {/* <p className={`${cascading.desc}`}>{e?.}</p> */}
                        </div>
                      </div>
                      <div>
                        <p className={`${cascading.desc}`}>{parseFloat(e?.lpBalance)}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </> :
            <div className='px-4 py-5'>
              <button
                className={`btn ${cascading.connect_btn}`}
                onClick={() => setWalletmodalshow(true)}>
                Connect Wallet
              </button>
            </div>
          }


        </Modal.Body>

      </Modal>
      {walletmodalshow && <Walletmodal show={walletmodalshow} onHide={() => setWalletmodalshow(false)} />}
    </div>
  )
}

