import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/apycalculatorstyle.module.css';
import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { useSelector, useDispatch } from 'react-redux';
import { IoMdClose } from "react-icons/io";

//hooks
import { TokenBalance } from '../hooks/useTokens'
import { ApproveUsage, CheckApproveUsage, Allocate, Deallocate, getXLaunchAddress } from '../hooks/useXtoken'
import { getXtoken, roundToSignificant } from '../hooks/useCommon'
import { getDividendAddress, AllocateTODividends, pendingDividendsAmount } from '../hooks/useDividends';

//lib
import { getWalletAddress } from '../lib/localStorage';
import { isEmpty } from '../lib/isEmpty';

//validations
import { validateAllocation } from '../validation/LaunchValidation'
import { toFixedNumber } from '../lib/toFixedOf';
import { getTokenPricesFromFarm } from '../hooks/usePools';

export default function DividendAllocatemodal(props) {

    let dispatch = useDispatch()

    const { showsuccesModal, SetsuccessLoader, setError } = props

    const [allocatemodal, setAllocatemodal] = useState(true);
    const [amount, setAmount] = useState('');
    const [approve, setApprove] = useState(false);
    const [isApprove, setIsApprove] = useState(false);
    const [allocation, setAllocation] = useState(false);
    const [errors, setErrors] = useState('')
    const [daiyReturns, setdaiyReturns] = useState('')
    const [perReturns, setperReturns] = useState('')
    const [totalAllocate, settotalAllocate] = useState('');
    const [totalShare, settotalShare] = useState('');
    const [bntenable,setbntenable] =  useState(false)
    const { nativebalance } = useSelector((state) => state.native)
    const { xtokenbalance } = useSelector((state) => (state.xtoken))
    // const { xtokenbalance, nativebalance } = useSelector((state) => (state.wallet))
    const { dividendsdetails, userInfo, distributeInfo } = useSelector((state) => (state.dividends))
    console.log("xtokenbalance____555", xtokenbalance, nativebalance)

    useEffect(() => {
        if (dividendsdetails.usersAllocation) {
            settotalAllocate(dividendsdetails.usersAllocation)
            let share = (dividendsdetails.usersAllocation / dividendsdetails.totalAllocation) * 100
            settotalShare(share)
        }
    }, [dividendsdetails])

    const calculateDailyReturns = async (amount) => {
        try {
            let daiyReturns = 0
            let perReturns = 0
            let farms = JSON.parse(localStorage.getItem("Farms"))
            let price = await getTokenPricesFromFarm(farms)
            for (let i = 0; i < distributeInfo.length; i++) {
                let dividendsInfo_ = distributeInfo[i]
                let tokenInusd = isEmpty(price[dividendsInfo_.tokenAddress.toLowerCase()]) ?  1 : price[dividendsInfo_.tokenAddress.toLowerCase()]
                let users = userInfo.find((val) => (val.tokenAddress == dividendsInfo_.tokenAddress))
                let nextCycleStartTime = dividendsdetails.nextCycleStartTime
                let cycleDurationSeconds = dividendsdetails.cycleDurationSeconds
                let totalAllocation = parseFloat(dividendsdetails.totalAllocation * 10 ** 18) + (amount * 10 ** 18)
                // let usersAllocation = parseFloat(dividendsdetails.usersAllocation * 10 ** 18) + parseFloat(amount * 10 ** 18)
                let usersAllocation = parseFloat(amount * 10 ** 18)
                let pendingamount = await pendingDividendsAmount(dividendsInfo_, users, usersAllocation, nextCycleStartTime, cycleDurationSeconds, totalAllocation)
                // console.log(pendingamount, 'calculateDailyReturns', dividendsInfo_.tokenAddress)

                pendingamount = pendingamount / 10 ** 18
                let perReturn = pendingamount / (amount)
                // console.log(pendingamount, perReturn, 'calculateDailyReturns')
                if(!dividendsInfo_.notinusd){
                    perReturns = parseFloat(perReturns) + (parseFloat(perReturn) * parseFloat(tokenInusd))
                    daiyReturns = parseFloat(daiyReturns) + (parseFloat(pendingamount) * parseFloat(tokenInusd))
                }
               
                console.log(pendingamount,parseFloat(pendingamount) * parseFloat(tokenInusd), perReturn, daiyReturns, 'calculateDailyReturns', dividendsInfo_.tokenAddress)
            }
            console.log(daiyReturns, 'daiyReturns')
            if (daiyReturns <= 0) {
                setdaiyReturns(0)
            } else {
                setdaiyReturns(daiyReturns)
            }
            if (perReturns <= 0) {
                setperReturns(0)
            } else {
                setperReturns(perReturns)
            }



        } catch (err) {
            console.log(err, 'calculateDailyReturns__err')
        }
    }
    const onChange = (e) => {
        try {
            setErrors({})
            const { name, value } = e.target;
            if (name == "amount") {
                var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
                if (!numbers.test(e.target.value) && e.target.value !== "") {
                    return false
                }
                setAmount(value)
                if (value == '') {
                    ShareAllocation(0)
                } else {
                    ShareAllocation(value)
                    calculateDailyReturns(value)
                }
                IsApproveUsage(value)
            }
        } catch (err) {
            console.log(err, 'onChange_err')
        }
    };

    const ShareAllocation = (value) => {
        try {
            let total = parseFloat(value) + parseFloat(dividendsdetails.usersAllocation)
            settotalAllocate(total)
            console.log('ShareAllocation', value)
            let totalAllocation = parseFloat(value) + parseFloat(dividendsdetails.totalAllocation)
            let share = (total / totalAllocation) * 100
            share =  isNaN(share) ? 0 :share
            settotalShare((share).toFixed(2))
        } catch (err) {
            console.log(err, 'ShareAllocation_err')
        }
    }
    const Max = () => {
        try {
            setAmount(xtokenbalance)
            IsApproveUsage(xtokenbalance)
            ShareAllocation(xtokenbalance)
            calculateDailyReturns(xtokenbalance)
        } catch (err) {
            console.log(err, 'Max__err')
        }

    }


    const IsApproveUsage = async (value) => {
        try {
            const account = getWalletAddress()
            let Amount = (value * 10 ** 18).toFixed(0)
            Amount = toFixedNumber(Amount)
            let result = await CheckApproveUsage(getDividendAddress(), Amount, account)
            if (result) {
                setApprove(true)
            } else {
                setApprove(false)
            }
        } catch (err) {
            console.log(err, 'approveUsage__err')
        }
    }
    const approveUsage = async () => {
        try {
            let value = {
                amount: amount,
            }
            let validation = validateAllocation(value, xtokenbalance)
            if (!isEmpty(validation)) {
                setErrors(validation);
                return false
            }
            setIsApprove(true)
            setbntenable(true)
            const account = getWalletAddress()
            let Amount = (amount * 10 ** 18).toFixed(0)
            Amount = toFixedNumber(Amount)
            let result = await ApproveUsage(getDividendAddress(), Amount, account)
            if (result) {
                setIsApprove(false)
                setApprove(true)
                setbntenable(false)
            } else {
                setIsApprove(false)
                setApprove(false)
                setbntenable(false)
            }
        } catch (err) {
            setIsApprove(false)
            setbntenable(false)
            console.log(err, 'approveUsage__err')
        }
    }

    const Allocation = async () => {
        try {
            let value = {
                amount: amount,
            }
            let validation = validateAllocation(value, xtokenbalance)
            if (!isEmpty(validation)) {
                setErrors(validation);
                return false
            }
            setAllocation(true)
            setErrors('');
            let Amount = (amount * 10 ** 18).toFixed(0)
            Amount = toFixedNumber(Amount)
            let loaderText = `Allocating to dividends.....`
            let title = `Allocate`
            showsuccesModal(loaderText, title)
            let data = await AllocateTODividends(Amount)
            console.log("Allocation", data)
            if (data == false) {
                setAllocation(false)
                let errorText = `Error, When trying to allocate`
                setError(errorText)
            }
            else {
                let successText = `Successfully allocated to dividends`
                SetsuccessLoader(successText, data.transactionHash)
                setAllocation(false)
                setAmount('')
                getXtoken(dispatch)
            }
        } catch (err) {
            console.log(err, 'Allocation___errr')
        }

    }


    return (
        <>
            <div className={`${cascading.networkmodalstyle}`}>
                <Modal centered show={allocatemodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
                    <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
                        <h4 className={`${cascading.modaltitle}`}>Allocate RICE</h4>
                        <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

                    </Modal.Header>
                    <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

                        <div>
                            <p className={`${cascading.innerheadwhite}`}>Amount
                            </p>

                            <div className={`${cascading.inputsec} my-3`}>
                                <div className={`input-group mb-1`}>
                                    <input
                                        type="text"
                                        className={`form-control`}
                                        placeholder="0"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        value={amount}
                                        name='amount'
                                        onChange={(e) => { onChange(e) }}
                                    />

                                    <div className={`input-group-append`} >
                                        <button className={` ${cascading.inputspan}`} id="basic-addon1" onClick={() => { Max() }}>
                                            Max
                                        </button>
                                    </div>

                                </div>
                                <p className="text-danger f-12 d-block text-left">{errors?.amount}</p>

                                <p className={`${cascading.balsec}`}>Balance : {xtokenbalance} RICE</p>
                            </div>

                            <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
                            </p>


                            <div className='row mt-3'>
                                <div className='col-8 col-md-8'>
                                    <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total allocated amount</p>
                                </div>
                                <div className='col-4 col-md-4'>
                                    <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(totalAllocate, 6)} RICE</p>
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className='col-8 col-md-8'>
                                    <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total allocation share</p>
                                </div>
                                <div className='col-4 col-md-4'>
                                    <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{totalShare}%</p>
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className='col-8 col-md-8'>
                                    <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Daily returns/RICE</p>
                                </div>
                                <div className='col-4 col-md-4'>
                                    <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>${roundToSignificant(perReturns, 4)}</p>
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className='col-8 col-md-8'>
                                    <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total daily returns</p>
                                </div>
                                <div className='col-4 col-md-4'>
                                    <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>${roundToSignificant(daiyReturns, 4)}</p>
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className='col-8 col-md-8'>
                                    <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Deallocation fee</p>
                                </div>
                                <div className='col-4 col-md-4'>
                                    <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadwhitesmbrkval}`}>{dividendsdetails.deallocationFee} %</p>
                                </div>
                            </div>

                            {console.log("approve", approve)}
                            {isEmpty(amount) ?
                                <Button
                                    className={`${cascading.btnstyle} mt-4`}
                                    disabled={true}
                                >
                                    Enter amount
                                </Button> :
                                approve ?
                                    <Button className={`${cascading.btnstyle} mt-4`} onClick={() => { Allocation() }}>
                                        {allocation == true ? 'Allocating...' : 'Allocate'}
                                    </Button> :

                                    <Button className={`${cascading.btnstyle} mt-4`} disabled={bntenable} onClick={() => { approveUsage() }}>
                                        {isApprove == true ? 'Approving...' : 'Approve'}
                                    </Button>
                            }

                            <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>
                        </div>
                        {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}
                    </Modal.Body>

                </Modal>
            </div>
        </>
    )
}

