import React, { useState ,useEffect} from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/waitingremovestyle.module.css';




// import { toFixedNumber } from "../lib/FixedNumber"

import { IoMdClose } from "react-icons/io";

import {roundToSignificant } from '../hooks/useCommon';
export default function WaitingRemovemodal(props) {


  var propsdata =  props?.supplyObj
  console.log("propsdata",propsdata)

//   useEffect(() => {
//     console.log("WaitingSupplymodal",props?.supplyObj)
// }, [])


  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`}>
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>You will receive</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
              <p className={`${cascading.headlabel}`}>Waiting For Confirmation</p>
              <p className={`${cascading.vallabel}`}>Removing {`${roundToSignificant(propsdata?.out1,6)}   ${propsdata?.fromToken?.symbol} and ${roundToSignificant(propsdata?.out2,6)}    ${propsdata?.toToken?.symbol}`}</p>
              <p className={`${cascading.declabel}`}>Confirm this transcation in your wallet</p>
        </Modal.Body>
      </Modal>
    </div>
  )
}

