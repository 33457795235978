import { CURRENT_CHAIN_ID, UseWeb3 ,useWeb3} from "./useWeb3"

import nativeABI from '../config/abi/Native.json'
import contractAddress from '../config/constant/contract'

import { consolelog } from "../lib/consolelog"

//hooks
import { EstGas } from "./useCommon"

//constant
import { NATIVE_BALANCE } from "../constants"

export const getNativeAddress  =()=>{
    try{
        const CHAINID =  CURRENT_CHAIN_ID()
        let nativeAddress = contractAddress.nativetoken[`${CHAINID}`]
        return nativeAddress
    }catch(err){
        consolelog('getNativeAddress__err',err,true)
    }
}

export const useNativeContract = async()=>{
    const web3 = await useWeb3()
    try{
        let nativeAddress = getNativeAddress()
        let contract =  new web3.eth.Contract(nativeABI,nativeAddress)
        return contract
    }catch(err){
        consolelog("NativeContract__err",err,true)
        return false
    }
}

export const BalanceNative  =  async(address,dispatch)=>{
    const web3 = await useWeb3()
    try{
        let contract = await useNativeContract()
        console.log(dispatch,'BalanceNative')
        let balance = await contract.methods.balanceOf(web3.utils.toChecksumAddress(address)).call()
        console.log(dispatch,'BalanceNative')
        let Balance  = balance/10**18
        dispatch({
            type: NATIVE_BALANCE,
            payload: Balance
        })
        // return balance 
    }catch(err){
        consolelog("BalanceNative__err",err,true)
        return false
    }
}


export const ApproveToken  =  async(spenderAddress,amount,account)=>{
    const web3 = await useWeb3()
    try{
        let contract = await useNativeContract()
        let params = [web3.utils.toChecksumAddress(spenderAddress),amount]
        const {gasLimit,gasPrice}  = await  EstGas(params,nativeABI,getNativeAddress(),'approve',account)
        let Approve = await contract.methods.approve(web3.utils.toChecksumAddress(spenderAddress),amount).send({from:web3.utils.toChecksumAddress(account),gasLimit:gasLimit,gasPrice:gasPrice})
        if(Approve){
            return true
        } 
    }catch(err){
        consolelog("ApproveToken__err",err,true)
        return false
    }
}

export const CheckIsApproved = async (spenderAddress,amount,account) => {
    try {
        let contract = await useNativeContract()
        const allow = await contract.methods.allowance(account,spenderAddress).call();
        consolelog("checkIsApproved",parseInt(allow) >= amount,true)
        return (parseInt(allow) >= amount) ? true : false;
    } catch (err) {
        consolelog("checkIsApproved__err",err,true)
        return false
    }
}