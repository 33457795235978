import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/networkstyle.module.css';

import { IoMdClose } from "react-icons/io";

import { CHAINS } from '../config/env'
import { setChainId, SwitchChain, SwitchChainS } from '../hooks/useWeb3';
// import { IoMdClose } from "react-icons/io";
import { NETWORK_SWITCH } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
export default function Networkmodal({ show, handleClose }) {
  const dispatch = useDispatch()

  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={show} onHide={handleClose} className={`${cascading.modalstyle}`}>
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Network</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={handleClose} />

        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <p className={`${cascading.innerhead}`}>SELECT A NETWORK</p>
          <ul>
            {CHAINS.map((e, i) => (
              <div>
                <li>
                  <button
                    className={`${cascading.netbtn}`}
                    onClick={async () => {
                      let SwitchRes = await SwitchChainS(i);
                      if (SwitchRes) {
                        setChainId(i);
                        handleClose();
                        dispatch({
                          type: NETWORK_SWITCH,
                          payload: i.toString()
                        })
                      }

                    }}
                  >
                    <img src={e.IMAGE} className={`${cascading.netimg}`} alt='' /> {e.NAME}</button>
                </li>
                {/* <button className={`walletrow text-center`} onClick={async () => {
                let SwitchRes = await SwitchChainS(i);
                if (SwitchRes) {
                  setChainId(i);
                  handleClose();
                  dispatch({
                    type: NETWORK_SWITCH,
                    payload: i.toString()
                  })
                }

              }} >
                <img src={e.IMAGE} className={`img-fluid networkmodalimg`} alt='' />
                <p className={`label_wallet mt-3`}>{e.NAME}</p>
              </button> */}
              </div>
              // <li>
              //   <button className={`${cascading.netbtn}`}>
              //     <img src={e.img} className={`${cascading.netimg}`} alt=''/> {e.name}</button>
              //     </li>

            ))}

          </ul>

        </Modal.Body>

      </Modal>
    </div>
  )
}

