import { CURRENT_CHAIN_ID, useWeb3 } from "./useWeb3"
import { CHAINS } from "../config/env";
import MASTER_ABI from '../config/abi/masterABI.json';
import contractAddress from '../config/constant/contract'



export const getMasterAddress = () => {
    let CHAIN_ID = CURRENT_CHAIN_ID()
    let masterChefaddress = contractAddress.master[`${CHAIN_ID}`]
    console.log(masterChefaddress,'masterChefaddress')
    return masterChefaddress;
}

export const UseMaster = async () => {
    try{
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(MASTER_ABI, getMasterAddress());
        console.log("contract", contract);
        return contract;
    }catch(err){
        console.log(err,'UseMaster__err')
    }
    
}

export const GetPoolInfo = async(poolAddress)=>{
    try{
        const web3 = await useWeb3();
        const contract = await UseMaster()
        const PoolInfo = await contract.methods.getPoolInfo(web3.utils.toChecksumAddress(poolAddress)).call()
        if(PoolInfo){
            return PoolInfo
        }else{
            return {}
        }
    }catch(err){
        console.log(err,'GetPoolInfo__err')
        return {}
    }
}

export const GettotalAllocPoint = async()=>{
    try{
        const web3 = await useWeb3();
        const contract = await UseMaster()
        const TotalAllocPoint = await contract.methods.totalAllocPoint().call()
        if(TotalAllocPoint){
            return TotalAllocPoint
        }else{
            return {}
        }
    }catch(err){
        console.log(err,'GettotalAllocPoint__err')
        return {}
    }
}

export const GetemissionRate = async()=>{
    try{
        const web3 = await useWeb3();
        const contract = await UseMaster()
        const EmissionRate = await contract.methods.emissionRate().call()
        if(EmissionRate){
            return EmissionRate
        }else{
            return {}
        }
    }catch(err){
        console.log(err,'GetemissionRate__err')
        return {}
    }
}