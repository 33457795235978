// import constant
import {
    WALLET_ADDRESS,
    WALLET_DATA,
} from '../constants';

const initialValue = {
    walletaddress: '',
    walletData: {},
}

//  console.log("initialValue----->>>",initialValue);
const wallet = (state = initialValue, action) => {
    if(action.type == 'NATIVE_BALANCE'){
        console.log(action,'nativeReducer')
    }
    console.log(action,'wallet')
    switch (action.type) {
        case WALLET_ADDRESS:
            return {
                ...state,
                walletaddress: action.payload
            };
        case WALLET_DATA:
            return {
                ...state,
                walletData: action.payload
            };
        default:
            return state;
    }
}

export default wallet;