export const Duration = (seconds,type) => {
    if(type == 'hours'){
        var hours = seconds/3600
        return hours
    }
    if (type == 'days') {
        var days = seconds/86400
        return days
    }
    if (type == 'weeks') {
        var weeks = seconds/604800
        return weeks
    }
    if (type == 'months') {
        var months = seconds/2628000.000000
        return (months).toFixed(0)
    }
    if (type == 'year') {
        var year = seconds/31540000.0000000
        return year
    }
}

export const formatTime = (time) => {
    return String(time).padStart(2, '0')
}