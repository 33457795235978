

// import constant
import {
    DIVIDENDS_DETAILS,
    DISTRIBUTE_INFO,
    DIVI_USER_INFO,
    IS_LOAD_DIVIDENDS
} from '../constants';

const initialValue = {
    dividendsdetails: {},
    distributeInfo: [],
    userInfo: [],
    isdiviload: true
}

//  console.log("initialValue----->>>",initialValue);
const dividendsReducer = (state = initialValue, action) => {

    switch (action.type) {
        case DIVIDENDS_DETAILS:
            return {
                ...state,
                dividendsdetails: action.payload
            };
        case DISTRIBUTE_INFO:
            return {
                ...state,
                distributeInfo: action.payload
            };
        case DIVI_USER_INFO:
            return {
                ...state,
                userInfo: action.payload
            }
        case IS_LOAD_DIVIDENDS:
            return {
                ...state,
                isdiviload: action.payload
            }
        default:
            return state;
    }
}

export default dividendsReducer;