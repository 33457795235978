import axios from "axios";
import { API_URL } from "../config/env";
import { addReferrer ,userinvested,saveTransaction,getTransaction } from "../Routes/UserRoutes";


export const UseAddReferrer = async (param) => {

    console.log("log>>>>>>>>>>>", param);

    try {
        console.log("try");
        const data = await axios.post(API_URL + addReferrer, param);

        console.log("datadatadata", data);

        if (data) {
            return data;
        }
    }
    catch (e) {
        console.log("error", e);
    }

};

export const userinvestedhook = async (data) => {
    console.log("login data", data);
    try {
        //   if (data) {
        //     formdata.append("saleaddress", data?.saleaddress);
        //     formdata.append("walletaddress", data?.walletaddress);
        //     formdata.append("amount", data?.amount);
        //     // formdata.append("chain" , data?.chain)
        //     formdata.append("chain", CHAINS[getChainId()].CHAIN_ID)
        //   }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + userinvested,
                data,
            });
            console.log("response", respdata);
            return {
                data: respdata.data
            }
        }
        catch (err) {
            return {
                error: err
            }
        }
    }
    catch (e) {
        console.log("error", e);
    }
}

export const saveTransactionData = async (data) => {
    console.log("saveTransactionData", data);
    try {
   
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + saveTransaction,
                data,
            });
            console.log("saveTransaction_response", respdata);
            return {
                data: respdata.data
            }
    }
    catch (e) {
        console.log("saveTransaction_error", e);
    }
}

export const getTransactionData = async (data) => {
    console.log("getTransactionData", );
    try {
   
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + getTransaction,
                data
            });
            console.log("getTransactionData_response", respdata);
            return {
                data: respdata.data
            }
    }
    catch (e) {
        console.log("getTransactionData_error", e);
    }
}