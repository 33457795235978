import React, { useState, useEffect } from 'react';
import cascading from '../assests/css/landing.module.css';
import Footer from '../common/footer'
import Header from '../common/header';
import wasabibanner from "../assests/images/wasabibanner.png";
import wasabitrade from "../assests/images/wasabitrade.png";
import swapdonut from "../assests/images/swap.png";
import liquiditydonut from "../assests/images/liquidity.png";
import poolsdonut from "../assests/images/pools.png";
import boosterdonut from "../assests/images/boost.png";
import bottlebanner from "../assests/images/bottlebanner.png";

import eth_logo from "../assests/images/eth_logo.png";
import bnb_logo from "../assests/images/bnb_logo.png";
import phantom_logo from "../assests/images/phantom_logo.png";

import arbitrum_logo from "../assests/images/arbitrum_logo.png";
import polygon_logo from "../assests/images/polygon_logo.png";

import polkadot_logo from "../assests/images/polkadot_logo.png";
import cronos_logo from "../assests/images/cronos_logo.png";
import okx_logo from "../assests/images/okx_logo.png";





import media1 from '../assests/images/media1.png';
import mediahover1 from '../assests/images/mediahover1.png';

import media2 from '../assests/images/media2.png';
import mediahover2 from '../assests/images/mediahover2.png';

import media3 from '../assests/images/media3.png';
import mediahover3 from '../assests/images/mediahover3.png';

import media4 from '../assests/images/media4.png';
import mediahover4 from '../assests/images/mediahover4.png';

import { Link } from 'react-router-dom';




export default function Landingpage() {

    const [partner, setPartner] = useState([
        { "id": 1, "image": eth_logo }, { "id": 2, "image": bnb_logo, }, { "id": 3, "image": phantom_logo }, { "id": 4, "image": arbitrum_logo }, { "id": 5, "image": polygon_logo, }, { "id": 6, "image": polkadot_logo, "soon": "Coming Soon" }, { "id": 7, "image": cronos_logo, "soon": "Coming Soon" }, { "id": 8, "image": okx_logo, "soon": "Coming Soon" }
    ]);



    return (
        <>
            <Header />


            <div className={`container pt-5 pb-5 ${cascading.containmax}`}>


                <div className={`row pt-0 pt-sm-3 pb-5 align-items-center ${cascading.rowmin}`}>
                    <div className={`col-md-6 mt-0 mt-md-5 pt-md-3 ${cascading.posrel}`}>
                        {/* <span className={`${cascading.blurviolent1}`}></span> */}
                        <h1 className={`mb-0 ${cascading.welcome} ${cascading.heading1}`}>
                            {/* Welcome To <span className={`${cascading.donutback}`}>WasabiSwap</span> */}
                            Revolutionize Your <span className={`${cascading.themetxt}`}>DeFi Experience</span>
                        </h1>
                        <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>
                        Step into the world of WasabiSwap, the trailblazer in DeFi solutions for liquidity providers and savvy traders. Ignite your market edge - open the app and dive into an exhilarating trading odyssey today!
                            </p>

                        <div className={`img-fluid mt-4 pt-3 ${cascading.socialsection}`}>
                        <Link  to="https://twitter.com/" >
                          <img src={media1} className={`img-fluid ${cascading.mediass}`}/>
                          <img src={mediahover1} className={`img-fluid ${cascading.mediasshover}`}/>
                          </Link>
                        <Link  to="https://telegram.org/" >  
                        <img src={media2} className={`img-fluid ${cascading.mediass}`}/>
                          <img src={mediahover2} className={`img-fluid ${cascading.mediasshover}`}/>
                        </Link>
                        <Link  to="" > 
                         <img src={media3} className={`img-fluid ${cascading.mediass}`}/>
                          <img src={mediahover3} className={`img-fluid ${cascading.mediasshover}`}/>
                         </Link>
                         <Link  to="https://github.com/" target='_blank'> 
                        <img src={media4} className={`img-fluid ${cascading.mediass}`}/>
                          <img src={mediahover4} className={`img-fluid ${cascading.mediasshover}`}/>
                         </Link>
                        </div>
                       
                        {/* <Link to="https://wasabiswap.maticz.in/swap"><button className={`btn mt-4 ${cascading.tradenow}`}>Trade Now</button></Link> */}
                    </div>
                    <div className={`col-md-6 mt-5 mt-md-3 pe-md-0 ${cascading.posrel}`}>
                        <span className={`${cascading.blurviolent}`}></span>
                        {/* <img src={blurimg} alt='img' className={`img-fluid ${cascading.blur1}`} /> */}
                        <img src={wasabibanner} alt='img' className={`img-fluid`} />
                    </div>
                </div>


                <div className={`text-left pt-md-5 pb-0 mt-md-5 ${cascading.posrel} `}>
                    <span className={`${cascading.blurviolentnew}`}></span>
                    <span className={`${cascading.blurviolent1}`}></span>
                    <h2 className={`mb-0 pt-2 pt-md-5 ${cascading.welcome} ${cascading.heading} ${cascading.heading2}`}>Redefining <span className={`${cascading.themetxt}`}>DeFi Intelligence</span></h2>
                    <p className={`mt-3 mb-0 ${cascading.welcomepara} ${cascading.heading}`} >Discover the potential of our groundbreaking, dependable DeFi platform, engineered to deliver steady investment gains.</p>
{/* <div className='text-left'>
<Link to="https://wasabiswap.maticz.in/swap"><button className={`btn mt-4 ${cascading.tradenow}`}>Trade Now</button></Link>
</div>                    */}
                    <div className={`row pt-3 justify-content-center ${cascading.rowmin}`}>
                        {/* <div className={`col-sm-6 col-md-6 col-xl-3 mt-4`}>
                            <div className={`card ${cascading.cardstyle} ${cascading.cardstylepos1}`}>
                                <div className='text-center pb-2'>
                            <img src={swapdonut} alt='img' className={`img-fluid ${cascading.donutexp}`} />
                            </div>
                            <h5 className={`mt-2 mb-0 ${cascading.welcomess}`}>Redefining DeFi Intelligence</h5>
                            <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>Discover the potential of our groundbreaking, dependable DeFi platform, engineered to deliver steady investment gains.
                            </p>
                            </div>
                        </div> */}
                        <div className={`col-sm-6 col-md-6 col-lg-4 mt-4`}>
                        <div className={`card ${cascading.cardstyle}`}>
                                {/* <div className='text-center pb-2'>
                            <img src={liquiditydonut} alt='img' className={`img-fluid ${cascading.donutexp}`} />
                           </div> */}
                            <h5 className={`mt-2 mb-0 ${cascading.welcomess}`}>Liquidity Providing</h5>
                            <p className={`mt-3 mb-0 ${cascading.welcomepara} ${cascading.cardtext}`}>Transform your crypto holdings by joining as a liquidity provider. Select your preferred liquidity pool, deploy your assets, and start earning a share of the trading fees, contributing to the enhancement of market liquidity and stability.
                            </p>
                            </div>
                        </div>
                        <div className={`col-sm-6 col-md-6 col-lg-4 mt-4`}>
                        <div className={`card ${cascading.cardstyle} `}>
                                {/* <div className='text-center pb-2'>
                            <img src={poolsdonut} alt='img' className={`img-fluid ${cascading.donutexp}`} />
                           </div> */}
                            <h5 className={`mt-2 mb-0 ${cascading.welcomess}`}>Staking Advantages</h5>
                            <p className={`mt-3 mb-0 ${cascading.welcomepara} ${cascading.cardtext}`}>Unlock the full value of your cryptocurrency with our straightforward staking platform. Watch your investments flourish with appealing rewards, while contributing to the strength and security of the network.
                            </p>
                            </div>
                        </div>
                        <div className={`col-sm-6 col-md-6 col-lg-4 mt-4`}> 
                        <div className={`card ${cascading.cardstyle}`}>
                                {/* <div className='text-center pb-2'>
                            <img src={boosterdonut} alt='img' className={`img-fluid ${cascading.donutexp}`} />
                          </div> */}
                            <h5 className={`mt-2 mb-0 ${cascading.welcomess}`}>Profit Amplification</h5>
                            <p className={`mt-3 mb-0 ${cascading.welcomepara} ${cascading.cardtext}`}>Begin a voyage towards significant earnings with our High Yield Platform. Explore a spectrum of high-return options, designed for diverse risk profiles. Increase your earnings and advance your investment journey.
                            </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={`row pt-0 pb-5 align-items-center ${cascading.rowmin} ${cascading.rowminpaddtop}`}>
                    <div className={`col-md-7 mt-3 ${cascading.posrel}`}>
                        {/* <span className={`${cascading.blurviolent2}`}></span> */}
                        <h1 className={`mb-0 mt-5 pt-5 ${cascading.welcome} ${cascading.heading3}`}>Effortless Multi-Chain <span className={`${cascading.themetxt}`}>Trading Awaits</span></h1>
                        <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>Enter the world of seamless trading with WasabiSwap, a place where blockchain borders become non-existent. Our adaptable multi-chain platform allows for the trading of a wide array of assets across numerous networks, providing unmatched ease and efficiency. Say farewell to limitations and embrace a universe brimming with endless trading opportunities!</p>
                        <Link to="https://wasabiswap.maticz.in/swap"><button className={`btn mt-4 ${cascading.tradenow}`}>Trade Now</button></Link>
                    </div>
                    <div className={`col-md-5 mt-3  ${cascading.posrel}`}>
                        {/* <span className={`${cascading.blurviolent2}`}></span> */}
                        <span className={`${cascading.blurviolent1}`}></span>
                        <img src={wasabitrade} alt='img' className={`img-fluid ${cascading.tradeimgpos}`} />
                    </div>
                </div>


                {/* <div className={`text-center pt-5 ${cascading.posrel}`}>
                <span className={`${cascading.blurviolent}`}></span>
                    <h2 className={`mb-0 ${cascading.welcome} ${cascading.heading} ${cascading.heading1}`}>How Trading Work</h2>
                    <p className={`mt-2 mb-0 ${cascading.welcomepara} ${cascading.heading}`}>In publishing and graphic design, Lorem ipsum is a placeholder<br></br>
                        text commonly used to demonstrate the visual.
                    </p>
                    <div className={`row pt-4 pb-5 justify-content-center`}>
                        <div className={`col-sm-6 col-md-4 col-xl-4  mt-3 ${cascading.posrel}`}>
                            <img src={trade1} alt='img' className={`img-fluid ${cascading.donutexp}`} />
                            <img src={vector1} alt='img' className={`img-fluid ${cascading.donutexp1}`} />
                            <h5 className={`mt-2 mb-0 ${cascading.welcome}`}>Click The Buy Trade Button</h5>
                            <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.
                            </p>
                        </div>
                        <div className={`col-sm-6 col-md-4 col-xl-4 mt-3 ${cascading.posrel}`}>
                            <img src={trade2} alt='img' className={`img-fluid ${cascading.donutexp}`} />
                            <img src={vector2} alt='img' className={`img-fluid ${cascading.donutexp2}`} />
                            <h5 className={`mt-2 mb-0 ${cascading.welcome}`}>Connect Your Metamask Wallet</h5>
                            <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.
                            </p>
                        </div>
                        <div className={`col-sm-6 col-md-4 col-xl-4 mt-3 mb-sm-5 mb-0`}>
                            <img src={trade3} alt='img' className={`img-fluid ${cascading.donutexp}`} />
                            <h5 className={`mt-2 mb-0 ${cascading.welcome}`}>Execute Trade And Complete</h5>
                            <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.
                            </p>
                        </div>

                    </div>
                </div> */}

{/* className={`mt-3 mb-0 ${cascading.welcomepara}`} */}


                <div className={`text-center pt-md-5 pb-5 ${cascading.rowmin}`}>
                <h1 className={`mb-0 mt-0 mt-md-5 pt-2 pt-md-5 ${cascading.welcome} ${cascading.heading2}`}>Network <span className={`${cascading.themetxt}`}>Integrations</span></h1>
                    <p className={`my-4 ${cascading.welcomepara}`} >Our ecosystem is constantly expanding, with a wide array of partnerships already in place.</p>
                  
                    <div className="row justify-content-center">
                    {partner.map((e, i) =>
                        <div className={`col-6 col-sm-4 col-md-3 mt-4 ${cascading.colwidth}`}>
                        <img src={e.image} className={`img-fluid ${cascading.marqueeimg}`}  alt="banner" />  
                        <h6 className={`mt-2 ${cascading.coming}`}>{e.soon}</h6>       
                        </div>
                    )}
                    </div>


                </div>


                <div className={`pt-0 pt-lg-5 mt-5 pt-5 pt-md-0 ${cascading.rowmin}`}>
                    <div className={`mt-3 mt-lg-5 mb-5 pt-4 ${cascading.donutrose} ${cascading.posrel}`}>
                        <span className={`${cascading.blurviolentlast}`}></span>
                        <div className={`row ${cascading.rows} ${cascading.row_rev_sec}`}>
                            <div className={`col-lg-5 col-xl-5 col-md-5 mt-0 mt-md-0`}>
                                <h3 className={`mb-2 ${cascading.welcome} ${cascading.welcomepink}`}>Get Started With<br></br>
                                WasabiSwap</h3>
                                <p className={`mb-0 ${cascading.welcome1}`}>Stay Up To Date With The Latest News. We
                                    Won’t Send Any Spam & Protect Privacy.</p>
                                <div className={`pb-3 ${cascading.maillist}`}>
                                    <input type="text" className={`form-control mt-3`} placeholder="Your Email" aria-label="Username" aria-describedby="basic-addon1" />
                                    <button className={`btn mt-3 ms-2 ms-lg-4 ${cascading.blackbtn}`}>Join!</button>
                                </div>

                            </div>
                            <div className={`col-md-5 col-lg-5`}>
                                <img src={bottlebanner} alt='img' className={`img-fluid`} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>







            <Footer />
        </>
    )
}
