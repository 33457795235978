import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cascading from '../assests/css/all.module.css';

import { useSelector, useDispatch } from 'react-redux';

import { FaArrowRight, FaRegQuestionCircle } from "react-icons/fa";
import RangeSlider from 'react-range-slider-input';


import { Accordion, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { roundToSignificant } from '../hooks/useCommon';
import { GetBounsApr,GetMultiplierByBoostPoints } from '../hooks/useNFTpool';
import { GetExpectBoostMultipler ,GetPoolTotalAllocation} from '../hooks/useyeildBooster';
import { isEmpty } from '../lib/isEmpty';
import { toFixedNumber } from '../lib/FixedNumber';
function Activenewallocation(props) {

    const { record } = props
    const { allocateDetails ,allocationDetails} = useSelector((state) => (state.yield))
    const { nftpooldata } = useSelector((state) => (state.nftpool))
    const [poolInfo, setPoolInfo] = useState({})
    const [bonusPerc, setbonusPerc] = useState('0')
    const [bonusApr, setbonusApr] = useState('0')
    const [totalApr, settotalApr] = useState('0')
    const [newtotalApr, setnewtotalApr] = useState('0')
    const [Exmultiper, setExmultiper] = useState('0')
    useEffect(() => {
        let poolInfo = nftpooldata.find((val) => (val?.poolAddress?.toLowerCase() == record?.poolAddress?.toLowerCase()))
        setPoolInfo(poolInfo)
    }, [nftpooldata, record])

    useEffect(() => {
        let bonusPerc = record?.boostMultiplier * 100
        setbonusPerc(bonusPerc)
        CalculateBonusApr(bonusPerc)
    }, [record,poolInfo])


    useEffect(()=>{
        let AllocateDetail = allocateDetails.find((val) => (val.tokenId == record.tokenId && val.poolAddress == record.poolAddress))
        if(!isEmpty(AllocateDetail)){
            setExpectMultipler(AllocateDetail)
        }
        else {
            setExmultiper('0')
            setnewtotalApr('0')
            console.log('AllocateDetail__new1', Exmultiper)
        }
    },[allocateDetails])

    const setExpectMultipler = async(AllocateDetail)=>{
        try{
          let lpAmount = toFixedNumber(record.amount * 10 ** 18)
          lpAmount =lpAmount.toString()
          let boostPoint = 0
          if (AllocateDetail.type == 'allocate') {
            boostPoint = parseFloat(record?.boostPoints) + parseFloat(AllocateDetail.amount)
            boostPoint = toFixedNumber(parseFloat(boostPoint) * 10 ** 18)
            boostPoint = boostPoint.toString()
          }
          else {
            boostPoint = parseFloat(record.boostPoints) - parseFloat(AllocateDetail.amount)
            boostPoint = toFixedNumber(parseFloat(boostPoint) * 10 ** 18)
            boostPoint = boostPoint.toString()
          }
          console.log('setExpectMultipler',boostPoint,record?.poolAddress,lpAmount)
          let boostmultiplier = await GetMultiplierByBoostPoints(record.poolAddress,lpAmount,boostPoint)
          setExmultiper(boostmultiplier)
          setbonusPerc(boostmultiplier*100)
          CalculatenewBonusApr(boostmultiplier*100)
        }catch(err){
          console.log(err,'setExpectMultipler___err')
        }
      }

    //   const setExpectMultipler = async(AllocateDetail)=>{
    //     try{
    //       let poolAddress  = record.poolAddress
    //       let maxBoostMultiplier = toFixedNumber(poolInfo.maxBoostMultiplier*10000)
    //       let lpAmount =  toFixedNumber(record.amount *10**18)
    //       let totalLpSupply = poolInfo.lpSupply.toString()
    //       let userAllocation = toFixedNumber(allocationDetails.UserTotalAllocation *10**18)
    //       let poolAllocation  =  await GetPoolTotalAllocation(poolAddress)
    //       if(AllocateDetail.type == 'allocate'){
    //         userAllocation = userAllocation + toFixedNumber((AllocateDetail.amount)*10**18)
    //         poolAllocation =  parseFloat(poolAllocation) +toFixedNumber((AllocateDetail.amount)*10**18)
    //       }else{
    //         userAllocation = userAllocation - toFixedNumber((AllocateDetail.amount)*10**18)
    //         poolAllocation =  parseFloat(poolAllocation) -toFixedNumber((AllocateDetail.amount)*10**18)
    //       }
          
          
    //       console.log(poolAllocation,'poolAllocation',poolInfo.lpSupply)
    //       let multiperVal =  await GetExpectBoostMultipler(maxBoostMultiplier.toString(),lpAmount.toString(),userAllocation.toString(),totalLpSupply.toString(),poolAllocation.toString())
    //       console.log(multiperVal,'setExpectMultipler',poolInfo.lpSupply)
    //       setExmultiper(multiperVal)
    //       CalculatenewBonusApr(multiperVal*100)
    //       CalculatenewBonusApr(multiperVal*100)
    //     }catch(err){
    //       console.log(err,'setMultipler__err')
    //     }
    //   }

    const CalculateBonusApr = (bonusPerc) => {
        try {
            if (!isEmpty(poolInfo) && !isEmpty(record)) {
                let bonusPercentage = parseFloat(record.lockMultiplier) * 100 + bonusPerc
                let BonusApr = GetBounsApr({
                    annualInterestRate: poolInfo.FramBaseApr,
                    bonusPercentage: bonusPercentage
                })
                BonusApr = isNaN(BonusApr) ? 0 : BonusApr
                setbonusApr(BonusApr)
                let totalApr = parseFloat(BonusApr) + parseFloat(poolInfo.FramBaseApr) + parseFloat(poolInfo.SwapFeeApr)
                settotalApr(totalApr)
            }
        } catch (err) {
            console.log('CalculateBonusApr__err')
        }
    }

    const CalculatenewBonusApr = (bonusPerc) => {
        try {
            if (!isEmpty(poolInfo) && !isEmpty(record)) {
                let bonusPercentage = parseFloat(record.lockMultiplier) * 100 + bonusPerc
                let BonusApr = GetBounsApr({
                    annualInterestRate: poolInfo.FramBaseApr,
                    bonusPercentage: bonusPercentage
                })
                BonusApr = isNaN(BonusApr) ? 0 : BonusApr
                setbonusApr(BonusApr)
                let totalApr = parseFloat(BonusApr) + parseFloat(poolInfo.FramBaseApr) + parseFloat(poolInfo.SwapFeeApr)
                setnewtotalApr(totalApr)
            }
        } catch (err) {
            console.log('CalculateBonusApr__err')
        }
    }

    // useEffect(() => {
    //     CalculateBonusApr()
    // }, [bonusPerc, poolInfo])
    return (
        <>
            <div className='d-flex align-items-center justify-content-between'>
                <div>
                    <p className={`mb-0 d-flex align-items-center small ${cascading.labelvalue} ${cascading.calculatorbtn}`}>{roundToSignificant(totalApr,6)}%</p>
                    <p className='mb-0 small'>x{record.boostMultiplier}</p>
                </div>
                <FaArrowRight />
                <div>
                    <div>
                        <p className={`mb-0 d-flex align-items-center small ${cascading.labelvalue} ${cascading.calculatorbtn}`}>{roundToSignificant(newtotalApr,6)}%

                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-1'>Swap fees APR: {roundToSignificant(poolInfo.SwapFeeApr, 4)}%</p>
                                        <p className='mb-1'>Farm APR:{roundToSignificant(poolInfo.FramBaseApr, 4)}%</p>
                                        <p className='mb-0'>Bonus APR:{roundToSignificant(bonusApr, 4)}%</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                            </OverlayTrigger>
                        </p>
                        <p className='mb-0 small'>x{roundToSignificant(Exmultiper,1)}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Activenewallocation
