import toast, { Toaster } from 'react-hot-toast';
import { useWeb3, getChainId } from "./useWeb3";
import { getSaleInfoCard, UseSale, UseERC20 } from "./useContract";
import { iconTheme, position, style } from "./useToast";

//Actions
import { UseAddReferrer, userinvestedhook, saveTransactionData } from "../Actions/LaunchActions";

//config
import { CHAINS, FRONT_URL } from "../config/env";

//lib
import { toFixedNumber } from '../lib/FixedNumber';
import { getWalletAddress } from '../lib/localStorage';



export const calculateBuy = async (saleAddress, bnbValue) => {
    try {
        const saleContact = await UseSale(saleAddress);
        const bnbValues = toFixedNumber(bnbValue * 10 ** 18);
        console.log("claim token in use buy", bnbValues);
        const willGet = await saleContact.methods.getTokensPerEth(bnbValues.toString()).call();
        console.log("willGet",willGet)
        return willGet;
    }
    catch (err) {
        console.log("calculateBuy", err)
    }
}


export const BuyToken = async (saleAddress, useramount, isMax, maxNumber, account, decimal, referrer, tokenaddress, rewardAmount, tokensymbol) => {

    const web3 = await useWeb3();
    try {
        // debugger
        console.log("buytoken data", saleAddress, useramount, isMax, maxNumber, account, decimal, referrer);
        const saleContact = await UseSale(saleAddress);
        const saleInfo = await getSaleInfoCard(saleAddress);

        const amount = !isMax ? toFixedNumber(useramount * 10 ** decimal) : toFixedNumber(maxNumber * 10 ** decimal);
        console.log("MaxVC saleInfo.buytype", saleInfo, amount, maxNumber, amount > saleInfo.maxEthLimit)
        if (saleInfo.buytype) {
            console.log("if");
            var data =await saleContact.methods.contribute(amount.toString(), referrer).send({ from: account, value: amount.toString() })
        }
        else {
            console.log("else");
            var data =await saleContact.methods.contribute(amount.toString(), referrer).send({ from: account })
        }
        // const data = web3.eth.sendTransaction({ from: account, to: saleAddress, value: amount.toString() })

        // await toast.promise(data, {
        //     loading: 'Making a Buy Token Request...',
        //     success: 'Bought Token Successfully',
        //     error: 'Error ! When Buying Token',
        // }
        // );
        
        console.log("Buy", data)
        if (data) {
            toast.success('Bought Token Successfully')
            const affData = {

                parantaddress: referrer,
                saleaddress: saleAddress,
                useraddress: account,
                TokenAddress: tokenaddress,
                tokensymbol: tokensymbol,
                buyAmount: parseFloat(useramount),
                link: `${FRONT_URL}/launchpaddetail/${saleAddress}/${referrer}`,
                rewardAmount: parseFloat(rewardAmount),
            }

            var buy = await UseAddReferrer(affData);
            console.log("buy", buy)

            const Saledata =await  getSaleInfoCard(saleAddress)
            console.log("Saledata",Saledata,Saledata.earnedCap,Saledata.hardCap)

            let earnedcap = parseFloat(Saledata.earnedCap)/ 10 ** 18/parseFloat(Saledata.hardCap)/ 10 ** 18
            console.log("earnedcap",earnedcap)
            const transData ={
                saleaddress: saleAddress,
                chainid :  CHAINS[getChainId()].CHAIN_ID,
                TransactionHash : await data.transactionHash,
                earnedCap : toFixedNumber(earnedcap)
            }
            var trans = await saveTransactionData(transData)
            console.log("trans",trans)


        }else{
            toast.error('Error ! When Buying Token',) 
        }

        let wallet = getWalletAddress();
        if (wallet) {
            wallet = wallet.toString();
            wallet = wallet.toLowerCase();
            console.log("wallet", wallet);
            let payload = {
                walletaddress: wallet,
                saleaddress: saleAddress,
                amount: useramount,
                chain: CHAINS[getChainId()].CHAIN_ID
            }
            let usercontribute = await userinvestedhook(payload);
            console.log("usercontribute", usercontribute)
        }
        return true
    }
    catch (err) {
        console.log("BuyToken", err)
        toast.error('Error ! When Buying Token',) 
        return false
    }
}

export const claimToken = async (saleAddress, account) => {
    try {
        const saleContact = await UseSale(saleAddress);
        const data = saleContact.methods.claimTokens().send({ from: account });
        await toast.promise(data, {
            loading: 'Requesting for Claim Tokens...',
            success: 'Tokens Claimed Successfully',
            error: 'Error ! When Claiming Token',
        }
        );
    }
    catch (err) {
        console.log("claimToken", err)
    }
}

export const approveContractbuy = async (account, token, presaleaddress) => {
    try {
        const erc20Contract = await UseERC20(token);
        const symbol = await erc20Contract.methods.symbol().call();
        const data = erc20Contract.methods.approve(presaleaddress, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account })
        await toast.promise(data, {
            loading: `Approving ${symbol} token...`,
            success: 'Approved Successfully',
            error: 'Try Again',
        })
    }
    catch (err) {
        console.log("approveContractbuy", err)
    }
}

export const claimReferralReward = async (saleAddress) => {
    try {
        console.log("claimReferralReward",saleAddress)
        const saleContact = await UseSale(saleAddress);
        console.log(saleContact,'saleContact')
        const data =  saleContact.methods.claimReferralReward(getWalletAddress()).send({from:getWalletAddress()});
        console.log("claimReferralReward",data)
        await toast.promise(data,{
            loading: 'Requesting for Referral reward...',
            success :'Reward Claimed Successfully',
            error:'Error ! When Claiming Reward'

        })
    }
    catch (err) {
        console.log("claimReferralReward_err", err)
    }
}

export const getclaimReferral = async (saleAddress) => {
    try {
        console.log("getclaimReferral",saleAddress)
        const saleContact = await UseSale(saleAddress);
        console.log(saleContact,'saleContact')
        const refReward = await saleContact.methods.refRewards(getWalletAddress()).call();
        console.log("getclaimReferral_data",refReward)
        return refReward
    }
    catch (err) {
        console.log("getclaimReferral_err", err)
    }
}



export const getUserWhitelistAmount = async (saleAddress) => {
    try {
        console.log("getUserWhitelistAmount",saleAddress)
        const saleContact = await UseSale(saleAddress);
        console.log(saleContact,'saleContact')
        const whitelistAmount = await saleContact.methods.getUserWhitelistAmount(getWalletAddress()).call();
        console.log("getUserWhitelistAmount_data",whitelistAmount)
        return whitelistAmount
    }
    catch (err) {
        console.log("getUserWhitelistAmount_err", err)
    }
}

export const getLaunchReceipt = async (saleAddress) => {
    try {
        console.log("getLaunchReceipt",saleAddress)
        const saleContact = await UseSale(saleAddress);
        console.log(saleContact,'saleContact')
        const LaunchReceiptAddress = await saleContact.methods._launchpadRecipt().call();
        console.log("LaunchReceiptAddress_data",LaunchReceiptAddress)
        return LaunchReceiptAddress
    }
    catch (err) {
        console.log("LaunchReceiptAddress_err", err)
    }
}