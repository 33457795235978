// import constant
import {
    REDEEM_INFO,
    REDEEM_ALLOCATION,
    XTOKEN_BALANCE
} from '../constants';

const initialValue = {
    redeemDetails: [],
    redeemAllocation: 0,
    xtokenbalance: 0
}

//  console.log("initialValue----->>>",initialValue);
const xTokenReducer = (state = initialValue, action) => {
    switch (action.type) {
        case REDEEM_INFO:
            return {
                ...state,
                redeemDetails: action.payload
            };
        case REDEEM_ALLOCATION:
            return {
                ...state,
                redeemAllocation: action.payload
            };
        case XTOKEN_BALANCE:
            return {
                ...state,
                xtokenbalance: action.payload
            };
        default:
            return state;
    }
}

export default xTokenReducer;