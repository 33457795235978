import { isEmpty } from "../lib/isEmpty";

export const validateStakeFarms = (value) => {
    console.log("validateStakeFarms_value",value,value.stakeValue,value.stakeValue > value.bal,  value.bal)
    let errors = {};

    if (isEmpty(value.stakeValue)) {
        errors.stakeValue = "Stake amount field is required"
    } else if (value.stakeValue > value.bal) {
        errors.stakeValue = "Stake value should not be greater than lp balance"
    }

    return errors;
}


export const validateUnStakeFarms = (value) => {
    console.log("validateStakeFarms_value",value,value.unstakeValue,value.unstakeValue > value.StakedAmount,  value.StakedAmount)
    let errors = {};

    if (isEmpty(value.unstakeValue)) {
        errors.unstakeValue = "Stake amount field is required"
    } else if (value.unstakeValue > value.StakedAmount) {
        errors.unstakeValue = "Unstake value should not be greater than staked balance"
    }

    return errors;
}
