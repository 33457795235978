import React, { useEffect, useState } from 'react'
import { Modal, Button, Dropdown } from 'react-bootstrap';
import cascading from '../assests/css/apycalculatorstyle.module.css';
import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { useSelector, useDispatch } from 'react-redux';
import { IoMdClose } from "react-icons/io";

//hooks
import { TokenBalance } from '../hooks/useTokens'
import { ApproveUsage, CheckApproveUsage, Allocate,GetUsageAllocations, Deallocate, getXLaunchAddress } from '../hooks/useXtoken'
import { getXtoken , roundToSignificant} from '../hooks/useCommon'
import { getLaunchReceipt } from '../hooks/useBuy'

//lib
import { getWalletAddress } from '../lib/localStorage';
import { isEmpty } from '../lib/isEmpty';

//validations
import { validateAllocation } from '../validation/LaunchValidation'

export default function Allocatemodal(props) {

  let dispatch = useDispatch()
  console.log("SaleData", props.SaleData,props.launchshare,props.totalShare)

  const [allocatemodal, setAllocatemodal] = useState(true);
  const [xtokenbal, setXtokenbal] = useState(0);
  const [amount, setAmount] = useState('');
  const [approve, setApprove] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [allocation, setAllocation] = useState(false);
  const [errors, setErrors] = useState('')
  const [sale, setSale] = useState('')
  const [saleAdd, setSaleAdd] = useState('')
  const [receiptAdd, setReceiptAdd] = useState('')
  const [allocBal, setAllocBal] = useState(0);



  // const { xtokenbalance, nativebalance } = useSelector((state) => (state.wallet))
  const {launchshare,totalShare} = props
  // console.log("xtokenbalance____555", xtokenbalance, nativebalance,props.launchShare)
  const {xtokenbalance} = useSelector((state)=>(state.xtoken))
  const { nativebalance } = useSelector((state) => state.native)
  console.log("xtokenbalance____555", xtokenbalance, nativebalance)
  // setXtokenbal(xtokenbalance)

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name == "amount") {
      var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
      if (!numbers.test(e.target.value) && e.target.value !== "") {
        return false
      }
      setAmount(value)
      ShareAllocate(value)
      checkIsApprove(getXLaunchAddress(), (value * 10 ** 18).toString(), getWalletAddress())
    }
  };


  const Max = () => {
    setAmount(xtokenbalance)
  }

  const Approve = async () => {
    let value = {
      amount: amount,
    }
    let validation = validateAllocation(value, xtokenbalance)
    if (isEmpty(validation)) {
      setIsApprove(true)
      setErrors('');
      let data = await ApproveUsage(receiptAdd, (amount * 10 ** 18).toString(), getWalletAddress())
      setApprove(data)
      if (data == false) {
        setIsApprove(false)
      }
      else {
        setIsApprove(false)

      }
    }
    else {
      setErrors(validation);
    }
  }

  const checkIsApprove = async (usageAddress, amount, account) => {
    console.log(amount, 'checkIsApprove')
    let result = await CheckApproveUsage(usageAddress, amount, account)
    setApprove(result)
    console.log("setApprove", result)
  }

  useEffect(() => {
    let Amount = '115792089237316195423570985008687907853269984665640564039457584007913129639935'
    checkIsApprove(receiptAdd, isEmpty(amount) ? Amount : (amount * 10 ** 18).toString(), getWalletAddress())
  }, [amount])

  const Allocation = async () => {
    let value = {
      amount: amount,
    }
    let validation = validateAllocation(value, xtokenbalance)
    if (isEmpty(validation)) {
      setAllocation(true)
      setErrors('');
      let usageData = '0x'
      let data = await Allocate(receiptAdd, (amount * 10 ** 18).toString(), usageData, getWalletAddress())
      console.log("Allocation", data)
      if (data == false) {
        setAllocation(false)
      }
      else {
        setAllocation(false)
        setAmount('')
        getXtoken(dispatch)
      }
      props.getreceiptAdd()
      props.onHide()
    }
    else {
      setErrors(validation);
    }
  }

  // useEffect(() => {
  //   getreceiptAdd()
  // }, [])

  const getreceiptAdd = async (saleAdd) => {
    console.log("saleAdd", saleAdd)
    let data = await getLaunchReceipt(saleAdd)
    console.log("getreceiptAdd", data)
    setReceiptAdd(data)
    getAllocations(data)
  }


  const getAllocations = async (receiptAdd) => {
    let allocate = await GetUsageAllocations(receiptAdd)
    console.log("allocate_bal", allocate)
    setAllocBal(allocate)
  }

  useEffect(()=>{
    settotalAllocate(allocBal)
    settotalShare(launchshare)
  },[allocBal,totalShare])

  const[totalAllocate,settotalAllocate] = useState()
  const[totalshare,settotalShare] = useState()

  const ShareAllocate = (value)=>{
    let total  =  parseFloat(allocBal)+ parseFloat(value)
    settotalAllocate(total)
    let totalalloc = parseFloat(value)+parseFloat(props.totalShare)
    let share =(total/  totalalloc)*100
    settotalShare(share)
  }
  
  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={allocatemodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Allocate RICE</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>


            <div className={`${cascading.modal_ddrop}`}>
              <p className={`${cascading.innerheadwhite}`}>Sale Address
              </p>
              <Dropdown className={`${cascading.dropdownstyle}`}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {isEmpty(sale) ? 'Select sale' : sale}
                </Dropdown.Toggle>

                <Dropdown.Menu className={`${cascading.dropdownmenustyle} ${cascading.dropdownmenuscrl}`}>
                  {props.SaleData.map((e, i) => {
                    console.log("e", e)
                    return (
                      <>
                        <Dropdown.Item href="#/action-1" onClick={() => { setSale(e._name); setSaleAdd(e._sale); getreceiptAdd(e._sale) }}>{e._name}</Dropdown.Item>
                      </>
                    )

                  })
                  }

                  {/* <Dropdown.Item href="#/action-2">0x12367587967676hjuy</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className='mt-3'>
              <p className={`${cascading.innerheadwhite}`}>Amount
              </p>

              <div className={`${cascading.inputsec} my-3`}>
                <div className={`input-group mb-1`}>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="0"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={amount}
                    name='amount'
                    onChange={(e) => { onChange(e) }}
                  />

                  <div className={`input-group-append`} >
                    <button className={` ${cascading.inputspan}`} id="basic-addon1" onClick={() => { Max() }}>
                      Max
                    </button>
                  </div>

                </div>
                <p className="text-danger f-12 d-block text-left">{errors?.amount}</p>

                <p className={`${cascading.balsec}`}>Balance : {xtokenbalance} RICE</p>
              </div>

              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
              </p>


              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total allocated amount</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{isNaN(totalAllocate) ? 0 : roundToSignificant(totalAllocate,6)} RICE</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total allocation share</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{isNaN(totalshare) ? 0 :roundToSignificant(totalshare,6)}%</p>
                </div>
              </div>

              {/* <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Daily returns/RICE</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>$0.0082</p>
                </div>
              </div> */}

              {/* <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total daily returns</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>$0</p>
                </div>
              </div> */}
              {/* 
              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Deallocation fee</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>0 RICE</p>
                </div>
              </div> */}

              {console.log("approve", approve)}
              {approve ?
                <Button className={`${cascading.btnstyle} mt-4`} onClick={() => { Allocation() }}>
                  {allocation == true ? 'Allocating...' : 'Allocate'}
                </Button> :

                <Button className={`${cascading.btnstyle} mt-4`} onClick={() => { Approve() }}>
                  {isApprove == true ? 'Approving...' : 'Approve'}
                </Button>
              }

              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>


            </div>


            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}


          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

