import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/claimcardstyle.module.css';
import { BiRightArrowAlt } from "react-icons/bi";
import netimg from '../assests/images/roundtable.png'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';

//modals
import ComingSoonmodal from '../modals/comingSoonmodal';

//lib
import { getWalletAddress } from '../lib/localStorage';
import { toFixedNumber } from '../lib/FixedNumber';

//hooks
import { claimToken, claimReferralReward, getclaimReferral } from '../hooks/useBuy';
import { roundToSignificant } from '../hooks/useCommon';

export default function Balancecard({buyToken}) {

    const [modalcoming, setmodalcoming] = useState(false);
    const [saleData, setSaleData] = useState({})
    const [UserData, setUserData] = useState({})
    const [refReward, setRefReward] = useState(0)

    const { LaunchData } = useSelector((state) => state.launch)
    const { saleInfo, userData, UseTokenInfo, AffliateInfo, getChildrendata } = LaunchData
    console.log("LaunchData", LaunchData)


    const { saleAddress } = useParams()
    console.log("LaunchpadDetail_state", saleAddress)


    useEffect(() => {
        if (saleInfo) {
            console.log(saleInfo, userData, 'Claim_useEffect')
            setSaleData(saleInfo)
            setUserData(userData)
        }
    }, [saleInfo])

    console.log("Claimcard", saleInfo, saleData)

    const Claim = async () => {
        await claimToken(saleData.saleAddress, getWalletAddress());
    }


    useEffect(() => {
        getReward()
    }, [refReward])


    const getReward = async () => {
        let reward = await getclaimReferral(saleAddress)
        console.log("getReward_reward", reward)
        setRefReward(reward)
    }

    const claimReward = async () => {
        let result = await claimReferralReward(saleAddress)
        console.log("claimReward", result)

    }


    return (
        <>
            <div className={`${cascading.claimcard}`}>
                <div className={`${cascading.toprow} mb-4`}>
                    <div>
                        <p className={`${cascading.cardname}`}>Claim</p>
                    </div>
                    {/* <div className={`${cascading.rightsec}`}>
                        <div className={`${cascading.inputsec} `}>
                            <div className={`input-group`}>
                                <input type="text" className={`form-control`} placeholder="0" aria-label="Username" aria-describedby="basic-addon1" />
                                <div className={`input-group-append`} >
                                    <button className={` ${cascading.inputspan}`} id="basic-addon1">Max</button>
                                </div>
                            </div>
                        </div>
                       
                        <button className={`btn ${cascading.btnstyle}`}>Buy</button>
                        {/* <button className={`btn ${cascading.btnstyle}`}  onClick={()=>{setmodalcoming(true)}} >Buy</button> */}

                    {/* </div>  */}
                </div>
                <div className={`${cascading.swaprow}`}>
                    <div className={`${cascading.swapsec}`}>
                        <div>
                            <img src={saleData.logo} alt='img' className={`img-fluid ${cascading.claimimg}`} />
                        </div>
                        <div>
                            <p className={`${cascading.netname}`}>{saleData.name}</p>
                            <p className={`${cascading.netprice}`}>{UserData && (isNaN(UserData?.userInvested) ? 0 : (parseInt(UserData?.userInvested) / 10 ** parseInt(saleData.decimals)).toFixed(10))}</p>
                        </div>
                    </div>
                    {/* <div className={`${cascading.swapsec}`}> 
                        <div>
                            <img src={saleData.logo} alt='img' className={`img-fluid  ${cascading.claimimg}`} />
                            
                        </div>
                        <div>
                            <p className={`${cascading.netname}`}>xGMBL</p>
                            <p className={`${cascading.netprice}`}>0</p>
                        </div>
                    </div> */}
                </div>
                <div className={`${cascading.btmsec}`}>
                <div className={`${cascading.contrirow}`}>
                        <p>Your contribution</p>
                        <p>{UserData && (isNaN(UserData?.userInvested) ? 0 : (parseInt(UserData?.userInvested) / 10 ** parseInt(saleData.decimals)).toFixed(10))} {buyToken}</p>
                    </div>
                    <div className={`${cascading.contrirow}`}>
                        <p>Your claimable</p>
                        <p>{UserData && (isNaN(UserData?.actualBalance) ? 0 : (parseInt(UserData?.actualBalance) / 10 ** parseInt(saleData.decimals)).toFixed(10)) - (isNaN(UserData?.userClaimbale) ? 0 : (parseInt(UserData?.userClaimbale) / 10 ** parseInt(saleData.decimals)).toFixed(10))} {saleData && saleData.symbol}</p>
                    </div>

                    {saleData && saleData.isVested ?
                        <button className={`btn ${cascading.btnstyle}`} onClick={() => { Claim() }} disabled={!saleData?.isClaimable}>Vested  {saleData?.symbol} </button> :
                        (saleData && saleData.LaunchpadType == false ?
                            <button onClick={() => { Claim() }} disabled={parseFloat(UserData.userTokenBalance) !== 0 || (UserData.userInvested * saleData.presaleRate) / 10 ** saleData.decimals <= 0}
                                className={`btn ${cascading.btnstyle}`}> Claim  {saleData?.symbol}  </button> :
                            <button onClick={() => { Claim() }} disabled={(!saleData.isClaimable || UserData.userClaimbale <= 0)}
                                className={`btn ${cascading.btnstyle}`}> Claim  {saleData?.symbol}  </button>
                        )}
                    {console.log("ClaimRefferal", refReward, refReward <= 0)}


                    <button className={`btn ${cascading.connect_btn_grey} w-100 my-3`} onClick={() => { claimReward() }} disabled={refReward && refReward <= 0}>Claim Referral Earnings</button>


                    {/* <button className={`btn ${cascading.btnstyle}`}>Claim</button> */}
                    {/* <button className={`btn ${cascading.btnstyle}`}  onClick={()=>{setmodalcoming(true)}}>Claim</button> */}


                </div>
            </div>

            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}


        </>
    )
}
