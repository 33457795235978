import { CURRENT_CHAIN_ID, UseWeb3, useWeb3 } from "./useWeb3"
import BigNumber from "bignumber.js"

import XtokenABI from '../config/abi/XtokenABI.json'
import XLAUNCH_ABI from '../config/abi/XLaunchABI.json'
import contractAddress, { XLAUNCH } from '../config/constant/contract'

//lib
import { consolelog } from "../lib/consolelog"
import { Duration } from "../lib/DateTimeHelper"

//hooks
import { EstGas, GetBlockTimeStamp } from "./useCommon"
import { multicall } from "./useMultiCall"
import { toFixedNumber } from "../lib/toFixedOf"
import { getWalletAddress } from "../lib/localStorage"
import {BalanceNative} from './useNativeToken'

import { REDEEM_ALLOCATION, REDEEM_INFO, XTOKEN_BALANCE } from "../constants"

//store
import store from "../store";

export const getXAddress = () => {
    try {
        const CHAINID = CURRENT_CHAIN_ID()
        let xtokenAddress = contractAddress.xtoken[`${CHAINID}`]
        return xtokenAddress
    } catch (err) {
        consolelog('getXAddress__err', err, true)
    }
}

export const useXcontract = async () => {
    const web3 = await useWeb3()
    try {
        let xtokenAddress = getXAddress()
        let contract = new web3.eth.Contract(XtokenABI, xtokenAddress)
        return contract
    } catch (err) {
        consolelog('Xcontract__err', err, true)
        return false
    }
}

export const XBalance = async (address, dispatch) => {
    const web3 = await useWeb3()
    try {
        let contract = await useXcontract()
        let balance = await contract.methods.balanceOf(web3.utils.toChecksumAddress(address)).call()
        console.log("XBalance", balance)
        dispatch({
            type: XTOKEN_BALANCE,
            payload: balance / 10 ** 18
        })
        return balance
    } catch (err) {
        consolelog('Xcontract__err', err, true)
        return false
    }
}

export const ConvertToken = async (amount, account) => {
    const web3 = await useWeb3()
    try {
        let contract = await useXcontract()
        let params = [amount]
        const { gasLimit, gasPrice } = await EstGas(params, XtokenABI, getXAddress(), 'convert', account)
        let convertX = await contract.methods.convert(amount).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        BalanceNative(getWalletAddress(),store.dispatch)
        XBalance(getWalletAddress(),store.dispatch)
        return convertX
    } catch (err) {
        consolelog('Xcontract__err', err, true)
        return false
    }
}

export const RedeemToken = async (amount, duration, account) => {
    const web3 = await useWeb3()
    try {
        let contract = await useXcontract()
        let params = [amount, duration]
        const { gasLimit, gasPrice } = await EstGas(params, XtokenABI, getXAddress(), 'redeem', account)
        let redeemNative = await contract.methods.redeem(amount, duration).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        XBalance(getWalletAddress(),store.dispatch)
        return redeemNative
    } catch (err) {
        consolelog('Xcontract__err', err, true)
        return false
    }
}


export const getXtokenInfo = async () => {
    try {
        let Calls = [
            {
                address: getXAddress(),
                name: "maxRedeemDuration"
            },
            {
                address: getXAddress(),
                name: "minRedeemDuration"
            },
            {
                address: getXAddress(),
                name: "minRedeemRatio"
            },
            {
                address: getXAddress(),
                name: "maxRedeemRatio"
            }
        ]
        let data = await multicall(XtokenABI, Calls)
        consolelog('multicall__data', data, true)
        return {
            maxRedeemDuration: new BigNumber(data[0]).toNumber(),
            minRedeemDuration: new BigNumber(data[1]).toNumber(),
            minRedeemRatio: new BigNumber(data[2]).toNumber(),
            maxRedeemRatio: new BigNumber(data[3]).toNumber()
        }

    } catch (err) {
        consolelog('getXtokenInfo__err', err, true)
        return false
    }
}

export const getXtokenusage = async (account) => {
    let Calls = [
        {
            address: getXAddress(),
            name: "getYUMBalance",
            params: [account]
        },
        {
            address: getXAddress(),
            name: "balanceOf",
            params: [account]
        }
    ]
    let data = await multicall(XtokenABI, Calls)
    let val = data[0][1]
    consolelog('multicall__data', { data: data, datas: new BigNumber(data[0][0]._hex).toNumber(), val: new BigNumber(val).toString() }, true,)
    return {
        allocatedAmount: toFixedNumber(new BigNumber(data[0][0]._hex).toNumber() / 10 ** 18),
        redeemingAmount: toFixedNumber(new BigNumber(data[0][1]._hex).toNumber() / 10 ** 18),
        balance: toFixedNumber(new BigNumber(data[1]).toNumber() / 10 ** 18),
    }
}

export const GetTokenOut = async (amount, duration, account) => {
    const web3 = await useWeb3()
    try {
        let contract = await useXcontract()
        let outAmount = await contract.methods.getDSPByVestingDuration(amount, duration).call({ from: web3.utils.toChecksumAddress(account) })
        consolelog('GetTokenOut', outAmount, true)
        return toFixedNumber(outAmount / 10 ** 18)
    } catch (err) {
        consolelog('GetTokenOut__err', err, true)
    }
}

export const GetReedDetails = async (account, dispatch) => {
    try {
        console.log('GetReedDetails')
        let redeemlength = await GetRedeemLength(account)
        console.log('GetReedDetails',redeemlength)
        if (redeemlength > 0) {
            let reedDetails = []
            for (let i = 0; i < redeemlength; i++) {
                let reedmInfo = await GetRedeemInfo(account, i)
                let ReedmInfo = { ...reedmInfo }
                ReedmInfo['DSPAmount'] = ReedmInfo.DSPAmount / 10 ** 18
                ReedmInfo['YUMAmount'] = ReedmInfo.YUMAmount / 10 ** 18
                ReedmInfo['dividendsAllocation'] = ReedmInfo.dividendsAllocation / 10 ** 18
                ReedmInfo['isClaim'] = new Date(ReedmInfo.endTime * 1000) > new Date() ? false : true
                reedDetails.push(ReedmInfo)
                if (redeemlength - 1 == i) {
                    let TotalReedmAllocation = reedDetails.reduce((total, currentValue) => {
                        return total = parseFloat(total) + parseFloat(currentValue.dividendsAllocation)
                    }, 0)
                    console.log(TotalReedmAllocation,reedDetails,'GetReedDetails')
                    dispatch({
                        type: REDEEM_ALLOCATION,
                        payload: TotalReedmAllocation
                    })
                    dispatch({
                        type: REDEEM_INFO,
                        payload: reedDetails
                    })
                }
            }
        }
    } catch (err) {
        console.log(err, 'GetReedDetails__err')
    }
}

export const GetRedeemLength = async (account) => {
    try {
        let contract = await useXcontract()
        let RedeemLength = await contract.methods.getUserRedeemsLength(account).call()
        console.log('GetRedeemLength',RedeemLength)
        return RedeemLength
    } catch (err) {
        consolelog('GetRedeemLength___err', err, true)
    }
}

export const GetRedeemInfo = async (account, index) => {
    const web3 = await useWeb3()
    try {
        let contract = await useXcontract()
        let RedeemInfo = await contract.methods.getUserRedeem(account, index).call({ from: web3.utils.toChecksumAddress(account) })
        return RedeemInfo
    } catch (err) {
        consolelog('GetRedeemLength___err', err, true)
    }
}

export const FinalRedeem = async (account, index) => {
    const web3 = await useWeb3()
    try {
        let contract = await useXcontract()
        let params = [index]
        const { gasLimit, gasPrice } = await EstGas(params, XtokenABI, getXAddress(), 'finalizeRedeem', account)
        let RedeemInfo = await contract.methods.finalizeRedeem(index).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        BalanceNative(getWalletAddress(),store.dispatch)
        XBalance(getWalletAddress(),store.dispatch)
        return RedeemInfo
    } catch (err) {
        consolelog('FinalReem___err', err, true)
    }
}

export const CancelRedeem = async (account, index) => {
    const web3 = await useWeb3()
    try {
        let contract = await useXcontract()
        let params = [index]
        const { gasLimit, gasPrice } = await EstGas(params, XtokenABI, getXAddress(), 'cancelRedeem', account)
        let cancelRedeemInfo = await contract.methods.cancelRedeem(index).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        return cancelRedeemInfo
    } catch (err) {
        consolelog('CancelReem___err', err, true)
    }
}

export const Allocate = async (usageAddress, amount, usageData, account) => {
    const web3 = await useWeb3()
    try {
        let contract = await useXcontract()
        console.log("Allocate", usageAddress, amount, usageData, account)

        let params = [web3.utils.toChecksumAddress(usageAddress), amount, usageData]
        const { gasLimit, gasPrice } = await EstGas(params, XtokenABI, getXAddress(), 'allocate', account)
        let allocate = await contract.methods.allocate(web3.utils.toChecksumAddress(usageAddress), amount, usageData).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        if (allocate) {
            XBalance(getWalletAddress(),store.dispatch)
            return allocate
            
        }
        return false
    } catch (err) {
        consolelog('Allocate__err', err, true)
        return false
    }
}

export const Deallocate = async (usageAddress, amount, usageData, account) => {
    const web3 = await useWeb3()
    try {

        let contract = await useXcontract()
        console.log("Deallocate", usageAddress, amount, usageData, account)

        let params = [web3.utils.toChecksumAddress(usageAddress), amount, usageData]
        const { gasLimit, gasPrice } = await EstGas(params, XtokenABI, getXAddress(), 'deallocate', account)
        let Deallocate = await contract.methods.deallocate(web3.utils.toChecksumAddress(usageAddress), amount, usageData).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        if (Deallocate) {
            XBalance(getWalletAddress(),store.dispatch)
            return Deallocate
        }

        return false
    } catch (err) {
        consolelog('Allocate__err', err, true)
        return false
    }
}

export const ApproveUsage = async (usageAddress, amount, account) => {
    const web3 = await useWeb3()
    try {
        let contract = await useXcontract()
        console.log("ApproveUsage",usageAddress, amount, account)

        let params = [web3.utils.toChecksumAddress(usageAddress), amount]
        const { gasLimit, gasPrice } = await EstGas(params, XtokenABI, getXAddress(), 'approveUsage', account)
        let approveUsage = await contract.methods.approveUsage(web3.utils.toChecksumAddress(usageAddress), amount).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        if (approveUsage) {
            return true
        }
        return false
    } catch (err) {
        consolelog('ApproveUsage__err', err, true)
        return false
    }
}

export const CheckApproveUsage = async (usageAddress, amount, account) => {
    const web3 = await useWeb3()
    try {
        let contract = await useXcontract()
        let approveUsage = await contract.methods.getUsageApproval(web3.utils.toChecksumAddress(account), web3.utils.toChecksumAddress(usageAddress)).call();
        console.log(approveUsage, amount, 'CheckApproveUsage')
        return parseFloat(amount) > parseFloat(approveUsage) ? false : true
    } catch (err) {
        consolelog('CheckApproveUsage__err', err, true)
        return false
    }
}

export const getXLaunchAddress = () => {
    try {
        const CHAIN_ID = CURRENT_CHAIN_ID();
        return XLAUNCH[CHAIN_ID];
    }
    catch (err) {
        console.log("getXLaunchAddress", err.code, err)
    }
}

export const useXLaunchcontract = async (receiptAddress) => {
    const web3 = await useWeb3()
    try {
        // let xLaunchAddress = getXLaunchAddress()
        let contract = new web3.eth.Contract(XLAUNCH_ABI, receiptAddress)
        return contract
    } catch (err) {
        consolelog('Xlaunchcontract__err', err, true)
        return false
    }
}


export const GetUsageAllocations = async (usageAddress) => {
    try {
        let contract = await useXcontract()
        const allocations = await contract.methods.getUsageAllocation(getWalletAddress(), usageAddress).call()
        const decimals = await contract.methods.decimals().call()
        console.log("allocations", allocations, decimals, allocations / 10 ** decimals)
        return allocations / 10 ** decimals
    }
    catch (err) {
        console.log("GetUsageAllocations", err.code, err)
    }
}

export const GetDeallocationTime = async (receiptAddress) => {
    try {
        let contract = await useXLaunchcontract(receiptAddress)
        const allocationsTime = await contract.methods.getUserInfo(getWalletAddress()).call()
        console.log("allocationsTime", allocationsTime, allocationsTime.allocationTime)
        const deallocationsTime = await contract.methods.deallocationCooldown().call()
        console.log("deallocationsTime", deallocationsTime)
        let SumofTime = parseFloat(allocationsTime.allocationTime) + parseFloat(deallocationsTime)
        console.log("SumofTime", SumofTime)
        let curentTime = await GetBlockTimeStamp()
        console.log("curentTime", curentTime > SumofTime)
        if (curentTime > SumofTime) {
            return {
                status: true,
                deallocationCooldown: SumofTime
            }
        }
        else {
            return {
                status: false,
                deallocationCooldown: SumofTime
            }
        }
    }
    catch (err) {
        console.log("GetDeallocationTime", err.code, err)
    }
}


export const GetLaunchpadshare = async (receiptAddress) => {
    try {
        let contract = await useXLaunchcontract(receiptAddress)
        const decimals = await contract.methods.decimals().call()
        console.log("decimals", decimals)
        const allocations = await contract.methods.getUserInfo(getWalletAddress()).call()
        console.log("allocations", allocations, allocations.allocation / 10 ** decimals)
        const TotalAllocations = await contract.methods.totalAllocation().call()
        console.log("TotalAllocations", TotalAllocations / 10 ** decimals)
        const launchShare = (parseFloat(allocations.allocation) / 10 ** decimals * 100) / (parseFloat(TotalAllocations) / 10 ** decimals)
        console.log("launchShare", toFixedNumber(launchShare))
        return {
            TotalAllocations: TotalAllocations / 10 ** decimals,
            launchShare: launchShare
        }

    }
    catch (err) {
        console.log("GetLaunchpadshare", err.code, err)
    }
}

export const GetDeallocateCooldown = async (receiptAddress) => {
    try {
        let contract = await useXLaunchcontract(receiptAddress)

        const Cooldown = await contract.methods.deallocationCooldown().call()
        console.log("Cooldown", Duration(Cooldown, 'days'))
        let result = Duration(Cooldown, 'days')
        return result

    }
    catch (err) {
        console.log("DeallocateCooldown", err.code, err)
    }
}



export const GetDeallocateFee = async (usageAddress) => {
    try {
        let contract = await useXcontract()
        const deallocateFee = await contract.methods.usagesDeallocationFee(usageAddress).call()
        console.log("deallocateFee", deallocateFee)
        return parseFloat(deallocateFee)

    }
    catch (err) {
        console.log("GetDeallocateFee", err.code, err)
    }
} 

export const GetTotalSupply =async()=>{
    try{
        let contract = await useXcontract()
        let totalSupply =  await contract.methods.totalSupply().call()
        return totalSupply
    }catch(err){
        console.log(err,'GetTotalSupply__err')
        return 0
    }
}