import contractAddress from '../config/constant/contract'
import { CURRENT_CHAIN_ID } from './useWeb3'
import { getWalletAddress, swaplocal } from "../lib/localStorage";
import { EstGas } from "./useCommon";
import router_ABI from "../config/abi/router.json";
import { consolelog } from "../lib/consolelog"
import { GetChainIndex, useWeb3 } from "./useWeb3"
import { addLiquiditylocal, addLiquidityETHlocal, removeLiquiditylocal, removeLiquidityETHlocal } from '../lib/localStorage'
import {Buffer} from 'buffer';

//lib
import { toFixedNumber } from '../lib/FixedNumber';
import { isEmpty } from '../lib/isEmpty';

// var utils = require('ethereumjs-util');


export const GetContract = async () => {
    const web3 = await useWeb3();
    try {
        console.log("GetContract__err")
        const contract = new web3.eth.Contract(router_ABI, web3.utils.toChecksumAddress(getRouterAddress()));
        return contract;
    } catch (err) {
        console.log(err, "GetContract__err")
    }
}

export const GetAmountsOut = async (addressArr, amountsIn) => {
    try {
        const web3 = await useWeb3()
        console.log(addressArr, amountsIn,'GetAmountsOut')
        const contract = await GetContract()
        const amountOut = await contract.methods.getAmountsOut(amountsIn, addressArr).call({ from: getWalletAddress() })

        let price = (parseFloat(amountOut[0]) / parseFloat(amountOut[1]))
        consolelog('GetAmountsOut', { price: price, amountOut: amountOut }, true)
        console.log(isEmpty(parseFloat(amountOut[1])),parseFloat(amountOut[1]),"GetAmountsOut")
        if(isEmpty(parseFloat(amountOut[1]))){
            return {
                price: 0,
                amountOut: 0,
                status: false
            }  
        }else{
            return {
                price: toFixedNumber(price),
                amountOut: toFixedNumber(parseFloat(amountOut[1]) / 10 ** 18),
                status: true
            }
        }
        
    } catch (err) {
        consolelog('GetAmountsOut__err', err, true)
        return{
            price:0,
            amountOut:0,
            status:false
        }
    }
}
export const Addliquidity = async (tokenA, tokenB, amountADesired, amountBDesired, amountAMin, amountBMin, deadline,qureyString) => {
    try {

        const web3 = await useWeb3();
        const contract = await GetContract()

        const account = getWalletAddress()
        const to = getWalletAddress()

        let params = [web3.utils.toChecksumAddress(tokenA), web3.utils.toChecksumAddress(tokenB), amountADesired, amountBDesired, amountAMin, amountBMin, to, deadline]
        const { gasLimit, gasPrice } = await EstGas(params, router_ABI, getRouterAddress(), 'addLiquidity', account)

        const data = await contract.methods.addLiquidity(tokenA, tokenB, amountADesired, amountBDesired, amountAMin, amountBMin, to, deadline).send({ from: getWalletAddress(), gasLimit: gasLimit, gasPrice: gasPrice })
        console.log("data in initiate addLiquidity", data);
        if (data) {
            let localdata = {
                txhash:data.transactionHash,
                qureyString:qureyString
            }
            addLiquiditylocal(JSON.stringify(localdata))
            return data
        }

        // await toast.promise(data, {
        //     loading: 'Creating New Presale ...',
        //     success: 'Presale Created Successfully',
        //     error: (err) => `Error : ${err.toString()}`,
        // }, {
        //     position: position.position,
        //     style: style,
        //     iconTheme: iconTheme
        // }
        // );

    }
    catch (err) {
        consolelog("addLiquidity__err", err, true)
        return false
    }
}


export const AddLiquidityETH = async (amountETH, token, amountTokenDesired, amountTokenMin, amountETHMin, deadline,qureyString) => {
    try {
        const web3 = await useWeb3();
        consolelog("AddLiquidityETH_AddLiquidityETH", { token, amountTokenDesired, amountTokenMin, amountETHMin, deadline }, true)

        const contract = await GetContract()

        const account = getWalletAddress()
        const to = getWalletAddress()
        let params = [web3.utils.toChecksumAddress(token), amountTokenDesired, amountTokenMin, amountETHMin.toString(), to, deadline]
        const { gasLimit, gasPrice } = await EstGas(params, router_ABI, getRouterAddress(), 'addLiquidityETH', account)

        const data = await contract.methods.addLiquidityETH(token, amountTokenDesired, amountTokenMin, amountETHMin.toString(), to, deadline).send({ from: getWalletAddress(), value: amountETH.toString(), gasLimit: gasLimit, gasPrice: gasPrice })
        console.log("data in initiate addLiquidityETH", data);
        if (data) {
            let localdata = {
                txhash:data.transactionHash,
                qureyString:qureyString
            }
            addLiquidityETHlocal(JSON.stringify(localdata))
            return data

        }
        // await toast.promise(data, {
        //     loading: 'Creating New Presale ...',
        //     success: 'Presale Created Successfully',
        //     error: (err) => `Error : ${err.toString()}`,
        // }, {
        //     position: position.position,
        //     style: style,
        //     iconTheme: iconTheme
        // }
        // );

    }
    catch (err) {
        consolelog("addLiquidity__err", err, true)
        return false
    }
}

export const Removeliquidity = async (tokenA, tokenB, liquidity, amountAMin, amountBMin, deadline,qureyString) => {
    try {
        const web3 = await useWeb3();
        console.log("Removeliquidity", tokenA, tokenB, liquidity, amountAMin, amountBMin, deadline)

        const contract = await GetContract()
        const to = getWalletAddress()
        const account = getWalletAddress()
        let params = [web3.utils.toChecksumAddress(tokenA), web3.utils.toChecksumAddress(tokenB), liquidity, amountAMin, amountBMin, to, deadline]
        const { gasLimit, gasPrice } = await EstGas(params, router_ABI, getRouterAddress(), 'removeLiquidity', account)

        const data = await contract.methods.removeLiquidity(tokenA, tokenB, liquidity, amountAMin, amountBMin, to, deadline).send({ from: getWalletAddress(), gasLimit: gasLimit, gasPrice: gasPrice })
        console.log("data in initiate removeLiquidity", data);
        if (data) {
            let localdata = {
                txhash:data.transactionHash,
                qureyString:qureyString
            }
            removeLiquiditylocal(JSON.stringify(localdata))
            return data
        }
        // await toast.promise(data, {
        //     loading: 'Creating New Presale ...',
        //     success: 'Presale Created Successfully',
        //     error: (err) => `Error : ${err.toString()}`,
        // }, {
        //     position: position.position,
        //     style: style,
        //     iconTheme: iconTheme
        // }
        // );

    }
    catch (err) {
        consolelog("removeLiquidity__err", err, true)
        return false
    }
}


export const RemoveLiquidityETH = async (token, liquidity, amountTokenMin, amountETHMin, deadline,qureyString) => {
    try {
        const web3 = await useWeb3();
        const contract = await GetContract()
        const to = getWalletAddress()
        const account = getWalletAddress()
        let params = [web3.utils.toChecksumAddress(token), liquidity, amountTokenMin, amountETHMin, to, deadline]
        const { gasLimit, gasPrice } = await EstGas(params, router_ABI, getRouterAddress(), 'removeLiquidityETH', account)

        const data = await contract.methods.removeLiquidityETH(token, liquidity, amountTokenMin, amountETHMin, to, deadline).send({ from: getWalletAddress(), gasLimit: gasLimit, gasPrice: gasPrice })
        console.log("data in initiate removeLiquidityETH", data);
        if (data) {
            let localdata = {
                txhash:data.transactionHash,
                qureyString:qureyString
            }
            removeLiquidityETHlocal(JSON.stringify(localdata))
            return data
        }
        // await toast.promise(data, {
        //     loading: 'Creating New Presale ...',
        //     success: 'Presale Created Successfully',
        //     error: (err) => `Error : ${err.toString()}`,
        // }, {
        //     position: position.position,
        //     style: style,
        //     iconTheme: iconTheme
        // }
        // );

    }
    catch (err) {
        consolelog("removeLiquidityETH__err", err, true)
        return false
    }
}


export const getRouterAddress = () => {
    try {
        let CHAIN_ID = CURRENT_CHAIN_ID()
        let routeraddress = contractAddress.router[`${CHAIN_ID}`]
        return routeraddress
    } catch (err) {

    }
}


// export const GetAmountsOut = async (addressArr, amountsIn) => {
//     try {
//         const contract = await GetContract()
//         const amountOut = await contract.methods.getAmountsOut(amountsIn, addressArr).call({ from: getWalletAddress() })
//         consolelog('GetAmountsOut',amountOut,true)
//         let price = (amountOut[0]/amountOut[1])
//         return {
//             price :toFixedNumber(price/10**18),
//             amountOut : toFixedNumber(parseFloat(amountOut[1])/10**18)}
//     } catch (err) {
//         consolelog('GetAmountsOut__err', err, true)
//     }
// }


export const GetAmountsIn = async (addressArr, amountsOut) => {
    try {
        const contract = await GetContract()
        console.log(amountsOut,"amountsOut")
        const amountIn = await contract.methods.getAmountsIn(amountsOut, addressArr).call({ from: getWalletAddress() })
        consolelog('GetAmountsIn', amountIn, true)
        let price = (amountIn[0] / amountIn[1])
        return {
            price: toFixedNumber(price),
            amountIn: toFixedNumber(parseFloat(amountIn[0]) / 10 ** 18),
            status:true
        }
    } catch (err) {
        consolelog('GetAmountsIn__err', err, true)
        return {status:false,amountIn:0}
    }
}


export const SwapExactETHForTokens  =  async(amountETH,amountOutMin,path,to,deadline,qureyString)=>{
    console.log(amountETH,amountOutMin,path,to,deadline,qureyString,'SwapExactETHForTokens')
    try{
        const web3 = await useWeb3();
        const contract = await GetContract()
        let params = [amountOutMin, path, to, deadline]
        let account = getWalletAddress()
        const { gasLimit, gasPrice } = await EstGas(params, router_ABI, getRouterAddress(), 'swapExactETHForTokens', account)
        const data = await contract.methods.swapExactETHForTokens(amountOutMin, path, to,deadline).send({ from: getWalletAddress(), value:amountETH,gasLimit: gasLimit, gasPrice: gasPrice })
        if (data) {
            let localdata = {
                txhash : data.transactionHash,
                qureyString :qureyString
            }
            swaplocal(JSON.stringify(localdata))
            return true
        }
    }catch(err){
        consolelog('SwapExactETHForTokens__err',err,true)
        return false
    }
}

export const SwapETHForExactTokens  =  async(amountETH,amountOut,path,to,deadline,qureyString)=>{
    try{
        const web3 = await useWeb3();
        const contract = await GetContract()
        let params = [amountOut, path, to, deadline]
        let account = getWalletAddress()
        const { gasLimit, gasPrice } = await EstGas(params, router_ABI, getRouterAddress(), 'swapETHForExactTokens', account)
        const data = await contract.methods.swapETHForExactTokens(amountOut, path, to,deadline).send({ from: getWalletAddress(), value:amountETH,gasLimit: gasLimit, gasPrice: gasPrice })
        if (data) {
            let localdata ={
                txhash : data.transactionHash,
                qureyString:qureyString
            }
            swaplocal(JSON.stringify(localdata))
            return true
        }
    }catch(err){
        consolelog('SwapExactETHForTokens__err',err,true)
        return false
    }
}

export const SwapTokensForExactETH  =  async(amountOut,amountInMax,path,to,deadline,qureyString)=>{
    try{
        const web3 = await useWeb3();
        const contract = await GetContract()
        let params = [amountOut, amountInMax,path, to, deadline]
        let account = getWalletAddress()
        const { gasLimit, gasPrice } = await EstGas(params, router_ABI, getRouterAddress(), 'swapTokensForExactETH', account)
        const data = await contract.methods.swapTokensForExactETH(amountOut,amountInMax, path, to,deadline).send({ from: getWalletAddress(),gasLimit: gasLimit, gasPrice: gasPrice })
        if (data) {
            let localdata ={
                txhash : data.transactionHash,
                qureyString:qureyString
            }
            swaplocal(JSON.stringify(localdata))
            return true
        }
    }catch(err){
        consolelog('SwapExactETHForTokens__err',err,true)
        return false
    }
}

export const SwapExactTokensForTokens = async(amountIn,amountOutMin,path,to,deadline,qureyString)=>{
    try{
        const web3 = await useWeb3();
        const contract = await GetContract()
        let params = [amountIn, amountOutMin,path, to, deadline]
        let account = getWalletAddress()
        const { gasLimit, gasPrice } = await EstGas(params, router_ABI, getRouterAddress(), 'swapExactTokensForTokens', account)
        const data = await contract.methods.swapExactTokensForTokens(amountIn,amountOutMin, path, to,deadline).send({ from: getWalletAddress(),gasLimit: gasLimit, gasPrice: gasPrice })
        if (data) {
            let localdata ={
                txhash : data.transactionHash,
                qureyString:qureyString
            }
            swaplocal(JSON.stringify(localdata))
            return true
        }
    }catch(err){
        consolelog('swapExactTokensForTokens',err,true)
        return false
    }
}

export const SwapTokensForExactTokens = async(amountOut, amountInMax, path, to, deadline,qureyString)=>{
    try{
        const web3 = await useWeb3();
        const contract = await GetContract()
        let params = [amountOut, amountInMax,path, to, deadline]
        let account = getWalletAddress()
        const { gasLimit, gasPrice } = await EstGas(params, router_ABI, getRouterAddress(), 'swapTokensForExactTokens', account)
        const data = await contract.methods.swapTokensForExactTokens(amountOut,amountInMax, path, to,deadline).send({ from: getWalletAddress(),gasLimit: gasLimit, gasPrice: gasPrice })
        if (data) {
            let localdata ={
                txhash : data.transactionHash,
                qureyString:qureyString
            }
            swaplocal(JSON.stringify(localdata))
            return true
        }
    }catch(err){
        consolelog('SwapExactETHForTokens__err',err,true)
        return false
    }
}
export const SwapExactTokensForETH  =  async(amountIn,amountOutMin,path,to,deadline,qureyString)=>{
    try{
        const web3 = await useWeb3();
        const contract = await GetContract()
        let params = [amountIn,amountOutMin, path, to, deadline]
        let account = getWalletAddress()
        const { gasLimit, gasPrice } = await EstGas(params, router_ABI, getRouterAddress(), 'swapExactTokensForETH', account)
        const data = await contract.methods.swapExactTokensForETH(amountIn,amountOutMin, path, to,deadline).send({ from: getWalletAddress(),gasLimit: gasLimit, gasPrice: gasPrice })
        if (data) {
            let localdata ={
                txhash : data.transactionHash,
                qureyString:qureyString
            }
            swaplocal(JSON.stringify(localdata))
            return true
        }
    }catch(err){
        consolelog('SwapExactETHForTokens__err',err,true)
        return false
    }
}


export const RemoveliquidityWithPermit = async (tokenA, tokenB, liquidity, amountAMin, amountBMin, deadline) => {
    try {
        const web3 = await useWeb3();
        // console.log("RemoveliquidityWithPermit",tokenA, tokenB, liquidity, amountAMin, amountBMin, deadline)

        // const contract = await GetContract()
        const to = getWalletAddress()
        const account = getWalletAddress()
        let deadline = ((new Date().getTime() / 1000) + 600).toFixed(0)

        // let params = [web3.utils.toChecksumAddress(tokenA),web3.utils.toChecksumAddress(tokenB), liquidity, amountAMin, amountBMin, to, deadline]

        // let params = {
        //     owner: account,
        //     spender: getRouterAddress(),
        //     value: "100000000000000",
        //     // nonce: nonce.toHexString(),
        //     deadline: deadline,
        //   }

        // let params = ["0x0A1F4c4E14Dd83018942c5813768A8bf70b78599", "0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c", "100000000000000", "0", "0", to, deadline]


        const EIP712Domain = [
            { name: 'name', type: 'string' },
            { name: 'version', type: 'string' },
            { name: 'chainId', type: 'uint256' },
            { name: 'verifyingContract', type: 'address' },
          ]
          const domain = {
            name: 'Camelot LP',
            version: '2',
            chainId : 97,
            verifyingContract: "0xFD343D5f85D625cd87a804bfDE00f6727280E9c5",
          }
          const Permit = [
            { name: 'owner', type: 'address' },
            { name: 'spender',type: 'address' },
            { name: 'value', type: 'uint256' },
            { name: 'nonce', type: 'uint256' },
            { name: 'deadline', type: 'uint256' },
          ]
          const message = {
            owner: web3.utils.toChecksumAddress(account),
            spender: web3.utils.toChecksumAddress(getRouterAddress()),
            value: 100000000000000,
            nonce: 1,
            deadline: deadline,
          }
          const data = JSON.stringify({
            types: {
              EIP712Domain,
              Permit,
            },
            domain,
            primaryType: 'Permit',
            message,
          })


        // var messageBuffer = new Buffer(params, 'hex');
        // console.log('message: ', messageBuffer);

        var signature = web3.eth.sign(account, data);
        console.log('signature: ', signature);

        signature = signature.split('x')[1];

        var r = new Buffer(signature.substring(0, 64), 'hex')
        var s = new Buffer(signature.substring(64, 128), 'hex')
        var v = new Buffer((parseInt(signature.substring(128, 130)) + 27).toString());

        console.log('r s v: ', r, s , v)

        // console.log('v: ', v)

        // var pub = utils.ecrecover(messageBuffer, v, r, s);

        // var recoveredAddress = '0x' + utils.pubToAddress(pub).toString('hex')

        // console.log('recoveredAddress: ', recoveredAddress);

        // console.log('isMatch: ', recoveredAddress === account);




        // const { gasLimit, gasPrice } = await EstGas(params, router_ABI, getRouterAddress(), 'removeLiquidity', account)

        // const data = await contract.methods.removeLiquidityWithPermit(tokenA, tokenB, liquidity, amountAMin, amountBMin, to, deadline).send({ from: getWalletAddress(), gasLimit: gasLimit, gasPrice: gasPrice })
        // console.log("data in initiate removeLiquidity", data);
        // if (data) {
        //     removeLiquiditylocal(data.transactionHash)
        //     return data
        // }


    }
    catch (err) {
        consolelog("removeLiquidity__err", err, true)
        return false
    }
}
