import React ,{useState,useEffect}from 'react'
import  cascading  from '../assests/css/footerstyle.module.css';
import { FaTelegramPlane } from "react-icons/fa";
import logo from '../assests/images/footerlogo.png';
import minilogo from '../assests/images/logomini.png'
import { Link } from 'react-router-dom';
import { IoMdMail } from "react-icons/io";
import { FaFile } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaTwitter } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import media1 from '../assests/images/media1.png';
import mediahover1 from '../assests/images/mediahover1.png';

import media2 from '../assests/images/media2.png';
import mediahover2 from '../assests/images/mediahover2.png';

import media3 from '../assests/images/media3.png';
import mediahover3 from '../assests/images/mediahover3.png';

import media4 from '../assests/images/media4.png';
import mediahover4 from '../assests/images/mediahover4.png';

import { CHAINS, FRONT_URL,ANALYTICS_URL } from '../config/env';
import { getTokenPricesFromFarm } from '../hooks/usePools';
import { getNativeAddress } from '../hooks/useNativeToken';
import { roundToSignificant } from '../hooks/useCommon';

import { useSelector } from 'react-redux';
export default function Footer() {
  const { totalTvl } = useSelector((state) => (state.nftpool))
  const [nativePrice,SetnativePrice] = useState(0)
  useEffect(()=>{
    NativePrice()
  },[])
  const NativePrice = async()=>{
    try{
      let farms = JSON.parse(localStorage.getItem("Farms"))
      let price = await getTokenPricesFromFarm(farms)
      let nativeAddress  =  getNativeAddress()
      let dspinusd = price[nativeAddress.toLowerCase()]
      SetnativePrice(dspinusd)
    }catch(err){
      console.log(err,'NativePrice__Err')
    }
  }
  return (
    <>
  <div className={`${cascading.footersec}`}>
    <div className={`container`}>
        <div className={`row mx-lg-0`}>
            {/* <div className={`col-lg-2 ${cascading.leftsec}`}>
                <img src={logo} alt='logo' className={`img-fluid ${cascading.logoimg}`}/>
                <div className={`${cascading.socialsec}`}>
                    <ul>
                        <li>
                        <Link  to="https://twitter.com/" > <FaTwitter /> </Link>
                        </li>
                        <li>
                        <Link  to="https://telegram.org/" >  <FaTelegramPlane /> </Link>
                        </li>
                        <li>
                        <Link  to="" >  <FaFile /> </Link>
                        </li>
                        <li>
                        <Link  to="https://mail.google.com/mail" > <IoMdMail /> </Link>
                        </li>
                    </ul>

                </div>
            </div> */}
            <div className={`col-lg-12 ${cascading.centersec}`}>
            <div className={`${cascading.flexFooter}`}>
             <div className={`${cascading.routerow}`}>
             <ul>
                <li><p  className={`${cascading.title}`}>Help</p></li>
                <li><Link   to="/"className={`${cascading.pageroute}`}>FAQ</Link></li>
                <li><Link  to="/" className={`${cascading.pageroute}`}>Guides</Link></li>
                <li><Link to="/" className={`${cascading.pageroute}`}>Support</Link></li>
                </ul>
             </div>
             <div className={`${cascading.routerow}`}>
             <ul>
                <li><p className={`${cascading.title}`}>Tools</p></li>
                <li><Link to="https://wasabiswap.maticz.in/info" className={`${cascading.pageroute}`}>Analytics</Link></li>
                <li><Link to="/" className={`${cascading.pageroute}`}>Bridge</Link></li>
                <li><Link to="/" className={`${cascading.pageroute}`}>CoinGecko</Link></li>
                <li><Link to="/" className={`${cascading.pageroute}`}>DexTools</Link></li>
                {/* <li><Link to="#" className={`${cascading.pageroute}`}>GeckoTerminal</Link></li> */}
                <li><Link to="/" className={`${cascading.pageroute}`}>Governance</Link></li>
                </ul>
             </div>
             <div className={`${cascading.routerow}`}>
             <ul>
                <li><p className={`${cascading.title}`}>Ecosystem</p></li>
                <li><Link to="https://wasabiswapreact.maticz.in/ricepools" className={`${cascading.pageroute}`}>Create a pool</Link></li>
                <li><Link to="https://wasabiswapreact.maticz.in/pools" className={`${cascading.pageroute}`}>My pools</Link></li>
                </ul>
             </div>
             <div className={`${cascading.routerow}`}>
             <ul>
                <li><p className={`${cascading.title}`}>Resources</p></li>
                {/* <li><Link to="https://wasabiswap.maticz.in/info" className={`${cascading.pageroute}`}>Resources</Link></li> */}
                <li><Link to="/" className={`${cascading.pageroute}`}>Audit</Link></li>
                <li><Link to="/" className={`${cascading.pageroute}`}>Contact us</Link></li>
                <li><Link to="/" className={`${cascading.pageroute}`}>Contracts</Link></li>
                <li><Link to="/" className={`${cascading.pageroute}`}>Documentation</Link></li>
                </ul>
             </div>
             </div>
            </div>
        
               

        </div>
        <hr className={`${cascading.hrline}`}/>
        <div>
        <div className={`px-lg-3 ${cascading.btmmenu}`}>
        <div className={` ${cascading.rightsec}`}>
                <div className={`${cascading.labelsec} mb-3 mb-md-0 `}>
                <img src={minilogo} alt='logo' className={`img-fluid ${cascading.minilogo}`}/>
                <p className={`${cascading.labeltxt}`}>${roundToSignificant(nativePrice,6)}</p>
                </div>
                <div className={`${cascading.labelsec} mb-3 mb-md-0`}>
                <p className={`${cascading.labeltxt}`}>TVL</p>
                <p className={`${cascading.labeltxt}`}>${roundToSignificant(totalTvl,6)}</p>
                </div>
                
            </div>
          <div>
          <div className={`${cascading.socialsec}`}>
          <div className={`${cascading.dflex}`}>
                        <Link  to="https://twitter.com/" >
                          <img src={media1} className={`img-fluid ${cascading.mediass}`}/>
                          <img src={mediahover1} className={`img-fluid ${cascading.mediasshover}`}/>        
                          </Link>
                        <Link  to="https://telegram.org/" >  
                        <img src={media2} className={`img-fluid ${cascading.mediass}`}/>
                          <img src={mediahover2} className={`img-fluid ${cascading.mediasshover}`}/>
                        </Link>
                        <Link  to="/" > 
                         <img src={media3} className={`img-fluid ${cascading.mediass}`}/>
                          <img src={mediahover3} className={`img-fluid ${cascading.mediasshover}`}/>
                         </Link>
                        <Link  to="https://github.com/" > 
                        <img src={media4} className={`img-fluid ${cascading.mediass}`}/>
                          <img src={mediahover4} className={`img-fluid ${cascading.mediasshover}`}/>
                         </Link>
</div>

                </div>
          </div>
          </div>
          <div className={`${cascading.copysec}`}>
       
       <p className={`${cascading.copytext}`}>Copyright © 2024 WASABISWAP - All Rights Reserved.</p>

</div>
      
       
        </div>

    </div>

  </div>
    

        
    </>
  )
}
