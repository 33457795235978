// import constant
import {
    YEILD_ALLOCATION_DETAILS,
    ALLOCATE_DETAILS
} from '../constants';

const initialValue = {
    allocationDetails: {},
    allocateDetails: []
}

//  console.log("initialValue----->>>",initialValue);
const yieldReducer = (state = initialValue, action) => {

    switch (action.type) {
        case YEILD_ALLOCATION_DETAILS:
            return {
                ...state,
                allocationDetails: action.payload
            };
        case ALLOCATE_DETAILS:
            return {
                ...state,
                allocateDetails: action.payload
            };
        default:
            return state;
    }
}

export default yieldReducer;