import React, { useEffect, useState } from 'react';
import cascading from '../assests/css/all.module.css';
import Footer from '../common/footer'
import Launchpaddashcard from '../components/launchpaddashcard';
import Approvecard from '../components/approvecard';
import Vestingcard from "../components/vestingcard";
import Balancecard from '../components/balancecard';
import coinicon from "../../src/assests/images/coinicon.png"

import Allocationlaunchpad from "../components/allocationlaunchpad";
import Header from '../common/header';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

//lib 
import { consolelog } from '../lib/consolelog';
import { getWalletAddress } from '../lib/localStorage'
import { toFixedNumber } from '../lib/toFixedOf'
import { isEmpty } from '../lib/isEmpty';

//hooks
import { getXtokenusage, GetLaunchpadshare, GetDeallocateFee, GetDeallocateCooldown, GetUsageAllocations, GetDeallocationTime } from '../hooks/useXtoken';
import { getTotalSalesInfo, UseTokenInfo, getlaunchpaddatalimit, isSaleLive, isUpcoming, DeletePresale } from '../hooks/useContract'
import Perkcard from '../components/perkcard';
import { getLaunchReceipt } from '../hooks/useBuy'


export default function Launchpadxgrail() {
    const navigate = useNavigate()
    //selector 
    const { walletaddress } = useSelector((state) => state.wallet)

    const [deallocateFee, setDeallocateFee] = useState(0);
    const [totalShare, setTotalShare] = useState(0);
    const [coolDownTime, setCoolDownTime] = useState(0);
    const [coolDown, setCoolDown] = useState(0);

    const [loaded, setisloaded] = useState(false);

    const [proxy, setProxy] = useState([])
    const [original, setOriginal] = useState([])
    const [loadinterval, setLoadinterval] = useState(3)
    const [searchInterval, setSearchInterval] = useState(3)
    const [interval, setInterval] = useState(3)
    const [totalSales, setTotalSales] = useState([])
    const [buyTokenDecimal, setBuyTokenDecimal] = useState(18)
    const [buyToken, setBuyToken] = useState('')
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [viewSale, setviewSale] = useState('')
    const [viewSaleData, setviewSaleData] = useState('')
    const [receiptAdd, setReceiptAdd] = useState('')
    const [allocBal, setAllocBal] = useState(0);
    const [launchShare, setLaunchShare] = useState(0);


   


    const showLoader = () => {
        setisloaded(true);
        document.getElementsByTagName("body")[0].classList.add("overflow_loading")
    }

    const hideLoader = () => {
        setisloaded(false);
        document.getElementsByTagName("body")[0].classList.remove("overflow_loading")
    }


    const [detailcard, setDetailcard] = useState([
        {
            label: "TOTAL ALLOCATIONS",
            value: "303 RICE",
            img: require("../assests/images/folder.png"),
            key: 'total'

        },
        {
            label: "DEALLOCATION COOLDOWN",
            value: "30 DAYS",
            img: require("../assests/images/unlock.png"),
            key: 'available'

        },
        {
            label: "DEALLOCATION FEE",
            value: "0%",
            img: require("../assests/images/fee_icon.png"),
            key: 'allocate'

        }

    ])
    const [dashdetail, setDashdetail] = useState([
        {
            name: "Arcton - MoneyMasters",
            desc: "Whitelist allocation + 50% bonus airdrop",
            img: require("../assests/images/divident.png"),
            mybal: "$0",
            total: "$1,980.72/xGRAIL",
            fees: "11/11/23 @ 12pm UTC",

        }
    ])

    const [perkdetail, setPerkdetail] = useState([
        {
            name: "GMBL.computer",
            desc: "Presale whitelist allocation",
            img: require("../assests/images/perk1.jpg"),
            isclosed: true,
            isopen: false,
            date: "08/27/2023",

        },
        {
            name: "Rodeo",
            desc: "Whitelist allocation + 20% bonus airdrop",
            img: require("../assests/images/perk2.jpg"),
            isclosed: true,
            isopen: false,
            date: "06/30/2023",

        },
        {
            name: "Penpie",
            desc: "Presale whitelist allocation",
            img: require("../assests/images/perk1.jpg"),
            isclosed: false,
            isopen: true,
            date: "06/12/2023",

        }
    ])


    //useEffect
    // useEffect(() => {

    //     launchSharePer()
    //     DeallocatingFee()
    //     CoolDown()
    // }, [walletaddress])


    useEffect(() => {
        // showLoader()
        // if(!isEmpty(totalShare)&& !isEmpty(coolDown)&& !isEmpty(deallocateFee)){
        fetchDatas()
        // }

    }, [totalShare, coolDown, deallocateFee])

    // const launchSharePer = async () => {
    //     if (getWalletAddress()) {
    //         let share = await GetLaunchpadshare()
    //         console.log("share", share?.TotalAllocations)
    //         setTotalShare(share?.TotalAllocations)
    //     }
    //     else {
    //         setTotalShare(0)

    //     }
    // }

    // const DeallocatingFee = async () => {
    //     if (getWalletAddress()) {
    //         let fee = await GetDeallocateFee()
    //         console.log("fee", fee)
    //         setDeallocateFee(fee)
    //     }
    //     else {
    //         setDeallocateFee(0)
    //     }
    // }

    // const CoolDown = async () => {
    //     if (getWalletAddress()) {
    //         let coolDown = await GetDeallocateCooldown()
    //         console.log("coolDown", coolDown)
    //         setCoolDown(coolDown)
    //     }
    //     else {
    //         setCoolDown(0)

    //     }
    // }


    //get function
    const fetchDatas = async () => {
        try {

            if (getWalletAddress()) {
                let CardDetail = detailcard
                for (let i = 0; i < CardDetail.length; i++) {

                    if (CardDetail[i].key == 'total') {
                        CardDetail[i].value = `${isEmpty(totalShare) ? 0 : totalShare}  RICE`
                    }
                    if (CardDetail[i].key == 'available') {
                        CardDetail[i].value = `${isEmpty(coolDown) ? 0 : coolDown}  DAYS`
                    }
                    if (CardDetail[i].key == 'allocate') {
                        CardDetail[i].value = `${isEmpty(deallocateFee) ? 0 : deallocateFee} %`
                    }
                    if (i == CardDetail.length - 1) {
                        setDetailcard(CardDetail)
                        // hideLoader()
                    }
                }

            }
        } catch (err) {
            consolelog('fetchDatas__err', err, true)
        }

    }

    useEffect(() => {
        totalSaleInfo()
    }, [])

    const totalSaleInfo = async () => {
        showLoader()
        console.log('totalSaleInfo')
        setLoading(true)
        const Proxydata = await getTotalSalesInfo();
        console.log("Proxydata", Proxydata);
        // const totalproxydata = Proxydata.filter(data => data._isWhitelisted == true)
        // console.log("totalproxydata", totalproxydata);

        // let trenddata = await gettrendingdisplayhook();
        // console.log("dataa trending", trenddata?.data?.data);
        // if (trenddata?.data?.data?.length > 0)
        // setTrending(trenddata?.data?.data)
        // var trending = trenddata?.data?.data
        // console.log("trendingdataaaaaaaaaaaaaa", trending);

        let newtotalproxy = [];
        Proxydata.map((val, i) => {
            let singledata = Proxydata.find(e => e?._sale == val.saleaddress);
            if (singledata) {
                newtotalproxy.push(singledata)
            }
        })

        Proxydata.map((datas, i) => {
            let singlesale = newtotalproxy.find(e => e._sale == datas?._sale);
            if (!singlesale) {
                newtotalproxy.push(datas)
            }
        })


        setProxy(newtotalproxy)
        console.log("trendingdataaaaaaaaaaaaaa", newtotalproxy);
        console.log("total proxy data", Proxydata);
        setOriginal(newtotalproxy)
        const total = await getlaunchpaddatalimit(newtotalproxy, 0, loadinterval);
        console.log("total>>>", total, newtotalproxy?.useWithToken);
        let data = total.map(async (val) => {
            let { symbol, tokenDecimal } = await GetBuytokeninfo(val?.useWithToken)
            // let amount = await getUserWhitelistAmount(val.saleAddress)
            // val['allocation'] = amount
            val['tokensymbol'] = symbol
            val['tokenDecimal'] = tokenDecimal
            console.log("symbol_tokenDecimal", symbol, tokenDecimal)
            return val

        })
        setTotalSales(total);
        setInterval(loadinterval);
        setLoading(false)

        hideLoader()

    }

    const GetBuytokeninfo = async (value) => {
        try {
            if (value === "0x0000000000000000000000000000000000000000") {

                // setBuyToken("BNB");
                setBuyTokenDecimal(18)
                return { symbol: 'BNB', tokenDecimal: 18 }
            } else {

                const token = await UseTokenInfo(value);
                console.log("buyToken_buyToken", token, value)
                setBuyTokenDecimal(Number(token?.decimals))
                return { symbol: token?.symbol, tokenDecimal: Number(token?.decimals) }
                // setBuyToken(token?.symbol);

            }
        }
        catch (err) {
            console.log("err", err)
        }
    }


    const loadMore = async () => {
        console.log("Lad more", totalSales.length < proxy.length)
        setIsLoading(true);
        const newly = await getlaunchpaddatalimit(proxy, interval, interval + loadinterval);
        const total = totalSales.concat(newly);
        // await this.sleep(1000)
        setTotalSales(total)
        setInterval(interval + loadinterval);
        setIsLoading(false);
    }


    useEffect(() => {
        console.log("viewSale", viewSale)
        getreceiptAdd()
        let SaleData = totalSales.find((val) => (val.saleAddress == viewSale))
        setviewSaleData(SaleData)
    }, [viewSale])

    useEffect(() => {
        setviewSale(totalSales[0]?.saleAddress)

    }, [totalSales])

    const getreceiptAdd = async () => {
        console.log("saleAdd", viewSale)
        let data = await getLaunchReceipt(viewSale)
        console.log("getreceiptAdd", data)
        setReceiptAdd(data)
        let coolDown = await GetDeallocateCooldown(data)
        setCoolDown(coolDown)
        let allocate = await GetUsageAllocations(data)
        console.log("allocate_bal", allocate)
        setAllocBal(allocate)
        let deallocateTime = await GetDeallocationTime(data)
        console.log("deallocateTime1", deallocateTime)
        setCoolDownTime(deallocateTime?.deallocationCooldown)
        // setCoolDown(deallocateTime?.deallocationCooldown)
        let share = await GetLaunchpadshare(data)
        console.log("share", share?.launchShare)
        setLaunchShare(share?.launchShare)
        setTotalShare(share?.TotalAllocations)
        let fee = await GetDeallocateFee(data)
        console.log("fee", fee)
        setDeallocateFee(fee)
    }



    // const launchSharePer = async () => {
    //     let share = await GetLaunchpadshare()
    //     console.log("share", share?.launchShare)
    //     setLaunchShare(share?.launchShare)
    //     setTotalShare(share?.TotalAllocations)
    // }

    // const DeallocatingFee = async()=>{
    //     let fee = await GetDeallocateFee()
    //     console.log("fee", fee)
    //     setDeallocateFee(fee)
    // }


    return (
        <>
            <div className={loaded ? 'loader_div' : 'loader_div d-none'} id="loader_div">
                <div className="circle">
                </div>
                <div className="circle_img">
                    <img src={coinicon} className='img-fluid' />
                </div>
            </div>
            <div className={loaded ? 'logo_overlay' : 'logo_overlay_none'}>



                <Header />
                <div className={`${cascading.innerpages} ${cascading.yumpage}`}>
                    <div className={`container`}>
                        <div className={`row ${cascading.headrow}`}>
                            <p className={`${cascading.pagehead}`}>Launchpad</p>
                            <p className={`${cascading.pagedesc}`}>Allocate RICE here to get perks and benefits from every sale happening on WasabiSwap launchpad.</p>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-12 col-md-7 col-lg-8'>

                                <p className={`${cascading.pageheadnew} my-4`}>Unlock <span className={`${cascading.spantitletheme}`}>exclusive benefits</span> from all our Launchpad partners.</p>
                                <div className='row'>
                                    {detailcard.map((e) => (

                                        <div className={`col-lg-4 mb-4`} >
                                            <Balancecard datas={e} />

                                        </div>

                                    ))}
                                </div>
                            </div>
                            <div className='col-12 col-md-5 col-lg-4'>
                                <Allocationlaunchpad sales={proxy} getreceiptAdd= {()=>{getreceiptAdd()}} receiptAdd={receiptAdd} allocBal={allocBal} coolDown={coolDown} coolDownTime={coolDownTime} launchShare={launchShare} totalShare={totalShare} deallocateFee={deallocateFee} />
                            </div>
                        </div>

                        {totalSales.length !== 0 ?
                        <div className={`row mt-4`}>

                            <div className={`col-lg-12 col-xl-12`}>
                                {/* {dashdetail.map((e) => ( */}
                                <Launchpaddashcard datas={viewSaleData}
                                    launchShare={launchShare}
                                    // navi={() => navigate(`${e.path}`)} 
                                    allocBal={allocBal} />
                                {/* ))} */}

                            </div>

                        </div>:""}

                        {totalSales.length !== 0 ?

                        <div className={`row mt-4`}>
                            <div className={`col-lg-12 col-xl-12`}>
                                <div className={`${cascading.dashcard} mb-4`}>
                                    {/* {perkdetail.map((e) => (
                                        <Perkcard datas={e} navi={() => navigate(`${e.path}`)} />
                                    ))} */}
                                    {totalSales.map((e) => (
                                        <Perkcard datas={e} Onset={(address) => { console.log("Perkcard", address); setviewSale(address) }} />
                                    ))}
                                    <div className='text-center pb-4'>
                                        {totalSales && totalSales.length < proxy.length ?
                                            <button className={`btn mt-4 ${cascading.loadbtn}`} onClick={() => { loadMore() }}>{isLoading ? 'Loading...' : 'Load More'}</button>
                                            : <></>}
                                    </div>

                                </div>

                            </div> 

                        </div>:""}
                    </div>


                </div>



                <Footer />
            </div>
        </>
    )
}
