import { CURRENT_CHAIN_ID, UseWeb3, useWeb3 } from "./useWeb3"
import BigNumber from "bignumber.js"

import XLaunchABI from '../config/abi/XLaunchReceipt_ABI.json'
import XLaunchABIReceipts from '../config/abi/XLaunchABI.json'
import { XLAUNCH } from "../config/constant/contract"

//lib
import { consolelog } from "../lib/consolelog"
import { getWalletAddress } from "../lib/localStorage"
//hooks
import { EstGas } from "./useCommon"
import { multicall } from "./useMultiCall"
import { toFixedNumber } from "../lib/toFixedOf"
import { Allocate, Deallocate, GetDeallocateFee, GetLaunchpadshare } from "./useXtoken"
import { isEmpty } from "../lib/isEmpty"

//constant
import { LAUNCH_ALLOCATION_DETAILS, YEILD_ALLOCATION_DETAILS } from "../constants"


export const getXlanunchAddress = () => {
    try {
        const CHAINID = CURRENT_CHAIN_ID()
        let xlaunchAddress = XLAUNCH[`${CHAINID}`]
        return xlaunchAddress
    } catch (err) {
        consolelog('getXAddress__err', err, true)
    }
}


export const UseXlanunchContract = async () => {
    const web3 = await useWeb3()
    try {
        let xlaunchAddress = getXlanunchAddress()
        let contract = new web3.eth.Contract(XLaunchABI, xlaunchAddress)
        return contract
    } catch (err) {
        consolelog('Xcontract__err', err, true)
        return false
    }
}


export const GetLaunchpadRecipits = async () => {
    try {
        const contract = await UseXlanunchContract()
        let LaunchRecipits = await contract.methods.getReceipts().call()
        console.log(LaunchRecipits,'LaunchRecipits')
        return LaunchRecipits
    } catch (err) {
        console.log(err, 'GetLaunchpadRecipits__err')
        return []
    }
}

export const GetRecipitsAllocation = async (dispatch) => {
    try {
        let LaunchRecipits = await GetLaunchpadRecipits()
        if (LaunchRecipits.length > 0) {
            let launchPadTotal =0
            let launchPadAllocate=0
            let DeallocateFeeTotal =0
            for (let i = 0; i < LaunchRecipits.length; i++) {
                let calls = []
                calls.push({
                    address: LaunchRecipits[i],
                    name: 'totalAllocation',
                })
                if (getWalletAddress()) {
                    calls.push({
                        address: LaunchRecipits[i],
                        name: 'getUserInfo',
                        params: [getWalletAddress()]
                    })
                }
                let DeallocateFee =  await GetDeallocateFee(LaunchRecipits[i])
                console.log(calls,'GetRecipitsAllocation')
                const [totalAllocation,UserInfo] = await multicall(XLaunchABIReceipts, calls)
                // console.log(totalAllocation,UserInfo,LaunchRecipits[i],'GetRecipitsAllocation',new BigNumber(totalAllocation[0]._hex).toNumber(),new BigNumber(UserInfo.allocation._hex).toNumber())
                launchPadTotal = (launchPadTotal) + (new BigNumber(totalAllocation[0]._hex).toNumber()/10**18)
                let userAllocation = isEmpty(UserInfo) ? 0 : (new BigNumber(UserInfo.allocation._hex).toNumber()/10**18)
                launchPadAllocate = launchPadAllocate + userAllocation
                DeallocateFeeTotal = DeallocateFeeTotal + parseFloat(DeallocateFee)
                if(LaunchRecipits.length-1 == i){
                    let obj= {
                        totalAllocation :launchPadTotal,
                        userAllocation :isEmpty(getWalletAddress()) ?  0 : launchPadAllocate,
                        deallocateFee:parseFloat(DeallocateFeeTotal)/LaunchRecipits.length
                    }
                    dispatch({
                        type:LAUNCH_ALLOCATION_DETAILS,
                        payload:obj
                    })
                }
            }
        }
    }
    catch (err) {
        console.log(err, 'GetRecipitsAllocation__err')
    }
}