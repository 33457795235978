import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/completedsupplystyle.module.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaRegQuestionCircle } from "react-icons/fa";
import { TiInfo } from "react-icons/ti";
import Switch from "react-switch";
import coinimg from '../assests/images/bnb.png'

import metaicon from '../assests/images/metamask.png';
import walleticon from '../assests/images/walletconnect.png';

import { IoMdClose } from "react-icons/io";
import { Link } from 'react-router-dom';
import { FaArrowUp } from "react-icons/fa";
import {CHAINS} from '../config/env'
import {GetChainIndex} from '../hooks/useWeb3'


export default function CompletedSupplymodal(props) {





  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`}>
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>You will receive</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>

          <FaArrowUp  fill='#fff' fontSize={40}/>

          <p className={`${cascading.vallabel}`}>Transcation Submitted</p>
          {/* <Link to="#" className={`${cascading.declabel}`}>View on BscScan</Link> */}

          <button className={`${cascading.declabel}`} onClick={()=>{
          window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem("addLiquidity")).txhash}`,'_blank');

            // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("addLiquidity")}`
            }} target="_blank" >View on BscScan</button>



        </Modal.Body>

      </Modal>
    </div>
  )
}

