import React, { useEffect, useState } from 'react'
import { Modal, Button, Dropdown } from 'react-bootstrap';
import cascading from '../assests/css/apycalculatorstyle.module.css';
import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { useSelector } from 'react-redux';
import { IoMdClose } from "react-icons/io";

//hooks
import { DeallocateTODividends,pendingDividendsAmount } from '../hooks/useDividends';

//lib
import { getWalletAddress } from '../lib/localStorage';
import { isEmpty } from '../lib/isEmpty';
import { toFixedNumber } from '../lib/FixedNumber';
import { roundToSignificant } from '../hooks/useCommon';
//validations
import { validateDeallocation } from '../validation/LaunchValidation'

import { getTokenPricesFromFarm } from '../hooks/usePools';

export default function DividendsDeallocatemodal(props) {
    const { redeemAllocation } = useSelector((state) => (state.xtoken))
    const [daiyReturns, setdaiyReturns] = useState('')
    const [perReturns, setperReturns] = useState('')
    const { showsuccesModal, SetsuccessLoader, setError } = props
    const [deallocatemodal, setDeallocatemodal] = useState(true);
    const [amount, setAmount] = useState('');
    const [deallocation, setDeallocation] = useState(false);
    const [errors, setErrors] = useState('')
    const [totalAllocate, settotalAllocate] = useState('');
    const [totalShare, settotalShare] = useState('');
    const [allocateAmount,setallocateAmount] =  useState('0')
    const { dividendsdetails, userInfo, distributeInfo } = useSelector((state) => (state.dividends))

    useEffect(()=>{
        if(!isEmpty(redeemAllocation) && !isEmpty(dividendsdetails)){
            let allocateAmount = parseFloat(dividendsdetails.usersAllocation) - parseFloat(redeemAllocation)
            allocateAmount =  isNaN(allocateAmount) ?0 :allocateAmount
            setallocateAmount(allocateAmount)
        }
    },[redeemAllocation,dividendsdetails])
    useEffect(() => {
        if (dividendsdetails.usersAllocation) {
            settotalAllocate(dividendsdetails.usersAllocation)
            let share = (dividendsdetails.usersAllocation / dividendsdetails.totalAllocation) * 100
            settotalShare((share).toFixed(2))
        }
    }, [dividendsdetails])
    const onChange = (e) => {
        const { name, value } = e.target;
        if (name == "amount") {
            var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
            if (!numbers.test(e.target.value) && e.target.value !== "" || parseFloat(value) > allocateAmount) {
                return false
            }
            setAmount(value)
            if (value == '') {
                ShareAllocation(0)
            } else {
                ShareAllocation(value)
            }

        }
    };

    const Max = () => {
        setAmount(allocateAmount)
        ShareAllocation(allocateAmount)
    }
    const ShareAllocation = (value) => {
        try {
            let total = parseFloat(dividendsdetails.usersAllocation) - parseFloat(value)
            settotalAllocate(total)
            let totalAllocation = parseFloat(dividendsdetails.totalAllocation) - parseFloat(value)
            let share = totalAllocation == 0 ? 0 : (total / totalAllocation) * 100
            console.log(share, (total / totalAllocation), totalAllocation, total, 'ShareAllocation')
            settotalShare(share)
        } catch (err) {
            console.log(err, 'ShareAllocation_err')
        }
    }

    const Deallocation = async () => {
        let value = {
            amount: amount,
            allocBal: allocateAmount
        }
        let validation = validateDeallocation(value)
        if (!isEmpty(validation)) {
            setErrors(validation);
            return false
        }
        let Amount = toFixedNumber(amount * 10 ** 18)
        Amount = parseFloat(Amount).toFixed(0)
        setDeallocation(true)
        setErrors('');
        let loaderText = `Deallocating to dividends.....`
        let title = `Deallocate`
        showsuccesModal(loaderText, title)
        let data = await DeallocateTODividends(Amount)
        console.log("Deallocation", data)
        if (data == false) {
            setDeallocation(false)
            let errorText = `Error, When trying to Deallocate`
            setError(errorText)
        }
        else {
            let successText = `Successfully Deallocated to dividends`
            SetsuccessLoader(successText, data.transactionHash)
            setDeallocation(false)
            setAmount('')
        }
    }

    return (
        <>
            <div className={`${cascading.networkmodalstyle}`}>
                <Modal centered show={deallocatemodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
                    <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
                        <h4 className={`${cascading.modaltitle}`}>Deallocate RICE</h4>
                        <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

                    </Modal.Header>
                    <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

                        <div className='mt-3'>
                            <p className={`${cascading.innerheadwhite}`}>Amount
                            </p>

                            <div className={`${cascading.inputsec} my-3`}>
                                <div className={`input-group mb-3`}>
                                    <input
                                        type="text"
                                        className={`form-control`}
                                        placeholder="0"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        value={amount}
                                        name='amount'
                                        onChange={(e) => { onChange(e) }}
                                    />
                                    <div className={`input-group-append`} >
                                        <button className={` ${cascading.inputspan}`} id="basic-addon1" onClick={() => { Max() }}>
                                            Max
                                        </button>
                                    </div>
                                </div>
                                <p className="text-danger f-12 d-block text-left">{errors?.amount}</p>
                                <p className={`${cascading.balsec}`}>Allocated :{roundToSignificant(allocateAmount,6)}RICE</p>
                                <p className={`${cascading.themenote} mt-3`}>You can only enter the value less than or equal to allocated balance</p>

                            </div>

                            <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
                            </p>


                            <div className='row mt-3'>
                                <div className='col-8 col-md-8'>
                                    <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total allocated amount</p>
                                </div>
                                <div className='col-4 col-md-4'>
                                    <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(totalAllocate,6)} RICE</p>
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className='col-8 col-md-8'>
                                    <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total allocation share</p>
                                </div>
                                <div className='col-4 col-md-4'>
                                    <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{totalShare}%</p>
                                </div>
                            </div>
                            {/* <div className='row mt-3'>
                                <div className='col-8 col-md-8'>
                                    <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Daily returns/RICE</p>
                                </div>
                                <div className='col-4 col-md-4'>
                                    <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>${roundToSignificant(perReturns, 4)}</p>
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className='col-8 col-md-8'>
                                    <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total daily returns</p>
                                </div>
                                <div className='col-4 col-md-4'>
                                    <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>${roundToSignificant(daiyReturns, 4)}</p>
                                </div>
                            </div> */}

                            <div className='row mt-3'>
                                <div className='col-8 col-md-8'>
                                    <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Deallocation fee</p>
                                </div>
                                <div className='col-4 col-md-4'>
                                    <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadwhitesmbrkval}`}>{dividendsdetails.deallocationFee} %</p>
                                </div>
                            </div>
                            <Button className={`${cascading.btnstyle} mt-4`} onClick={() => { Deallocation() }}>
                                {deallocation ? 'Deallocating...' : 'Deallocate'}
                            </Button>

                            <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

                        </div>
                    </Modal.Body>

                </Modal>
            </div>
        </>
    )
}

