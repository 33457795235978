import React, { useState } from 'react'
import cascading from '../assests/css/all.module.css';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FaTrophy } from "react-icons/fa";
import Dividentcalculatormodal from "../modals/dividentcalculatormodal";

import bnblogo from '../assests/images/bnb.png';
import bsclogo from '../assests/images/bsc.png';

import coinlogo from '../assests/images/logomini.png';

//modals
import ComingSoonmodal from '../modals/comingSoonmodal';

import { useSelector } from 'react-redux';
import { roundToSignificant } from '../hooks/useCommon';

//lib
import fileObjectUrl from '../lib/img'
export default function Currentepoch(props) {

    const [dividentcalculatormodal, setdividentCalculatormodal] = useState(false);
    const [modalcoming, setmodalcoming] = useState(false);
    const { distributeInfo } = useSelector((state) => (state.dividends))
    return (
        <div className={`${cascading.approvecard} mb-4`}>

            <div className={`${cascading.innercard} ${cascading.dashcard}`}>
                <p className={`${cascading.head} ${cascading.headfelx}`}>
                    Current Epoch
                    <div>
                        <button className={`btn me-2 ${cascading.stakebtnicon}`} onClick={()=>{setdividentCalculatormodal(true)}}>
                        {/* <button className={`btn me-2 ${cascading.stakebtnicon}`} onClick={() => { setmodalcoming(true) }}> */}
                            Calculator
                        </button>


                    </div>

                </p>

                {distributeInfo.length > 0 ? distributeInfo.map((val, i) => {
                    return (
                        <>
                            <p className={`${cascading.headfelx} mt-4`}>
                                <div className='d-flex align-items-center'>
                                    <div className={`${cascading.leftimgsec}`}>
                                        {val.isLp ?
                                            <>
                                                <img src={val.token1.image ? fileObjectUrl(val.token1.image) : bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                                                <img src={val.token2.image ? fileObjectUrl(val.token2.image) : coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                                            </>
                                            :

                                            <img src={val.token.image ? fileObjectUrl(val.token.image) : bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                                        }

                                    </div>
                                    <div>
                                        <p className={`${cascading.coinnamewhitenew} mb-0`}>{val.symbol}</p>
                                        <p className={`${cascading.coinnamewhitenew} mb-0`}>
                                            {/* {roundToSignificant(val.currentCycleDistributedAmount, 6)}
                                            <span className={`${cascading.coinnamegreynew}`}>({val.notinusd ? '' :'$'}{roundToSignificant(val.currDistributedInusd, 6)})</span> */}
                                            {roundToSignificant(val.currentDistributionAmount, 6)}
                                            <span className={`${cascading.coinnamegreynew} ps-1`}>({val.notinusd ? '' :'$'}{roundToSignificant(val.currDistributionInusd, 6)})</span>
                                        </p>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </p>
                        </>
                    )
                }) : <p className='text-white text-center'>No current epoch</p>}

                {/* <p className={`${cascading.headfelx} mt-4`}>
                    <div className='d-flex align-items-center'>
                        <div className={`${cascading.leftimgsec}`}>
                            <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                            <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                        </div>
                        <div>
                            <p className={`${cascading.coinnamewhitenew} mb-0`}>WSBI-BNB</p>
                            <p className={`${cascading.coinnamewhitenew} mb-0`}>
                                0 <span className={`${cascading.coinnamegreynew}`}>($0.0001)</span>
                            </p>
                        </div>
                    </div>
                    <div>



                    </div>

                </p>

                <p className={`${cascading.headfelx} mt-4`}>
                    <div className='d-flex align-items-center'>
                        <div className={`${cascading.tropyhyblack} ms-0`}>
                            <FaTrophy />
                        </div>
                        <div>
                            <p className={`${cascading.coinnamewhitenew} mb-0`}>RICE</p>
                            <p className={`${cascading.coinnamewhitenew} mb-0`}>
                                0 <span className={`${cascading.coinnamegreynew}`}>($0.0001)</span>
                            </p>
                        </div>
                    </div>
                    <div>


                    </div>

                </p>

                <p className={`${cascading.headfelx} mt-4`}>
                    <div className='d-flex align-items-center'>
                        <div className={`${cascading.leftimgsec}`}>
                            <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                            <img src={bsclogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                        </div>
                        <div>
                            <p className={`${cascading.coinnamewhitenew} mb-0`}>BUSD-BNB</p>
                            <p className={`${cascading.coinnamewhitenew} mb-0`}>
                                0 <span className={`${cascading.coinnamegreynew}`}>($0.0001)</span>
                            </p>
                        </div>
                    </div>
                    <div>



                    </div>

                </p> */}






            </div>





            {dividentcalculatormodal && <Dividentcalculatormodal onHide={() => setdividentCalculatormodal(false)} />}
            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}


        </div>
    )
}
