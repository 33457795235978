import { isEmpty } from "../lib/isEmpty";

export const validateAmount = (value,balance) => {
    console.log("validateAllocation_value",value,value.amount)
    let errors = {};

    if (isEmpty(value.amount)) {
        errors.amount = "Amount field is required"
    }else if(parseFloat(value.amount) > parseFloat(balance) ){
        errors.amount = "Insufficient balance"
    }
    return errors;
}
