import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/apycalculatorstyle.module.css';
import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";




import { IoMdClose } from "react-icons/io";
import { FaArrowRight } from 'react-icons/fa';

import { useSelector } from 'react-redux';

//lib
import { toFixedNumber } from '../lib/FixedNumber';


//validation 
import { validateAllocation } from '../validation/LaunchValidation'
//hooks
import { roundToSignificant } from '../hooks/useCommon';
import { GettotalAllocationFloor, GetMaxBonus,getYeildAddress, GetExpectBoostMultipler, GetPoolTotalAllocation, GetBoostMultipler } from '../hooks/useyeildBooster'
import { ApproveUsage, CheckApproveUsage } from '../hooks/useXtoken';
import { BoostPosition } from '../hooks/useyeildBooster';
import { isEmpty } from '../lib/isEmpty';
import { getWalletAddress } from '../lib/localStorage';
import { GetMultiplierByBoostPoints ,GetBounsApr} from '../hooks/useNFTpool';
import { validateAmount } from '../validation/AmountValidation';
export default function Boostmodal(props) {



  const [boostmodal, setBoostmodal] = useState(true);
  const [amount, setAmount] = useState('')
  const { xtokenbalance } = useSelector((state) => (state.xtoken))
  const [approve, setApprove] = useState(false)
  const [errors, setErrors] = useState('')
  const [poolInfo, setPoolInfo] = useState({})
  const [multiper, setmultiper] = useState('0')
  const [Exmultiper, setExmultiper] = useState('0')
  const [bonusPerc, setbonusPerc] = useState('0')
  const [bonusApr, setbonusApr] = useState('0')
  const [totalApr, settotalApr] = useState('0')
  const [isApprove, setisApprove] = useState(false)
  const [btnenable, setbtnenable] = useState(false)
  const { onSuccess, SetsuccessLoader, setError, record } = props
  const { nftpooldata } = useSelector((state) => (state.nftpool))
  const { allocationDetails } = useSelector((state) => (state.yield))
  useEffect(() => {
    let poolInfo = nftpooldata.find((val) => (val?.poolAddress?.toLowerCase() == record?.poolAddress?.toLowerCase()))
    setPoolInfo(poolInfo)
  }, [nftpooldata, record])
  useEffect(()=>{
    CalculateBonusApr()
  },[bonusPerc,poolInfo])
  useEffect(()=>{
    let bonusPerc = record?.boostMultiplier*100
    setbonusPerc(bonusPerc)
  },[record])
  const CalculateBonusApr = ()=>{
    try{
      if(!isEmpty(poolInfo) && !isEmpty(record)){
        let bonusPercentage = parseFloat(record.lockMultiplier)*100 + bonusPerc
        let BonusApr =  GetBounsApr({
          annualInterestRate:poolInfo.FramBaseApr,
          bonusPercentage:bonusPercentage
        })
        BonusApr = isNaN(BonusApr) ? 0: BonusApr
        setbonusApr(BonusApr)
        let totalApr = parseFloat(BonusApr)+parseFloat(poolInfo.FramBaseApr)+parseFloat(poolInfo.SwapFeeApr)
        settotalApr(totalApr)
      }
      
    }catch(err){
      console.log('CalculateBonusApr__err')
    }
  }
  // useEffect(() => {
  //   setMultipler()
  // }, [poolInfo, record])
  //onChange func
  const SetMax = () => {
    try {
      setAmount(xtokenbalance)
      IsApproveUsage(xtokenbalance)
      setExpectMultipler(xtokenbalance)
    } catch (err) {
      console.log(err, 'SetMax__err')
    }
  }

  const handleChange = (e) => {
    try {
      setErrors({})
      const { name, value } = e.target
      var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
      if (!numbers.test(value) && value !== "") {
        return false
      }
      if (name == 'amount') {
        setAmount(value)
        IsApproveUsage(value)
        setExpectMultipler(value)
      }
    } catch (err) {
      console.log(err, 'onChange__err')
    }
  }

  const approveUsage = async () => {
    try {
      let Data = {
        amount: amount
      }
      let isValidate = validateAmount(Data, xtokenbalance)
      if (!isEmpty(isValidate)) {
        let error = { ...errors }
        error['amount'] = isValidate.amount
        setErrors(error)
        return false
      }
      setisApprove(true)
      setbtnenable(true)
      const account = getWalletAddress()
      let Amount =(amount * 10 ** 18).toFixed(0)
      Amount = toFixedNumber(Amount)
      let result = await ApproveUsage(getYeildAddress(), Amount, account)
      if (result) {
        setisApprove(false)
        setbtnenable(false)
        setApprove(true)
      } else {
        setisApprove(false)
        setbtnenable(false)
        setApprove(false)
      }
    } catch (err) {
      setisApprove(false)
      setbtnenable(false)
      setApprove(false)
      console.log(err, 'approveUsage__err')
    }
  }

  const IsApproveUsage = async (value) => {
    try {
      const account = getWalletAddress()
      let Amount =(value * 10 ** 18).toFixed(0)
      Amount = toFixedNumber(Amount)
      let result = await CheckApproveUsage(getYeildAddress(), Amount, account)
      if (result) {
        setApprove(true)
      } else {
        setApprove(false)
      }
    } catch (err) {
      console.log(err, 'approveUsage__err')
    }
  }

  const Boost = async () => {
    try {
      let value = {
        amount: amount,
      }
      let validation = validateAllocation(value, xtokenbalance)
      if (!isEmpty(validation)) {
        setErrors(validation);
        return false
      }
      let Amount =(amount * 10 ** 18).toFixed(0)
      Amount = toFixedNumber(Amount)
      let loaderText = 'Boosting position.....'
      onSuccess(loaderText, 'Boost position')
      let result = await BoostPosition(record.poolAddress, record.tokenId, Amount)
      if (result) {
        let successLoaderText = `You've successfully allocated ${amount} RICE to your ${record.lpsymbol} position`
        SetsuccessLoader(successLoaderText, result.transactionHash)
      } else {
        let errorText = `Error ! When Boost to position`
        setError(errorText)
      }
    } catch (err) {
      console.log(err, 'Boost__err')
    }
  }

  const setMultipler = async () => {
    try {
      let poolAddress = record.poolAddress
      let maxBoostMultiplier = toFixedNumber(poolInfo.maxBoostMultiplier * 10000)
      let lpAmount = toFixedNumber(record.amount * 10 ** 18)
      let totalLpSupply = poolInfo.totalLpSupply.toString()
      let userAllocation = toFixedNumber(allocationDetails.UserTotalAllocation * 10 ** 18)
      let multiperVal = await GetBoostMultipler(poolAddress, maxBoostMultiplier.toString(), lpAmount.toString(), userAllocation.toString(), totalLpSupply.toString())
      console.log(multiperVal, 'multiperVal', poolInfo.lpSupply)
      setmultiper(multiperVal)
    } catch (err) {
      console.log(err, 'setMultipler__err')
    }
  }
  // const setExpectMultipler = async (amount) => {
  //   try {
  //     let poolAddress = record.poolAddress
  //     let maxBoostMultiplier = toFixedNumber(poolInfo.maxBoostMultiplier * 10000)
  //     let lpAmount = toFixedNumber(record.amount * 10 ** 18)
  //     let totalLpSupply = poolInfo.totalLpSupply.toString()
  //     let userAllocation = toFixedNumber(allocationDetails.UserTotalAllocation * 10 ** 18)
  //     let poolAllocation = await GetPoolTotalAllocation(poolAddress)
  //     userAllocation = userAllocation + toFixedNumber((amount)*10**18)
  //     poolAllocation =  parseFloat(poolAllocation) +toFixedNumber((amount)*10**18)
  //     console.log(poolAllocation, 'poolAllocation', poolInfo.lpSupply)
  //     let multiperVal = await GetExpectBoostMultipler(maxBoostMultiplier.toString(), lpAmount.toString(), userAllocation.toString(), totalLpSupply.toString(), poolAllocation.toString())
  //     console.log(multiperVal, 'setExpectMultipler', poolInfo.lpSupply)
  //     setExmultiper(multiperVal)
  //   } catch (err) {
  //     console.log(err, 'setMultipler__err')
  //   }
  // }

  const setExpectMultipler = async (amount) => {
    try {
      let lpAmount = toFixedNumber(record.amount * 10 ** 18)
      lpAmount = lpAmount.toString()
      let boostPoint = parseFloat(record?.boostPoints) + parseFloat(amount)
      boostPoint = toFixedNumber(parseFloat(boostPoint) * 10 ** 18)
      boostPoint = boostPoint.toString()
      console.log('setExpectMultipler', boostPoint, amount, record?.boostPoints)
      let boostmultiplier = await GetMultiplierByBoostPoints(record.poolAddress, lpAmount, boostPoint)
      setExmultiper(boostmultiplier)
      setbonusPerc(boostmultiplier * 100)
    } catch (err) {
      console.log(err, 'setExpectMultipler___err')
    }
  }

  const SetMaxBonus  = async()=>{
    try{
      let poolAllocation  =  await GetPoolTotalAllocation(record.poolAddress)
      let lpSupply = poolInfo.lpSupply.toString()
      let multiper = toFixedNumber(poolInfo.maxBoostMultiplier*10**4)
      let maxBoostMultiplier = toFixedNumber(poolInfo.maxBoostMultiplier*10**4)
      let lpAmount = toFixedNumber(record.amount *10**18)
      let totalAllocationFloor = await GettotalAllocationFloor()
      let MaxAllocation =  GetMaxBonus(multiper,lpSupply,maxBoostMultiplier,lpAmount,poolAllocation,totalAllocationFloor)
    }catch(err){
      console.log(err,'SetMaxBonus__err')
    }
  }

  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={boostmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Boost your position</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>
              <p className={`${cascading.innerheadwhite}`}>Amount
              </p>

              <div className={`${cascading.inputsec} my-3`}>
                <div className={`input-group mb-3`}>
                  <input
                    type="text"
                    value={amount}
                    name='amount'
                    onChange={(e) => { handleChange(e) }}
                    className={`form-control`}
                    placeholder="0"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <div className={`input-group-append`} >
                    <button className={` ${cascading.inputspan}`} id="basic-addon1" onClick={() => { SetMax() }}>
                      Max
                    </button>
                  </div>
                </div>
                <p className="text-danger f-12 d-block text-left">{errors?.amount}</p>
                <p className={`${cascading.balsec}`}>balance : {xtokenbalance} RICE</p>
              </div>

              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
              </p>


              <div className='row mt-3'>
                <div className='col-8 col-md-7'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Boost multiplier</p>
                </div>
                <div className='col-4 col-md-5'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>x{record.boostMultiplier} <FaArrowRight className='px-1' />x{Exmultiper}</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-7'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                </div>
                <div className='col-4 col-md-5'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(poolInfo.FramBaseApr,4)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-7'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Bonus APR</p>
                </div>
                <div className='col-4 col-md-5'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(bonusApr,6)}% 
                  {/* <FaArrowRight className='px-1' />0% */}
                  </p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-7'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Earned fees APR</p>
                </div>
                <div className='col-4 col-md-5'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(poolInfo.SwapFeeApr,4)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-7'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                </div>
                <div className='col-4 col-md-5'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(totalApr,4)}% 
                  {/* <FaArrowRight className='px-1' />20.09%</p> */}
                  </p>
                </div>
              </div>


              {isEmpty(amount) ?
                <Button className={`${cascading.btnstyle} mt-4`} disabled={true}>
                  Enter amount
                </Button>
                : approve ?
                  <Button className={`${cascading.btnstyle} mt-4`} onClick={() => { Boost() }}>
                    Boost
                  </Button> :
                  <Button className={`${cascading.btnstyle} mt-4`} disabled={btnenable} onClick={() => { approveUsage() }}>
                    {isApprove ? 'Approving...':'Approve'}
                  </Button>
              }
              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>
            </div>
            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}
          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

