

// import constant
import {
    NFTPOOL_DATA,
    NFTPOOL_TVL,
    NFTPOOL_DEPOSITS,
    NFTPOOL_REWARD,
    IS_LOAD_NFTPOOL
} from '../constants';

const initialValue = {
    nftpooldata: [],
    totalTvl: 0,
    pooldeposits: {},
    poolrewards: {},
    ispoolload: true
}

//  console.log("initialValue----->>>",initialValue);
const nftpoolReducer = (state = initialValue, action) => {
    switch (action.type) {
        case NFTPOOL_DATA:
            return {
                ...state,
                nftpooldata: action.payload
            };
        case NFTPOOL_TVL:
            return {
                ...state,
                totalTvl: action.payload
            };
        case NFTPOOL_DEPOSITS:
            return {
                ...state,
                pooldeposits: action.payload
            };
        case NFTPOOL_REWARD:
            return {
                ...state,
                poolrewards: action.payload
            };
        case IS_LOAD_NFTPOOL:
            return {
                ...state,
                ispoolload: action.payload
            };
        default:
            return state;
    }
}

export default nftpoolReducer;