import React, { useState, useEffect } from 'react';
import cascading from '../../assests/css/farms.module.css';
import Footer from '../../common/footer'
import Header from '../../common/header';
import { Link, useNavigate } from 'react-router-dom';
import { FaThList } from "react-icons/fa";
import { BsFillGrid3X2GapFill } from "react-icons/bs";
import Switch from "react-switch";
import { Dropdown } from 'react-bootstrap';
import bnblogo from '../../assests/images/bnb.png';
import coinlogo from '../../assests/images/coinicon.png';
import Poolscard from '../../components/poolscard';
import { useDispatch, useSelector } from 'react-redux';

//hooks

import { getActivePools, fetchPoolsStakingLimits, fetchPoolsPublicDataAsync, fetchPoolsUserDataAsync } from '../../hooks/usePools'

//lib
import { isEmpty } from "../../lib/isEmpty";
import { getWalletAddress } from '../../lib/localStorage';
export default function Pools() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [checked, setChecked] = useState(false);
    const [listtype, setListtype] = useState(true);
    const [status, setStatus] = useState(true);
    const [pools, setPools] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [InsearchResults, setInsearchResults] = useState([]);
    const [activePools, setActivePools] = useState([]);
    const [inActivePools, setInActivePools] = useState([]);
    const [userdata, setUserdata] = useState([]);
    const [stakedOnly, setStakedOnly] = useState(false)

    const { pooldata, pooluserData } = useSelector((state) => (state.pool))
    const { walletaddress } = useSelector((state) => (state.wallet))
    const [loading, setLoading] = useState(true)

    const [pooldetail, setpooldetail] = useState([{

        fromname: "DSP",
        apr: "0.00%",
        earned: "0",
        balance: "0",
        total: "0",
        max: "0",
        deposit: "1",
        status: "finish"


    },
    {

        fromname: "DSP",
        apr: "0.00%",
        earned: "0",
        balance: "0",
        total: "0",
        max: "0",
        deposit: "1",
        status: "live"


    },

    ])
    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };


    useEffect(() => {
        getPools()
    }, [walletaddress])

    const getPools = async () => {
        let pools = await fetchPoolsPublicDataAsync(dispatch)
        if (!isEmpty(pools) && pools != false) {
            setLoading(true)
            console.log("pools", pools)
            setPools(pools);
            const active = pools?.filter((pool) =>
                pool?.isFinished === false
            )
            console.log("active", active)
            const inactive = pools?.filter((pool) => pool.isFinished !== false)
            console.log("inactive", inactive)
            setInsearchResults(inactive)
            setActivePools(active);
            setSearchResults(active)
            setInActivePools(inactive);
            setLoading(false)
        }
        else if (pools == false) {
            setLoading(false)
        }

    }

    useEffect(() => {
        console.log("pooldata", pooldata, !isEmpty(pooldata), pooldata == false)
        if (!isEmpty(pooldata)) {
            const active = pooldata?.filter((pool) =>
                pool?.isFinished === false
            )
            console.log("active", active)
            const inactive = pooldata?.filter((pool) => pool.isFinished !== false)
            console.log("inactive", inactive)

            setActivePools(active);
            setSearchResults(active)
            setInActivePools(inactive);
            setInsearchResults(inactive)
        }
    }, [pooldata, pooluserData])



    // const getPools = async () => {
    //     let pools = await getActivePools()
    //     console.log("pools", pools)
    //     setPools(pools);
    //     const active = pools?.filter((pool) =>
    //         pool?.isFinished === false
    //     )
    //     console.log("active", active)
    //     const inactive = pools?.filter((pool) => pool.isFinished !== false)
    //     console.log("inactive", inactive)

    //     setActivePools(active);
    //     setInActivePools(inactive);
    // }


    useEffect(() => {
        userData()
    }, [walletaddress])

    const userData = async () => {
        if (getWalletAddress()) {
            let data = await fetchPoolsUserDataAsync(dispatch)
            console.log("userData", data)
            setUserdata(data)
        }
    }



    const stakedPools = (status) => {
        console.log('stakedPools')
        setChecked(status);
        setStakedOnly(status)
        console.log('stakedPools', status)
        if (status) {
            console.log('stakedPools', status)
            let FilterUser = userdata.length > 0 ? userdata?.map((val) => {
                if (val.stakedBalance > 0) {
                    return val.sousId
                }
            }) : []
            console.log(FilterUser, 'FilterPools')
            let FilterPools = activePools?.filter((val) => { return FilterUser?.includes(val?.sousId) })
            let FilterPools2 = inActivePools?.filter((val) => { return FilterUser?.includes(val?.sousId) })
            console.log(FilterPools, 'FilterPools')
            setInsearchResults(FilterPools2)
            setSearchResults(FilterPools)
        } else {
            setInsearchResults(inActivePools)
            setSearchResults(activePools)
        }

    }
    console.log(searchResults, 'searchResults')

    return (
        <>
            <Header />
            <div className={`${cascading.innerpages} ${cascading.earnpage} ${cascading.poolpage}`}>
                <div className={`container`}>
                    <div className={`${cascading.topcardsec}`}>
                        <p className={`${cascading.heading}`}>Pools</p>
                        <p className={`${cascading.desc}`}>We're excited to share that Wasabi integration is now live on wasabiswap.maticz.in! To get an estimate of the fees you could earn based on your chosen parameters, please visit our platform by clicking here. We look forward to assisting you with your earnings calculations.</p>

                    </div>
                    <div className={`row ${cascading.headrow}`}>
                        <p className={`${cascading.pagehead}`}>WasabiSwap Pools</p>
                        <p className={`${cascading.pagedesc}`}>Just stake tokens to earn. High APR, low risk</p>
                    </div>
                    <div className={`${cascading.extracardsec}`}>
                        <div className={`${cascading.cardstyle}`}>
                            <p className={`${cascading.label}`}>Learn about providing liquidity ↗</p>
                            <Link className={`${cascading.linkstyle}`} to="#">Check our liquidity</Link>

                        </div>
                        <div className={`${cascading.cardstyle}`}>
                            <p className={`${cascading.label}`}>Do you want to exchange? ↗</p>
                            <Link className={`${cascading.linkstyle}`} to="#">Click to swap</Link>

                        </div>
                    </div>

                    <div className={`row mt-5 justify-content-center ${cascading.filterrow}`}>
                        <div className={`col-lg-6 mb-3 mb-lg-0`}>
                            <div className={`justify-content-center ${cascading.leftfiltersec}`}>

                                <div className={`${cascading.togglesec}`}>

                                    <Switch
                                        // onChange={handleChange}
                                        checked={checked}
                                        className="react-switch"
                                        onHandleColor="#182322"
                                        onColor="#5AB437"
                                        offHandleColor="#182322"
                                        offColor="#fff"
                                        height={20}
                                        width={40}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        onChange={(e) => { stakedPools(e) }}
                                    />
                                    <span className={`${cascading.spanlabel}`}>Staked only</span>
                                </div>
                                <div className={`${cascading.tabsec}`}>
                                    <p onClick={() => setStatus(true)} className={status ? `${cascading.active}` : ''}>Live</p>
                                    <p onClick={() => setStatus(false)} className={!status ? `${cascading.active}` : ''}>Finished</p>

                                </div>


                            </div>

                        </div>

                    </div>

                    {!status ?
                        <>
                            <div className={`${cascading.poolscardsec} mt-4`}>
                                {isEmpty(InsearchResults) ?
                                    <p className={`${cascading.finshedlabel}`}>These pools are no longer distributing rewards. Please unstake your tokens.</p>
                                    :
                                    <>

                                        {InsearchResults && InsearchResults?.map((e, index) => (
                                            <Poolscard datas={e} userData={userdata[index]} status='inactive' />
                                        ))

                                        }
                                    </>}
                            </div>
                        </> 
                        :
                        <>
                            <div className={`${cascading.poolscardsec} mt-4`}>
                                {(stakedOnly && isEmpty(searchResults)) && (
                                    <p className={`${cascading.emptylabels}`}>Only Staked Pools will be displayed
                                    </p>

                                )
                                }
                                {loading ?
                                    <p className={`${cascading.emptylabels}`}>Loading...
                                    </p> : !stakedOnly && isEmpty(searchResults) && !loading ?

                                        <p className={`${cascading.emptylabels}`}>No Pools found!
                                        </p> :
                                        <>


                                            {searchResults && searchResults.length > 0 ? searchResults?.map((e, index) => (

                                                <Poolscard datas={e} userData={pooluserData[index]} status='active' />
                                            )) : ''}

                                        </>
                                }
                            </div>
                        </>
                    }
                    {/* <div className={`${cascading.poolscardsec} mt-4`}>
                        {status ?
                            <>
                                {pooldetail.filter(name => name.status == "live").map((e) => (
                                    <Poolscard datas={e} />
                                ))}
                            </>
                            :
                            <>
                                {pooldetail.filter(name => name.status == "finish").map((e) => (
                                    <Poolscard datas={e} />
                                ))

                                }
                            </>
                        }
                    </div> */}
                </div>
            </div>
            <Footer />
        </>
    )
}
