import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
// import { Redirect } from 'react-router'
import 'bootstrap/dist/css/bootstrap.css';
import Landingpage from './pages/Landingpage';
import store from "./store";
import { Provider } from "react-redux";
import HelperRoute from './helperRoutes';
import Dashboard from './pages/Dashboard';
import Yieldbooster from './pages/Yieldbooster';
import Roundtable from './pages/Roundtable';
import Tabledetail from './pages/tabledetail';
import Launchpad from './pages/Launchpad';
import Launchpaddetail from './pages/Launchpaddetail';
import Launchpadxgrail from "./pages/Launchpadxgrail";

import Swap from './pages/Trade/swap'
import Liquidity from './pages/Trade/liquidity';
import AddLiquidity from './pages/Trade/addliquidity';
import FindLiquidity from './pages/Trade/findliquidity';
import Farms from './pages/Earn/Farms';
import Pools from "./pages/Earn/Pools";
import RemoveLiquidity from './pages/Trade/removeliquidity';


import Xgrailpools from "./pages/xgrail/Xgrailpools";
import Xgrailpooldetail from "./pages/xgrail/Xgrailpooldetail";
import Createposition from "./pages/xgrail/createposition";
import Positions from "./pages/xgrail/positions";
import Yumremoveliquidity from "./pages/xgrail/yumremoveliquidity";
import Dividents from "./pages/xgrail/Dividents";

import Comingsoon from './pages/Comingsoon';
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter >
        <div><Toaster /></div>

        <HelperRoute />

        <Routes>
          {/* <Route exact path="/">
  <Redirect exact
        from="/"
     to="/" /> 
</Route> */}
          <Route exact path="/" element={<Landingpage />} />

          <Route exact path="/rice" element={<Dashboard />} />
          <Route exact path="/yieldbooster" element={<Yieldbooster />} />
          {/* <Route exact path="/bakery" element={<Roundtable />}/> */}
          {/* <Route exact path="/bakerydetail" element={<Tabledetail />}/> */}
          <Route exact path="/launchpad" element={<Launchpad />} />
          <Route exact path="/launchpaddetail" element={<Launchpaddetail />} />
          <Route exact path="/launchpaddetail/:saleAddress" element={<Launchpaddetail />} />
          <Route exact path="/launchpaddetail/:saleAddress/:walletAddress" element={<Launchpaddetail />} />
          <Route exact path="/launchpadrice" element={<Launchpadxgrail />} />
          <Route exact path="/swap" element={<Swap />} />
          <Route exact path="/liquidity" element={<Liquidity />} />
          <Route exact path="/add" element={<AddLiquidity />} />
          <Route exact path="/remove" element={<RemoveLiquidity />} />
          <Route exact path="/find" element={<FindLiquidity />} />
          <Route exact path="/farms" element={<Farms />} />
          <Route exact path="/pools" element={<Pools />} />
          <Route exact path="/ricepools" element={<Xgrailpools />} />
          <Route exact path="/ricepooldetail/:poolAddress" element={<Xgrailpooldetail />} />

          <Route exact path="/createposition" element={<Createposition />} />
          <Route exact path="/positions" element={<Positions />} />

          <Route exact path="/riceremoveliquidity" element={<Yumremoveliquidity />} />
          <Route exact path="/dividends" element={<Dividents />} />

          <Route exact path="/comingsoon" element={<Comingsoon />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
