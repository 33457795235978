const BASE_URL = "/user";


export const addReferrer = BASE_URL + "/addReferrer"

export const userinvested = BASE_URL + '/userinvested';

export const createuserApi = BASE_URL + '/createuser'

export const saveTransaction = BASE_URL + '/saveTransaction'

export const getTransaction = BASE_URL + '/getTransaction'