import React, { useState } from 'react'
import cascading from '../assests/css/claimcardstyle.module.css';
import { BiRightArrowAlt } from "react-icons/bi";
import { FaCopy } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';

import Chart from 'react-apexcharts';


import netimg from '../assests/images/roundtable.png'

//modals
import ComingSoonmodal from '../modals/comingSoonmodal';
import { useEffect } from 'react';

//action
import { getTransactionData } from '../Actions/LaunchActions'

//lib
import { toFixedNumber } from '../lib/FixedNumber';
import { roundToSignificant } from '../hooks/useCommon';
import { isEmpty } from '../lib/isEmpty';

//constants
import {CHART_DATA} from '../constants'

export default function Chartcard() {

  const [modalcoming, setmodalcoming] = useState(false);
  const [transaction, setTransaction] = useState([])
  const [data, setData] = useState([])
  const [months, setMonths] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']);
  const [price, setPrice] = useState([])

  const { LaunchData } = useSelector((state) => state.launch)
  // const  {saleInfo, userData,UseTokenInfo,AffliateInfo,getChildrendata }=LaunchData
  console.log("LaunchData_chart", LaunchData)

  let dispatch = useDispatch()


  const options = {
    chart: {
      id: 'area-chart',
      zoom: {
        enabled: false
      }
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      axisBorder: {
        color: '#fff', // Change Y-axis color
      },
      labels: {
        style: {
          colors: '#fff', // Change X-axis label color
        },
      }
    },
    yaxis: {
      axisBorder: {
        color: '#fff', // Change Y-axis color
      },
      labels: {
        style: {
          colors: '#fff', // Change Y-axis label color
        },
      },
    },
    toolbar: {
      show: false, // Hide tool icons
      download: false,
    },
    colors: ['#5ab437'], // Change area color

  }

  const series = [{
    name: 'Series 1',
    data: price,
  }];

  useEffect(() => {
    gettransaction()
  }, [])

  const gettransaction = async () => {
    try {
      let price = []
      const trans = await getTransactionData({ saleaddress: window.location.pathname.split('/')[2] })
      console.log("trans_transtrans",trans.data.type, trans.data.result)
      if(trans.data.type == 'success'){
      setTransaction(trans.data.result)
      }
      // setData(trans.data.data)
      months.map((val, ind) => {
        console.log("gettransaction_price", trans?.data.result?.some((dat) => dat._id == ind + 1))
        if (trans?.data.result?.some((dat) => dat._id == ind + 1)) {
          console.log("gettransaction_price", trans?.data.result?.some((dat) => dat._id == ind + 1))
          trans?.data.result.map((value, index) => {
            console.log("gettransaction_price", value, value._id == ind + 1)
            if (value._id == ind + 1) {
              console.log("gettransaction_price", value, roundToSignificant(value.Price, 45))
              let convertPrice = (value.Price).toFixed(45)
              price.push(convertPrice)
              dispatch({
                type: CHART_DATA,
                payload: price
            })
            }
          })
        }
        else {
          price.push(0)
        }
      })
      // trans.data.result.map((val, ind) => {
      //   console.log("Price",val,roundToSignificant(val.Price,45))
      //   let convertPrice =(val.Price).toFixed(45)
      //   price.push(convertPrice)
      //    setPrice(price)
      // })
      setPrice(price)
    }
    catch (err) {
      console.log("gettransaction_err", err)
    }

  }



  return (
    <>
      <div className={`${cascading.claimcard} h-100`}>
        {transaction &&
          <div className={`${cascading.toprow} mb-4`}>
            <div>
              <p className={`${cascading.cardname}`}>{`$  ${LaunchData?.saleInfo?.name == undefined ? "" : LaunchData?.saleInfo?.name} Sale Price (USD)`}</p>
            </div>
          </div>
        }

        {console.log("transaction_length",transaction, transaction.length, transaction.length > 0,isEmpty(transaction),transaction === "")}

        {transaction?.length > 0 ?
          <div className="area-chart">

            <Chart options={options} series={series} type="area" height={300} />
          </div> :
          isEmpty(transaction) ?
           <div className={`${cascading.areachartcenter}`}>No data found</div> : ""}

      </div>

      {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}

    </>
  )
}
