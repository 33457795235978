
export const WALLET_ADDRESS = 'WALLET_ADDRESS';
export const WALLET_DATA = 'WALLET_DATA';
export const WALLET_STATUS = 'WALLET_STATUS';
export const NATIVE_BALANCE   = 'NATIVE_BALANCE'
export const XTOKEN_BALANCE   = 'XTOKEN_BALANCE'

export const SLIPPAGE = 'SLIPPAGE'
export const DEADLINE = 'DEADLINE'
export const MULTI_HOPS = 'MULTI_HOPS'
export const EXPERT_MODE = 'EXPERT_MODE'

export const POOL_DATA = 'POOL_DATA'
export const POOL_USER_DATA = 'POOL_USER_DATA'

export const GAS_PRICE = 'GAS_PRICE'

export const NETWORK_SWITCH = 'NETWORK_SWITCH'

export const FARMS_DATA = 'FARMS_DATA'

export const NFTPOOL_DATA = 'NFTPOOL_DATA'
export const NFTPOOL_TVL = 'NFTPOOL_TVL'
export const NFTPOOL_DEPOSITS = 'NFTPOOL_DEPOSITS'
export const NFTPOOL_REWARD = 'NFTPOOL_REWARD'
export const IS_LOAD_NFTPOOL = 'IS_LOAD_NFTPOOL'

export const LAUNCH_DATA = 'LAUNCH_DATA'
export const LAUNCH_ALLOCATION_DETAILS  = 'LAUNCH_ALLOCATION_DETAILS'

export const CHART_DATA = 'CHART_DATA'

export const POSITION_LIST = 'POSITION_LIST'
export const POSITION_RECORD = 'POSITION_RECORD'
export const TOTAL_HOLDING = 'TOTAL_HOLDING'
export const SPNFT_Reward = 'SPNFT_Reward'
export const  IS_LOAD_POSITION = 'IS_LOAD_POSITION'
export const  IS_POSITION = 'IS_POSITION'

export const YEILD_ALLOCATION_DETAILS = 'YEILD_ALLOCATION_DETAILS'
export const ALLOCATE_DETAILS = 'ALLOCATE_DETAILS'

export const DIVIDENDS_DETAILS  = 'DIVIDENDS_DETAILS'
export const DISTRIBUTE_INFO  = 'DISTRIBUTE_INFO'
export const DIVI_USER_INFO  = 'DIVI_USER_INFO'
export const REDEEM_INFO  = 'REDEEM_INFO'
export const REDEEM_ALLOCATION  = 'REDEEM_ALLOCATION'
export const IS_LOAD_DIVIDENDS = 'IS_LOAD_DIVIDENDS'

export const USER_TOKEN  = 'USER_TOKEN'


