import { getAddress } from "../hooks/useTokens"

//local
// export const POOLS = [
//   {
//     sousId: 1,
//     stakingToken: '0x2F30560d17044230eF4c6cEb9768D860e2cFf36E',
//     earningToken: '0x2F30560d17044230eF4c6cEb9768D860e2cFf36E',
//     // poolCategory: PoolCategory.CORE,
//     contractAddress: {
//         97: '0xAb56d6585A4dB40e9779FFA8fc9DE6b16e0129d0',//'0xa373B9f29b40500dF7F538Cb9d0ae73376986533',//'0xeAa81cc2174e73b4C4311f4c7182dC746D1B1627',//0x64C2E898410e6F0dD58eF934072770e7D9536192
//         56: '',
//       },
//     harvest: true,
//     tokenPerBlock: '0.0001',
//     sortOrder: 0,
//     isFinished: false,
//   }

// ]

//demo
export const POOLS = [
  {
    sousId: 1,
    StakingToken: {

      //bsc
      56: '', //M
      97: '0xAbF661F0c0E1723CA7fA7f29E3e53FF53Ceaa824', //T

      //Eth
      1: '', //M
      11155111: '', //T

      //Avax
      43114: '', //M
      43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

      //Arbitrum
      42161: '', //M
      421614: '', //T

      //zkSync
      324: '', //M
      280: '', //T

    },

    EarningToken: {

      //bsc
      56: '', //M
      97: '0xAbF661F0c0E1723CA7fA7f29E3e53FF53Ceaa824', //T

      //Eth
      1: '', //M
      11155111: '', //T

      //Avax
      43114: '', //M
      43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

      //Arbitrum
      42161: '', //M
      421614: '', //T

      //zkSync
      324: '', //M
      280: '', //T

    },
    // poolCategory: PoolCategory.CORE,
    contractAddress: {

      //bsc
      56: '', //M
      97: '0x4aB5f1f6d43b68bb14b8755e420f7fABCC156D69', //T

      //Eth
      1: '', //M
      11155111: '', //T

      //Avax
      43114: '', //M
      43113: '0x740f9a02544Cf6bdFdB3454C904A4eeEa32042Cd', //T

      //Arbitrum
      42161: '', //M
      421614: '', //T

      //zkSync
      324: '', //M
      280: '', //T

    },
    harvest: true,
    tokenPerBlock: '0.001',
    sortOrder: 0,
    isFinished: false,
  }

]


export const getPoolChain = () => {
  try {
    let pools = JSON.stringify(POOLS)
    pools = JSON.parse(pools)
    for (let i = 0; i < pools.length; i++) {
      pools[i]['earningToken'] = getAddress(pools[i].EarningToken)
      pools[i]['stakingToken'] = getAddress(pools[i].StakingToken)
      if (pools.length - 1 == i) {
        console.log(pools, 'getFarmsChain')
        return pools
      }
    }
  } catch (err) {
    console.log(err, "getPoolChain__err")
  }
}