import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/claimcardstyle.module.css';
import { BiRightArrowAlt } from "react-icons/bi";
import { FaCopy } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';


import netimg from '../assests/images/roundtable.png'

//modals
import ComingSoonmodal from '../modals/comingSoonmodal';

//hooks
import { calculateBuy, BuyToken, approveContractbuy,claimReferralReward, getclaimReferral } from '../hooks/useBuy';
import { getProxyOwner, isSaleLive, isUpcoming, UseTokenInfo,getAffliateInfo } from '../hooks/useContract'
import { GetCurrencyBalance, TokenBalance } from '../hooks/useTokens'
import { getChainId } from "../hooks/useWeb3";

//lib
import { getWalletAddress } from '../lib/localStorage';
import { isEmpty } from '../lib/isEmpty';

//config
import { frontendurl, CHAINS } from '../config/env';

export default function Stagecard({ iswhitelist, refreshData }) {

    // console.log(iswhitelist, saleInfo, "props whi")
    const { walletaddress } = useSelector((state) => state.wallet)

    const [modalcoming, setmodalcoming] = useState(false);
    const [max, setMax] = useState(0)
    const [isMax, setIsMax] = useState(0)
    const [claim, setClaim] = useState(0)
    const [amount, setAmount] = useState(0)
    const [saleData, setSaleData] = useState({})
    const [userData, setUserData] = useState({})
    // const [AffliateInfo, setAffliateInfo] = useState({})
    const [buyTokenDecimal, setBuyTokenDecimal] = useState(18)
    const [tokeninfo, setTokenInfo] = useState({})
    const [balance, setBalance] = useState(0)
    const [symbol, setSymbol] = useState('')
    const [approvebutton, setApprovebutton] = useState(false)
    const [buyToken, setBuyToken] = useState('')
    const [refReward, setRefReward] = useState(0)
    const [loading, setLoading] = useState(false)

    
    // const  {saleInfo, userData,UseTokenInfo,AffliateInfo,getChildrendata } = useSelector((state)=>state.launch)
    // console.log("LaunchData",saleInfo, userData,UseTokenInfo,AffliateInfo,getChildrendata )


    const { LaunchData } = useSelector((state) => state.launch)
    // const  {saleInfo, userData,UseTokenInfo,AffliateInfo,getChildrendata }=LaunchData
    console.log("LaunchData", LaunchData)

    const { saleAddress } = useParams()
    console.log("LaunchpadDetail_state", saleAddress)

    useEffect(() => {
        console.log('Balancecard_useEffect')
        if (LaunchData.saleInfo) {
            console.log('Balancecard_useEffect')
            console.log(LaunchData.saleInfo, 'Balancecard_useEffect')
            setSaleData(LaunchData.saleInfo)
            setUserData(LaunchData.userData)
            GetBuytokeninfo(LaunchData.saleInfo?.useWithToken)
        }
        console.log("setSaleData_setSaleData_setSaleData", saleData, LaunchData.saleInfo,LaunchData.AffliateInfo)

    }, [LaunchData.saleInfo])

    console.log("Balancecard", LaunchData.saleInfo, saleData, buyToken)


    useEffect(() => {
        CurrecyBal()
    }, [balance, buyToken,walletaddress])

    const CurrecyBal = async () => {
        let balance
        if (saleData.useWithToken === "0x0000000000000000000000000000000000000000") {
            balance = await GetCurrencyBalance()
            console.log("balance", balance)
        }
        else {
            balance = await TokenBalance(saleData.useWithToken)
            console.log("TokenBalance", balance)

        }
        setBalance(balance)
        setSymbol(buyToken)
    }

    const maxButton = async () => {
        console.log("setMax", balance, buyTokenDecimal, (balance / 10 ** buyTokenDecimal).toFixed(15))
        // setMax(saleData && (parseInt(balance) / 10 ** buyTokenDecimal))
        setMax(balance)
        setIsMax(true)
        // setAmount(saleData && (parseInt(balance) / 10 ** buyTokenDecimal))
        setAmount(balance)
    }



    const GetBuytokeninfo = async (value) => {
        try {
            if (value === "0x0000000000000000000000000000000000000000") {
                console.log("CHAINS[getChainId()].SYMBOL", CHAINS[getChainId()].SYMBOL)
                setBuyToken(CHAINS[getChainId()].SYMBOL);
                setBuyTokenDecimal(18)
            } else {
                const token = await UseTokenInfo(LaunchData.saleInfo && LaunchData.saleInfo?.useWithToken);
                setBuyToken(token?.symbol);
                setBuyTokenDecimal(Number(token?.decimals))
            }
        }
        catch (err) {
            console.log("err", err)
        }
    }



    // const calculateToken = async (value) => {
    //     console.log("maxEthLimit", saleData,saleData.saleAddress, value)
    //     const tokenValue = await calculateBuy(saleData.saleAddress, value);
    //     console.log("tokenValue", tokenValue)
    //     setClaim(tokenValue)
    //     setIsMax(false)
    // }


    const buy = async () => {
        setLoading(true)
        const owner = await getProxyOwner();
        const afflink = window.location.pathname.split('/')[3];
        console.log("afflink>>>>>", afflink);
        var AffLink = (afflink?.length != 42 || afflink == "" || afflink == undefined) ? owner : afflink;
        console.log("AffLink", AffLink);
        var result = await BuyToken(saleData.saleAddress, amount, isMax, max, getWalletAddress(), buyTokenDecimal, AffLink, saleData.tokenAddress, parseFloat(LaunchData.AffliateInfo[0]) / 10 ** 18, tokeninfo?.symbol)
        console.log("result", result)
        if(result == false){
            setLoading(false)
        }
        else{
            setAmount("")
            setLoading(false)
            await refreshData()
        }
        
    }


    const approvetoken = async () => {
        await approveContractbuy(sessionStorage.getItem("accountInfo"), saleData.useWithToken, saleData.saleAddress);
        setApprovebutton(true)
    }

    const copyCode = async (a, b) => {
        toast.success("Affliate link Copied")
        //  {
        //   position: position.position,
        //   style: style,
        //   iconTheme: iconTheme,
        // }

    }

    const claimReward=async()=>{
        let result = await claimReferralReward(saleAddress)
        console.log("claimReward",result)
    
    }
 
    useEffect(()=>{
        getReward()
    },[refReward])

    const getReward = async()=>{
       let reward = await getclaimReferral(saleAddress)
       console.log("getReward_reward",reward)
       setRefReward(reward)
    }


    return (
        <>
            <div className={`${cascading.claimcard} h-100`}>
                <div className={`${cascading.toprow} mb-4`}>
                    <div>
                        <p className={`${cascading.cardname}`}>Buy</p>
                    </div>


                    <CopyToClipboard
                        text={`${frontendurl}/launchpaddetail/${saleData.saleAddress}/${sessionStorage.getItem("accountInfo")}`}
                        onCopy={() =>
                            copyCode('invite link',
                                `${frontendurl}/launchpaddetail/${saleData.saleAddress}/${sessionStorage.getItem("accountInfo")}`)}
                    // onCopy={() =>
                    //     toast('Link copied', {
                    //         duration: 2000,
                    //         position: 'top-right',

                    //         // Styling
                    //         style: { background: "#1c2029", color: "#fff", minWidth: 200 },
                    //         className: '',

                    //         // Custom Icon

                    //         // Change colors of success/error/loading icon
                    //         iconTheme: {
                    //             primary: '#000',
                    //             secondary: '#fff',
                    //         },

                    //         // Aria
                    //         ariaProps: {
                    //             role: 'status',
                    //             'aria-live': 'polite',
                    //         },
                    //     })
                    // }

                    >

                        <button className={`btn ${cascading.btnstyle} w-50`}>
                            <FaCopy className='me-2' />Referral Link</button>

                    </CopyToClipboard>




                </div>
                <div className={`${cascading.rightsec}`}>
                    <div className={`${cascading.inputsec} `}>
                        <div className={`input-group`}>
                            <input type="text" className={`form-control`} placeholder="0" aria-label="Username" aria-describedby="basic-addon1"
                                value={amount}
                                onChange={(e) => {
                                    var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
                                    if (!numbers.test(e.target.value) && e.target.value !== "") {
                                        return false
                                    }
                                    setAmount(e.target.value);
                                    // calculateToken(e.target.value);
                                }}
                            />
                            <div className={`input-group-append`} >
                                <button className={` ${cascading.inputspan}`} id="basic-addon1" onClick={() => { maxButton() }} > Max</button>
                            </div>

                            {/* {saleData.buytype ?

                                <div className={`input-group-append`} >
                                    {saleData && isSaleLive(saleData.startTime, saleData.endTime, saleData.isPresaleOpen) || isUpcoming(saleData.startTime, saleData.endTime) ?
                                        <button className={` ${cascading.inputspan}`} id="basic-addon1" onClick={() => { buy() }}
                                            disabled={saleData && saleData.isWhitelisted ? userData && !(userData.userWhitelistedAmount > 0) : false}>
                                            {saleData && saleData.isWhitelisted ? (userData && !(userData.userWhitelistedAmount > 0) ? "Not Whitelisted" : "Buy") : "Buy"} with {symbol}</button> :
                                        ((!isSaleLive(saleData.startTime, saleData.endTime) ?
                                            <button className={` ${cascading.inputspan}`} id="basic-addon1" disabled={true}> Sale InActive</button> :
                                            <button className={` ${cascading.inputspan}`} id="basic-addon1" disabled={true}> Sale InActive</button>))
                                    }
                                </div>
                                :
                                <div className={`input-group-append`} >
                                    {!approvebutton ?
                                        <button className={` ${cascading.inputspan}`} id="basic-addon1" onClick={() => { approvetoken() }}>
                                            Approve
                                        </button>
                                        :
                                        saleData && isSaleLive(saleData.startTime, saleData.endTime, saleData.isPresaleOpen) || isUpcoming(saleData.startTime, saleData.endTime) ?
                                            <button onClick={buy()} disabled={saleData && saleData.isWhitelisted ? userData && !(userData.userWhitelistedAmount > 0) : false} className={` ${cascading.inputspan}`} id="basic-addon1">
                                                {saleData && saleData.isWhitelisted ? (userData && !(userData.userWhitelistedAmount > 0) ? "Not Whitelisted" : "Buy") : "Buy"} with {symbol} </button> :
                                            ((!isSaleLive(saleData.startTime, saleData.endTime) ?
                                                <button disabled={true} className={` ${cascading.inputspan}`} id="basic-addon1"> Sale InActive </button> :
                                                <button onClick={buy()} className={` ${cascading.inputspan}`} id="basic-addon1" > Buy </button>))
                                    }

                                </div>

                            } */}
                        </div>
                    </div>
                    <p className={`${cascading.balsec} mt-2 mb-3`}>balance : {isEmpty(balance) ? 0 : parseFloat(balance).toFixed(8)} {symbol}</p>

                    <div className={`${cascading.listsec}`}>
                        <div className={`${cascading.listrow}`}>
                            <p>Spent</p>
                            <p>{isNaN(userData?.userInvested/10**buyTokenDecimal) ? 0 : (userData?.userInvested/10**buyTokenDecimal).toFixed(5)} {symbol}</p>
                        </div>
                        {/* <div className={`${cascading.listrow}`}>
                            <p>Wallet cap</p>
                            <p>63 ETH</p>
                        </div> */}

                        <div className={`${cascading.listrow}`}>
                            <p>Your referral earnings</p>
                            <p>{refReward && refReward/10**18}  {symbol}</p>
                        </div>

                        {/* <div className={`${cascading.listrow}`}>
                            <p>Pending referral earnings</p>
                            <p>0 WSBI</p>
                        </div> */}
                    </div>

                    {console.log("saleData_buytype", saleData.buytype,saleData && saleData.isWhitelisted ? LaunchData.userData && !(LaunchData.userData.userWhitelistedAmount > 0) : false || amount <= 0 || saleData.owner?.toLowerCase() == getWalletAddress()?.toLowerCase(),saleData.owner?.toLowerCase() == getWalletAddress()?.toLowerCase(),saleData.owner,getWalletAddress())}

                    {saleData.buytype ?
                        <>
                            {saleData && isSaleLive(saleData.startTime, saleData.endTime, saleData.isPresaleOpen) || isUpcoming(saleData.startTime, saleData.endTime) ?
                                <button className={`btn ${cascading.connect_btn_theme} w-100 my-3`} onClick={() => { buy() }}
                                    disabled={saleData && amount <= 0 ? true: false || saleData.owner == getWalletAddress() ? true : false}>
                                    {saleData && ( loading ? `Buying with ${symbol} ...` : `Buy with ${symbol}`) } </button> :
                                ((!isSaleLive(saleData.startTime, saleData.endTime) ?
                                    <button className={`btn ${cascading.connect_btn_theme} w-100 my-3`} disabled={true}> Sale InActive</button> :
                                    <button className={`btn ${cascading.connect_btn_theme} w-100 my-3`} disabled={true}> Sale InActive</button>))
                            }
                        </>
                        :
                        <>
                            {!approvebutton ?
                                <button className={`btn ${cascading.connect_btn_theme} w-100 my-3`} onClick={() => { approvetoken() }}>
                                    Approve
                                </button>
                                :
                                saleData && isSaleLive(saleData.startTime, saleData.endTime, saleData.isPresaleOpen) || isUpcoming(saleData.startTime, saleData.endTime) ?
                                    <button onClick={buy()} disabled={saleData && amount <= 0 ? true: false || saleData.owner == getWalletAddress() ? true : false} className={`btn ${cascading.connect_btn_theme} w-100 my-3`} >
                                        {saleData && ( loading ? `Buying with ${symbol} ...` : `Buy with ${symbol}`)} </button> :
                                    ((!isSaleLive(saleData.startTime, saleData.endTime) ?
                                        <button disabled={true} className={`btn ${cascading.connect_btn_theme} w-100 my-3`} > Sale InActive </button> :
                                        <button onClick={buy()} className={`btn ${cascading.connect_btn_theme} w-100 my-3`} > Buy </button>))
                            }

                        </>

                    }


                    {/* <button
                        className={`btn ${cascading.connect_btn_theme} w-100 my-3`}

                    >
                        {!props.iswhitelist ? "Not Whitelisted" : "Buy"}
                    </button> */}


                    <button className={`btn ${cascading.connect_btn_grey} w-100 mb-3`} onClick={()=>{claimReward()}} disabled={refReward && refReward<=0}>Claim Referral Earnings</button>

                </div>


            </div>

            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}


        </>
    )
}
