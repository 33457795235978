import axios from "axios";
import { API_URL } from "../config/env";
import { createuserApi } from "../Routes/UserRoutes";


export const createuserhook = async (data) => {
    console.log("login data", data);
     
      try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + createuserApi,
          data
        });
        console.log("response", respdata);
        return {
          data: respdata.data
        }
      }
      catch (err) {
        console.log("createuserhook__error", err)
        return {
          error: err
        }
      }
  }