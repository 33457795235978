import contractAddress from '../config/constant/contract'
import { CURRENT_CHAIN_ID } from './useWeb3'
import { getWalletAddress } from "../lib/localStorage";
import { EstGas } from "./useCommon";
import factory_ABI  from "../config/abi/factory.json";
import {consolelog} from "../lib/consolelog"
import { GetChainIndex, useWeb3 } from "./useWeb3"
import { ZEROTH_ADDRESS } from '../config/env';

export const GetFactory = async () => {
    const web3 = await useWeb3();
    try {
            console.log("getFactory")
            const contract = new web3.eth.Contract(factory_ABI, web3.utils.toChecksumAddress(getFactoryAddress()));
            return contract;
    } catch (err) {
        console.log(err, "getFactory__err")
    }
}

export const getFactoryAddress = () => {
    try {
        let CHAIN_ID = CURRENT_CHAIN_ID()
        let factoryaddress = contractAddress.factory[`${CHAIN_ID}`]
        return factoryaddress
    } catch (err) {

    }
}

export const GetPairInfo = async (TokenA,TokenB) => {
           const web3 = await useWeb3();
    try {
            console.log(TokenA,TokenB,"GetPairInfo")
            const contract = await GetFactory()
            const account = getWalletAddress()

            const data = await contract.methods.getPair(TokenA,TokenB).call({ from: account})
            console.log("data in initiate getPair", data);
            return data
    } catch (err) {
        console.log(err, "getPairInfo__err")
    }
}


export const GetallPairsLength = async () => {
    try {
        // const web3 = await useWeb3();
        console.log('GetallPairsLength')
        const contract = await GetFactory()

        // const account = getWalletAddress()
        // let params = [web3.utils.toChecksumAddress()]
        // const {gasLimit,gasPrice}  = await  EstGas(params,router_ABI,contract,'removeLiquidityETH',account)
        console.log(contract,'GetallPairsLength')
        const data = await contract?.methods?.allPairsLength().call()
        console.log("GetallPairsLength234", data);
        return data

    }
    catch (err) {
        consolelog("getallPairsLength__err", err, true)
        return false
    }
}


export const GetLiquidityETH = async (pool) => {
    try {
        const web3 = await useWeb3();
        const contract = await GetFactory()

        const account = getWalletAddress()
        // let params = [pool]
        // const {gasLimit,gasPrice}  = await  EstGas(params,factory_ABI,contract,'allPairs',account)

        const data = await contract.methods.allPairs(pool).call()
        console.log("GetLiquidityETH", data);
        return data

    }
    catch (err) {
        consolelog("removeLiquidityETH__err", err, true)
        return false
    }
}

export const  GetPairaddress=async(tokenA, tokenB) =>{
    try{
        const web3 = await useWeb3()
        const contract = await GetFactory()
        let _hexadem = await contract.methods.INIT_CODE_PAIR_HASH().call()
        console.log(_hexadem,'_hexadem')
        let _factory = getFactoryAddress()
        console.log(_factory,'_factory')
        let [token0, token1] = tokenA < tokenB ? [tokenA, tokenB] : [tokenB, tokenA];
    
        let abiEncoded1 =  web3.eth.abi.encodeParameters(['address', 'address'], [token0, token1]);
        abiEncoded1 = abiEncoded1.split("0".repeat(24)).join("");
        let salt = web3.utils.soliditySha3(abiEncoded1);
        let abiEncoded2 =  web3.eth.abi.encodeParameters(['address', 'bytes32'], [_factory, salt]);
        abiEncoded2 = abiEncoded2.split("0".repeat(24)).join("").substr(2);
        let pair = '0x' + web3.utils.soliditySha3( '0xff' + abiEncoded2, _hexadem ).substr(26);
        console.log( 'PAIR: ',pair  )
        return pair
    }catch(err){
        console.log('getPair__err',err)
        return ZEROTH_ADDRESS
    }
}

