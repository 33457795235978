import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/confirmremovestyle.module.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import infoimg from '../assests/images/infoicon.png'
import coinimg from '../assests/images/infoicon.png'
import { FaAngleDown } from "react-icons/fa";


import fileObjectUrl from '../lib/img'
import { isEmpty } from '../lib/isEmpty';



import { IoMdClose } from "react-icons/io";
import { FaDownLong } from 'react-icons/fa6';
import { FaPlus } from 'react-icons/fa6';


export default function PositionTokenmodal(props) {



  const [tokenA, setTokenA] = useState({})

  const { selectToken, poolAddress } = props

  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Create Position</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div className='d-flex align-items-center justify-content-center mb-3'>
            {isEmpty(selectToken) ?
              <button className={`btn ${cascading.currencybtn}`} onClick={() => props.onShowtoken()}>
                <img src={coinimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} />Select Token <FaAngleDown fill='#fff' />
              </button> :
              ''}

          </div>
          <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={props.onShowcreate}>Confirm</button>
          <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>
        </Modal.Body>

      </Modal>
    </div>
  )
}
