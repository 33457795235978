// import constant
import {
    NATIVE_BALANCE,
} from '../constants';

const initialValue = {
    nativebalance : 0,
}

//  console.log("initialValue----->>>",initialValue);
const nativeReducer = (state = initialValue, action) => {
    if(action.type == 'NATIVE_BALANCE'){
        console.log(action,'nativeReducer')
    }
   
    switch (action.type) {
        case NATIVE_BALANCE:
            return{
                ...state,
                nativebalance: action.payload
            };
        default:
            return state;
    }
}

export default nativeReducer;