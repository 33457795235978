// import constant
import {
    LAUNCH_DATA,
    LAUNCH_ALLOCATION_DETAILS
} from '../constants';

const initialValue = {
    LaunchData: {},
    launchallocations: {}
}

//  console.log("initialValue----->>>",initialValue);
const launchReducer = (state = initialValue, action) => {

    switch (action.type) {
        case LAUNCH_DATA:
            return {
                ...state,
                LaunchData: action.payload
            };
        case LAUNCH_ALLOCATION_DETAILS:
            return {
                ...state,
                launchallocations: action.payload
            };
        default:
            return state;
    }
}

export default launchReducer;