import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/recenthistorystyle.module.css';
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

import metaicon from '../assests/images/metamask.png';
import walleticon from '../assests/images/walletconnect.png';
import { useDispatch, useSelector } from 'react-redux';
import { walletControl } from '../Redux/action';

import Walletmodal from './walletmodal';

import { getWalletAddress } from '../lib/localStorage';
import { isEmpty } from "../lib/isEmpty"

import { GetChainIndex } from '../hooks/useWeb3'

import { CHAINS } from '../config/env'
export default function Recenthistory(props) {
  const userdetail = useSelector((state) => state.admin)
  const [addLiquidity, setAddLiquidity] = useState("")
  const [removeLiquidity, setRemoveLiquidity] = useState("")
  const [swap, setSwap] = useState("")
  const [approve, setApprove] = useState("")
  const [walletmodalshow, setWalletmodalshow] = useState(false)



  useEffect(() => {
    transaction()
  }, [])

  const transaction = async () => {
    if (localStorage.getItem('addLiquidity')) {
      setAddLiquidity(localStorage.getItem('addLiquidity'))
    }
    if (localStorage.getItem('removeLiquidity')) {
      setRemoveLiquidity(localStorage.getItem('removeLiquidity'))
    }
    if (localStorage.getItem('swap')) {
      setSwap(localStorage.getItem('swap'))
    }
    if (localStorage.getItem('approve')) {
      setApprove(localStorage.getItem('approve'))
    }
  }

  const clearAll = async () => {
    localStorage.removeItem("addLiquidity")
    localStorage.removeItem("removeLiquidity")
    localStorage.removeItem('swap')
    localStorage.removeItem('approve');
    setAddLiquidity("")
    setRemoveLiquidity("")
    setSwap("")
    setApprove("")
  }
  return (
    <div className={`${cascading.walletmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`}>
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Recent Transactions</h4>

          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />
          {/* <button className={`${cascading.closebtn}`} onClick={ props.onHide}>X</button> */}
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          {userdetail?.walletStatus == 'connect' && !isEmpty(getWalletAddress()) ?
            isEmpty(addLiquidity) && isEmpty(removeLiquidity) && isEmpty(swap) && isEmpty(approve) ?
              <p className={`text-white text-center`}>No recent transactions</p> :
              "" :
            <Button className={`${cascading.btnstyle}`} onClick={props.onHide}>
              Connect Wallet
            </Button>}
          {/* {userdetail.walletStatus == 'connect' ? <p className={`text-white`}>No recent transactions</p> :
        <Button className={`${cascading.btnstyle}`} onClick={props.onHide}>
         Connect Wallet
          </Button>
} */}

          {!isEmpty(addLiquidity) || !isEmpty(removeLiquidity) || !isEmpty(swap) || !isEmpty(approve) ?
            <div className={`${cascading.clrsec} mb-4`}>
              {/* <p className={` ${cascading.label}`}>Recent transactions</p> */}
              <button className={`ms-auto btn ${cascading.clrbtn}`} onClick={() => { clearAll() }}>Clear all</button>
            </div> : ""}


          {!isEmpty(addLiquidity) ?
            <button
              className={`${cascading.translist} ${cascading.translistcenter}`}
              onClick={() => {
                window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem("addLiquidity")).txhash}`, '_blank');
                // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("addLiquidity")}`
              }} >
              {JSON.parse(localStorage.getItem("addLiquidity")).qureyString}
              <FaExternalLinkAlt fill='#fff' />
              {/* <TiTick /> */}
            </button> : ""}

          {!isEmpty(removeLiquidity) ?
            <button
              className={`${cascading.translist} ${cascading.translistcenter}`}
              onClick={() => {
                window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem("removeLiquidity")).txhash}`, '_blank');
                // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("removeLiquidity")
              }} >
              {JSON.parse(localStorage.getItem("removeLiquidity")).qureyString}
              <FaExternalLinkAlt fill='#fff' />
              {/* <TiTick /> */}
            </button> : ""}

          {!isEmpty(swap) ?
            <button
              className={`${cascading.translist} ${cascading.translistcenter}`}
              onClick={() => {
                window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem("swap")).txhash}`, '_blank');
                // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("swap")}`
              }} >
              {JSON.parse(localStorage.getItem("swap")).qureyString}
              <FaExternalLinkAlt fill='#fff' />
              {/* <TiTick /> */}
            </button> : ""}

          {!isEmpty(approve) ?
            <div className='text-center'>
              <button
                className={`${cascading.translist} ${cascading.translistcenter}`}
                onClick={() => {
                  window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem('approve')).txhash}`, '_blank');
                  // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("approve")}`
                }} >
                {JSON.parse(localStorage.getItem('approve')).qureyString} <FaExternalLinkAlt fill='#fff' />
                {/* <TiTick /> */}
              </button> </div> : ""}
        </Modal.Body>
        <Modal.Footer className={`${cascading.modalclr} ${cascading.modalfooter}`}>



        </Modal.Footer>
      </Modal>
    </div>
  )
}

