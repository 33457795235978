import React from 'react'
import cascading from '../assests/css/all.module.css';
import { BiRightArrowAlt } from "react-icons/bi";
export default function Balancecard(props) {
    const {datas} =  props
    return (
        <>
            <div className={`${cascading.balancecard}`}>
                <div className='pe-1'>
                    <p className={`${cascading.labelname}`}>{datas.label}</p>
                    <p className={`${cascading.labelvalue} ${cascading.labelvaluebrk}`}>{datas.value}</p>
                </div>
                <div>
                    <img className={`img-fluid ${cascading.balanceimg}`} src={datas.img} alt='img' />
                </div>
            </div>



        </>
    )
}
