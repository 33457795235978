import { combineReducers } from "redux";
import wallet from "./walletReducers";
import adminReducer from './admin'
import settings from "./settingsReducer";
import poolRedux from './poolReducer'
import feeSettings from './gasFeeSettings'
import networkSwitching from './networkSwitch'
import farmReducer from './farmReducer'
import nftpoolReducer from "./NFTpoolreducer";
import launchReducer from './launchReducer'
import positionReducer from "./positionReducer";
import yieldReducer from "./yieldReducer";
import chartPriceReducer from './chartPriceReducer'
import dividendsReducer from "./dividendsReducer";
import xTokenReducer from "./XtokenReducers";
import nativeReducer from "./nativeReducer";
import tokenReducer from "./tokenReducer";
export default combineReducers({
    wallet:wallet,
    admin:adminReducer,
    settings:settings,
    pool:poolRedux,
    gasSettings : feeSettings,
    networkSwitching : networkSwitching,
    farm:farmReducer,
    chart:chartPriceReducer,
    nftpool:nftpoolReducer,
    launch: launchReducer,
    position:positionReducer,
    yield:yieldReducer,
    dividends:dividendsReducer,
    xtoken:xTokenReducer,
    native:nativeReducer,
    token:tokenReducer
});