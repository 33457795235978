import { CURRENT_CHAIN_ID, UseWeb3, useWeb3 } from "./useWeb3"
import BigNumber from "bignumber.js"

import NFTPoolABI from '../config/abi/NFTPool.json'
import contractAddress from '../config/constant/contract'
import pairABI from '../config/abi/pairAbi.json'
import MASTER_ABI from '../config/abi/masterABI.json';

//constant
import {
    POSITION_LIST,
    TOTAL_HOLDING,
    SPNFT_Reward,
    NFTPOOL_DATA,
    NFTPOOL_TVL,
    NFTPOOL_DEPOSITS,
    NFTPOOL_REWARD,
    IS_POSITION,
    IS_LOAD_POSITION,
    IS_LOAD_NFTPOOL
} from '../constants'

//lib
import { consolelog } from "../lib/consolelog"
import { getNFTPoolList, getWalletAddress, setNFTPoolList } from "../lib/localStorage"

//env
import { BLOCKS_PER_YEAR, SWAP_FEE, TRADING_VOLUME_24, ZEROTH_ADDRESS } from '../config/env';

//hooks
import { EstGas } from "./useCommon"
import { multicall } from "./useMultiCall"
import { toFixedNumber } from "../lib/toFixedOf"
import { GetPoolLength, GetPoolAddress } from './useNFTpoolFactory';
import { getTokens, TokenBalance } from './useTokens';
import { GetBlockTimeStamp } from './useCommon';
import { getTokenPricesFromFarm } from './usePools'
import { isEmpty } from "../lib/isEmpty"
import { getMasterAddress } from "./useMaster"
import { TokenInfo } from "./useErc20"

//store
import store from "../store";

const _TOTAL_REWARDS_SHARES = '10000'
export const getNFTpooladdress = () => {
    try {
        const CHAINID = CURRENT_CHAIN_ID()
        let PoolFactoryAddress = contractAddress.NFTPoolFactory[`${CHAINID}`]
        return PoolFactoryAddress
    } catch (err) {
        consolelog('getNFTpooladdress__err', err, true)
    }
}
export const UsePoolcontract = async (poolAddress) => {
    const web3 = await useWeb3()
    try {
        let contract = new web3.eth.Contract(NFTPoolABI, poolAddress)
        return contract
    } catch (err) {
        consolelog('UsePoolcontract__err', err, true)
        return false
    }
}

export const GetPoolInfo = async (poolAddress) => {
    try {
        let contract = await UsePoolcontract(poolAddress)
        let poolInfo = await contract.methods.getPoolInfo().call()
        return poolInfo
    } catch (err) {
        console.log(err, 'GetPoolInfo__Err')
        return {}
    }
}

export const GetMultiplierSettings = async (poolAddress) => {
    try {
        let contract = await UsePoolcontract(poolAddress)
        let settingsInfo = await contract.methods.getMultiplierSettings().call()
        return settingsInfo
    } catch (err) {
        console.log(err, 'GetMultiplierSettings__Err')
        return {}
    }
}

export const GetPoolListInfo = async (dispatch) => {
    try {
        let poolLength = await GetPoolLength()
        let poolList = []
        for (let i = 0; i < poolLength; i++) {
            let poolAddress = await GetPoolAddress(i)
            if (poolAddress !== ZEROTH_ADDRESS) {
                let Calls = [
                    {
                        address: poolAddress,
                        name: 'getPoolInfo'
                    },
                    {
                        address: poolAddress,
                        name: 'getMultiplierSettings'
                    },
                    {
                        address: poolAddress,
                        name: 'YUMRewardsShare',
                    }
                ]
                const [poolInfo, settingsInfo, YUMRewardsShare] = await multicall(NFTPoolABI, Calls)

                console.log(poolInfo, settingsInfo, 'GetPoolListInfo')
                let PoolInfo = {}
                PoolInfo['YUMToken'] = poolInfo.YUMToken
                PoolInfo['dspToken'] = poolInfo.dspToken
                PoolInfo['lpToken'] = poolInfo.lpToken
                PoolInfo['lastRewardTime'] = new BigNumber(poolInfo.lastRewardTime._hex).toString()
                PoolInfo['accRewardsPerShare'] = new BigNumber(poolInfo.accRewardsPerShare._hex).toString()
                PoolInfo['lpSupply'] = new BigNumber(poolInfo.lpSupply._hex).toString()
                PoolInfo['lpSupplyWithMultiplier'] = new BigNumber(poolInfo.lpSupplyWithMultiplier._hex).toString()
                PoolInfo['allocPoint'] = new BigNumber(poolInfo.allocPoint._hex).toString()
                PoolInfo['maxGlobalMultiplier'] = new BigNumber(settingsInfo.maxGlobalMultiplier._hex).toString() / 10000
                PoolInfo['maxLockDuration'] = new BigNumber(settingsInfo.maxLockDuration._hex).toString()
                PoolInfo['maxLockMultiplier'] = new BigNumber(settingsInfo.maxLockMultiplier._hex).toString() / 10000
                PoolInfo['maxBoostMultiplier'] = new BigNumber(settingsInfo.maxBoostMultiplier._hex).toString() / 10000
                PoolInfo['YUMRewardsShare'] = new BigNumber(YUMRewardsShare[0]._hex).toString() / 100
                // let poolInfo = await GetPoolInfo(poolAddress)
                // let PoolInfo = { ...poolInfo }
                console.log(PoolInfo, 'PoolInfo')
                let calls = [
                    {
                        address: poolInfo.lpToken,
                        name: 'token0',
                    },
                    {
                        address: poolInfo.lpToken,
                        name: 'token1',
                    },
                    {
                        address: poolInfo.lpToken,
                        name: 'getReserves'
                    },
                    {
                        address: poolInfo.lpToken,
                        name: 'totalSupply'
                    }
                ]
                if (getWalletAddress()) {
                    calls.push({
                        address: poolInfo.lpToken,
                        name: 'balanceOf',
                        params: [getWalletAddress()]
                    })
                }
                var pooldata = await multicall(pairABI, calls)
                let token0, token1, token0Info, token1Info, reserveA, reserveB, lpPriceInusd
                let farms = JSON.parse(localStorage.getItem("Farms"))
                let farm = farms.find((val) => (val.lpAddress == poolInfo.lpToken))
                let price = await getTokenPricesFromFarm(farms)
                console.log(price, 'getTokenPricesFromFarm')
                let dspinusd = price[PoolInfo.dspToken.toLowerCase()]
                if (pooldata) {
                    let notinusd = isEmpty(farm) ? true : false
                    let tokenPriceBusd = farm?.tokenPriceBusd ? farm.tokenPriceBusd : 1
                    let quoteTokenPriceBusd = farm?.quoteTokenPriceBusd ? farm.quoteTokenPriceBusd : 1
                    reserveA = new BigNumber(pooldata[2][0]._hex).toNumber() * tokenPriceBusd
                    reserveB = new BigNumber(pooldata[2][1]._hex).toNumber() * quoteTokenPriceBusd
                    lpPriceInusd = reserveB / reserveA
                    token0 = pooldata[0][0]
                    token1 = pooldata[1][0]

                    token0Info = getTokens().find((val) => (val.address.toString() == token0.toString()))
                    token0Info = isEmpty(token0Info) ? await TokenInfo(token0) : token0Info
                    token1Info = getTokens().find((val) => (val.address.toString() == token1.toString()))
                    token1Info = isEmpty(token1Info) ? await TokenInfo(token1) : token1Info
                    PoolInfo['notinusd'] = notinusd
                    PoolInfo['token0'] = token0Info
                    PoolInfo['token1'] = token1Info
                    PoolInfo['totalLpSupply'] = new BigNumber(pooldata[3][0]._hex).toNumber()
                    PoolInfo['poolAddress'] = poolAddress
                    PoolInfo['lpPriceInusd'] = lpPriceInusd
                    PoolInfo['lpSupplyInusd'] = (parseFloat(PoolInfo['lpSupply']) / 10 ** 18) * lpPriceInusd
                    PoolInfo['dspinusd'] = dspinusd
                    PoolInfo['lpsymbol'] = `${token0Info.symbol}-${token1Info.symbol}`
                    PoolInfo['lpBalance'] = PoolInfo['lpBalance'] = isEmpty(getWalletAddress()) ? 0 : new BigNumber(pooldata[4][0]._hex).toNumber() / 10 ** 18
                    console.log(pooldata, 'GetPoolListInfo', PoolInfo)
                    let { apy, RewardPerYear, dailyReward } = await GetFarmBaseApr({
                        allocPoint: PoolInfo['allocPoint'],
                        rewardTokenPrice: PoolInfo['dspinusd'],
                        poolTVL: PoolInfo['lpSupplyInusd']
                    })
                    let SwapFeeApr = await GetSwapFeeApr(
                        {
                            totalLpSupply: PoolInfo['totalLpSupply'],
                            lpPriceInusd: PoolInfo['lpPriceInusd']
                        }
                    )
                    PoolInfo['dailyReward'] = dailyReward
                    PoolInfo['RewardPerYear'] = RewardPerYear
                    PoolInfo['FramBaseApr'] = apy
                    PoolInfo['SwapFeeApr'] = SwapFeeApr
                    PoolInfo['Apr'] = parseFloat(apy) + parseFloat(SwapFeeApr)
                    poolList.push(PoolInfo)
                }
                if (i == poolLength - 1) {
                    dispatch({
                        type: NFTPOOL_DATA,
                        payload: poolList
                    })
                    setNFTPoolList(poolList)
                    let totalTvl = poolList.reduce((total, currentValue) => {
                        console.log(total, currentValue, 'GetPoolListInfo__totalTvl')
                        if (!currentValue.notinusd) {
                            return total = parseFloat(total) + parseFloat(currentValue.lpSupplyInusd)
                        } else {
                            return total
                        }
                    }, 0)
                    dispatch({
                        type: NFTPOOL_TVL,
                        payload: totalTvl
                    })
                    dispatch({
                        type :IS_LOAD_NFTPOOL,
                        payload:false
                    })
                    console.log('GetPoolListInfo__totalTvl', totalTvl)
                    // return poolList
                }
            }
        }
    } catch (err) {
        dispatch({
            type :IS_LOAD_NFTPOOL,
            payload:false
        })
        console.log(err, 'GetPoolList__err')
    }
}

export const GetFarmBaseApr = async ({ allocPoint, rewardTokenPrice, poolTVL }) => {
    try {
        let masteraddres = getMasterAddress()
        let calls = [
            {
                address: masteraddres,
                name: 'totalAllocPoint',
            },
            {
                address: masteraddres,
                name: 'emissionRate',
            }
        ]
        const [totalAllocPoint, emissionRate] = await multicall(MASTER_ABI, calls)
        const poolWeight = new BigNumber(allocPoint).div(new BigNumber(totalAllocPoint))
        let RewardPerSeond = new BigNumber(emissionRate).times(new BigNumber(allocPoint)).div(new BigNumber(totalAllocPoint))
        RewardPerSeond = RewardPerSeond.div(new BigNumber(10).pow(18))

        const RewardPerYear = new BigNumber(31540000).times(RewardPerSeond)
        const dailyReward = new BigNumber(86400).times(RewardPerSeond)
        let apy = new BigNumber(rewardTokenPrice).times(RewardPerYear);
        apy = apy.div(new BigNumber(poolTVL));
        console.log((apy).toNumber(), 'GetFarmBaseApr', new BigNumber(RewardPerSeond).toNumber(), new BigNumber(RewardPerYear).toNumber())
        return { apy: (apy).toNumber(), RewardPerYear: new BigNumber(RewardPerYear).toNumber(), dailyReward: dailyReward }
    } catch (err) {
        console.log(err, 'GetFarmBaseApr___err')
    }
}

export const GetSwapFeeApr = async ({ totalLpSupply, lpPriceInusd }) => {
    try {
        const feePerDay = TRADING_VOLUME_24 * (SWAP_FEE / 10 ** 4)
        const feePerYear = feePerDay * (BLOCKS_PER_YEAR)
        let TVL = parseFloat(totalLpSupply / 10 ** 18) * parseFloat(lpPriceInusd)
        const apr = (new BigNumber(feePerYear).toNumber() / TVL) * 100;
        console.log(apr, 'GetSwapFeeApr', feePerYear, TVL)
        return apr
    } catch (err) {
        console.log(err, 'GetFarmBaseApr___err')
    }
}

export const GetBounsApr = ({ annualInterestRate, bonusPercentage }) => {
    try {
        console.log(annualInterestRate, bonusPercentage, 'GetBounsApr')
        const adjustedInterestRate = parseFloat(annualInterestRate) * (1 + parseFloat(bonusPercentage) / 100);
        return adjustedInterestRate;
    } catch (err) {
        console.log(err, 'GetFarmBaseApr___err')
    }
}

export const GetuserPositions = async (poolData, dispatch) => {
    try {
        const web3 = await useWeb3()
        console.log('GetUserTokenIds')
        let positionsList = []
        let totalHoldings = 0
        let totalSPNFT = 0
        let DepositVal = {}
        let RewardsVal = {}
        for (let i = 0; i < poolData.length; i++) {
            let poolAddress = poolData[i].poolAddress
            console.log(poolAddress, 'poolLength')
            if (poolAddress !== ZEROTH_ADDRESS) {
                let contract = await UsePoolcontract(poolAddress)
                console.log(contract.methods, 'tokenIds')
                let tokenIds = await contract.methods.getuserTokenIdLength(web3.utils.toChecksumAddress(getWalletAddress())).call()
                console.log(tokenIds, 'tokenIds1')
                if (tokenIds.length > 0) {
                    for (let j = 0; j < tokenIds.length; j++) {
                        let calls = [
                            {
                                address: poolAddress,
                                name: 'getStakingPosition',
                                params: [tokenIds[j]]
                            },
                            {
                                address: poolAddress,
                                name: 'pendingRewards',
                                params: [tokenIds[j]]
                            },
                            {
                                address: poolAddress,
                                name: 'YUMRewardsShare',
                            }
                        ]
                        const [positionInfo, pendingReward, YUMRewardsShare] = await multicall(NFTPoolABI, calls)
                        console.log(positionInfo, pendingReward[0], new BigNumber(YUMRewardsShare[0]._hex).toString(), new BigNumber(pendingReward[0]._hex).toString(), new BigNumber(positionInfo.amount._hex).toString(), 'positionData')
                        let PositionInfo = {}
                        PositionInfo['amount'] = toFixedNumber(new BigNumber(positionInfo.amount._hex).toString() / 10 ** 18)
                        PositionInfo['amountInusd'] = parseFloat(PositionInfo['amount']) * poolData[i].lpPriceInusd
                        totalHoldings = parseFloat(totalHoldings) + PositionInfo['amountInusd']
                        PositionInfo['amountWithMultiplier'] = new BigNumber(positionInfo.amountWithMultiplier._hex).toString()
                        PositionInfo['boostPoints'] = toFixedNumber(new BigNumber(positionInfo.boostPoints._hex).toString() / 10 ** 18)
                        PositionInfo['lockDuration'] = new BigNumber(positionInfo.lockDuration._hex).toString()
                        PositionInfo['lockMultiplier'] = toFixedNumber(new BigNumber(positionInfo.lockMultiplier._hex).toString() / 10 ** 4)
                        PositionInfo['rewardDebt'] = new BigNumber(positionInfo.rewardDebt._hex).toString()
                        PositionInfo['startLockTime'] = new BigNumber(positionInfo.startLockTime._hex).toString()
                        PositionInfo['totalMultiplier'] = new BigNumber(positionInfo.totalMultiplier._hex).toString()
                        PositionInfo['pendingReward'] = new BigNumber(pendingReward[0]._hex).toString()
                        PositionInfo['YUMRewardsShare'] = new BigNumber(YUMRewardsShare[0]._hex).toString()
                        PositionInfo['boostMultiplier'] = await GetMultiplierByBoostPoints(poolData[i].poolAddress, new BigNumber(positionInfo.amount._hex).toString(), new BigNumber(positionInfo.boostPoints._hex).toString())
                        //withdraw time checks
                        let blocktimestamp = await GetBlockTimeStamp()
                        let isWithdraw = parseFloat(PositionInfo.startLockTime) + parseFloat(PositionInfo.lockDuration) <= blocktimestamp ? true : false
                        PositionInfo['isWithdraw'] = isWithdraw

                        PositionInfo['tokenId'] = tokenIds[j]
                        PositionInfo['token0'] = poolData[i].token0
                        PositionInfo['token1'] = poolData[i].token1
                        PositionInfo['lpToken'] = poolData[i].lpToken
                        PositionInfo['poolAddress'] = poolData[i].poolAddress
                        PositionInfo['lpBalance'] = await TokenBalance(poolData[i].lpToken)
                        PositionInfo['lpsymbol'] = `${poolData[i].token0.symbol}-${poolData[i].token1.symbol}`


                        //reward calculation
                        const { YUMRewards, dspRewards } = rewardCalculation(PositionInfo)
                        PositionInfo['YUMRewards'] = toFixedNumber((YUMRewards) / 10 ** 18)
                        PositionInfo['dspRewards'] = toFixedNumber(dspRewards / 10 ** 18)
                        PositionInfo['RewardsInusd'] = parseFloat(PositionInfo['dspRewards']) * parseFloat(poolData[i].dspinusd) + parseFloat(PositionInfo['YUMRewards']) * parseFloat(poolData[i].dspinusd)
                        console.log(PositionInfo, 'positionData')
                        totalSPNFT = totalSPNFT + PositionInfo['RewardsInusd']
                        positionsList.push(PositionInfo)
                        DepositVal[`${poolAddress}`] = isEmpty(DepositVal[`${poolAddress}`]) ? PositionInfo['amountInusd'] : parseFloat(DepositVal[`${poolAddress}`]) + parseFloat(PositionInfo['amountInusd'])
                        RewardsVal[`${poolAddress}`] = isEmpty(RewardsVal[`${poolAddress}`]) ? PositionInfo['RewardsInusd'] : parseFloat(RewardsVal[`${poolAddress}`]) + parseFloat(PositionInfo['RewardsInusd'])
                    }
                }
            }
            if (poolData.length - 1 == i) {
                console.log(totalHoldings, 'totalHoldings', totalSPNFT, DepositVal, RewardsVal,positionsList)
                if (positionsList.length > 0) {
                    dispatch({
                        type: POSITION_LIST,
                        payload: positionsList
                    })
                    dispatch({
                        type: TOTAL_HOLDING,
                        payload: totalHoldings
                    })
                    dispatch({
                        type: SPNFT_Reward,
                        payload: totalSPNFT
                    })
                    dispatch({
                        type: NFTPOOL_DEPOSITS,
                        payload: DepositVal
                    })
                    dispatch({
                        type: NFTPOOL_REWARD,
                        payload: RewardsVal
                    })
                    dispatch({
                        type:IS_POSITION,
                        payload:true
                    })
                }else{
                    dispatch({
                        type:IS_POSITION,
                        payload:false
                    })
                }

                return { positionsList: positionsList, totalHoldings: totalHoldings, totalSPNFT: totalSPNFT }
            }
        }
    } catch (err) {
        console.log(err, 'GetPositionList__err')
        dispatch({
            type:IS_POSITION,
            payload:false
        })
    }
}




export const rewardCalculation = (PositionInfo) => {
    try {
        const YUMRewards = (PositionInfo.pendingReward * PositionInfo.YUMRewardsShare) / _TOTAL_REWARDS_SHARES
        const dspAmount = (PositionInfo.pendingReward) - YUMRewards

        console.log('rewardCalculation', YUMRewards, dspAmount)
        return { YUMRewards: YUMRewards, dspRewards: dspAmount }
    } catch (err) {
        console.log(err, 'rewardCalculation__err')
    }
}

export const AddtoPosition = async (poolAddress, tokenId, amount) => {
    try {
        let web3 = await useWeb3()
        let contract = await UsePoolcontract(poolAddress)
        let params = [tokenId, amount]
        const account = getWalletAddress()
        const { gasLimit, gasPrice } = await EstGas(params, NFTPoolABI, poolAddress, 'addToPosition', account)
        let result = await contract.methods.addToPosition(tokenId, amount).send({ from: web3.utils.toChecksumAddress(getWalletAddress()), gasLimit: gasLimit, gasPrice: gasPrice })
        if (result) {
            let poolList = getNFTPoolList()
            GetuserPositions(poolList, store.dispatch)
            return result
        } else {
            return false
        }
    } catch (err) {
        console.log(err, 'AddtoPosition__err')
        return false
    }
}

export const WithdrawFromPosition = async (poolAddress, tokenId, amount) => {
    try {
        let web3 = await useWeb3()
        let contract = await UsePoolcontract(poolAddress)
        let params = [tokenId, amount]
        const account = getWalletAddress()
        const { gasLimit, gasPrice } = await EstGas(params, NFTPoolABI, poolAddress, 'withdrawFromPosition', account)
        let result = await contract.methods.withdrawFromPosition(tokenId, amount).send({ from: web3.utils.toChecksumAddress(getWalletAddress()), gasLimit: gasLimit, gasPrice: gasPrice })
        if (result) {
            let poolList = getNFTPoolList()
            GetuserPositions(poolList, store.dispatch)
            return result
        } else {
            return false
        }
    } catch (err) {
        console.log(err, 'WithdrawFromPosition__err')
        return false
    }
}


export const HarvestPosition = async (poolAddress, tokenId) => {
    try {
        let web3 = await useWeb3()
        let contract = await UsePoolcontract(poolAddress)
        let params = [tokenId]
        const account = getWalletAddress()
        const { gasLimit, gasPrice } = await EstGas(params, NFTPoolABI, poolAddress, 'harvestPosition', account)
        let result = await contract.methods.harvestPosition(tokenId).send({ from: web3.utils.toChecksumAddress(getWalletAddress()), gasLimit: gasLimit, gasPrice: gasPrice })
        if (result) {
            let poolList = getNFTPoolList()
            GetuserPositions(poolList, store.dispatch)
            return result
        } else {
            return false
        }
    } catch (err) {
        console.log(err, 'harvestPosition__err')
        return false
    }
}

export const LockPosition = async (poolAddress, tokenId, lockDuration) => {
    try {
        let web3 = await useWeb3()
        let contract = await UsePoolcontract(poolAddress)
        let params = [tokenId, lockDuration]
        const account = getWalletAddress()
        const { gasLimit, gasPrice } = await EstGas(params, NFTPoolABI, poolAddress, 'lockPosition', account)
        let result = await contract.methods.lockPosition(tokenId, lockDuration).send({ from: web3.utils.toChecksumAddress(getWalletAddress()), gasLimit: gasLimit, gasPrice: gasPrice })
        if (result) {
            let poolList = getNFTPoolList()
            GetuserPositions(poolList, store.dispatch)
            return result
        } else {
            return false
        }
    } catch (err) {
        console.log(err, 'LockPosition__err')
        return false
    }
}

export const CreatePosition = async (poolAddress, amount, lockDuration) => {
    try {
        let web3 = await useWeb3()
        let contract = await UsePoolcontract(poolAddress)
        let params = [amount, lockDuration]
        const account = getWalletAddress()
        const { gasLimit, gasPrice } = await EstGas(params, NFTPoolABI, poolAddress, 'createPosition', account)
        let result = await contract.methods.createPosition(amount, lockDuration).send({ from: web3.utils.toChecksumAddress(getWalletAddress()), gasLimit: gasLimit, gasPrice: gasPrice })
        if (result) {
            let poolList = getNFTPoolList()
            GetuserPositions(poolList, store.dispatch)
            return result
        } else {
            return false
        }
    } catch (err) {
        console.log(err, 'CreatePosition__err')
        return false
    }
}


export const GetMultiplierByLockDuration = async (poolAddress, lockDuration) => {
    try {
        let contract = await UsePoolcontract(poolAddress)
        let result = await contract.methods.getMultiplierByLockDuration(lockDuration).call()
        return (result) / 10 ** 4
    } catch (err) {
        console.log(err, 'GetMultiplierByLockDuration__err')
        return 0
    }
}

export const GetMultiplierByBoostPoints = async (poolAddress, amount, boostPoints) => {
    try {
        console.log('GetMultiplierByBoostPoints', poolAddress, amount, boostPoints)
        let contract = await UsePoolcontract(poolAddress)
        console.log('GetMultiplierByBoostPoints', contract)
        let result = await contract.methods.getMultiplierByBoostPoints(amount, boostPoints).call()
        console.log(result, 'GetMultiplierByBoostPoints')
        return (result) / 10 ** 4
    } catch (err) {
        console.log(err, 'GetMultiplierByBoostPoints__err')
        return 0
    }
}

export const getApy = (apr, compoundFrequency = 1, days = 365, performanceFee = 0) => {
    console.log("getApy", apr, compoundFrequency = 1, days = 365, performanceFee = 0)
    const daysAsDecimalOfYear = days / 365
    const aprAsDecimal = apr / 100
    const timesCompounded = 365 * compoundFrequency
    let apyAsDecimal = (apr / 100) * daysAsDecimalOfYear
    console.log("getApy1", daysAsDecimalOfYear, aprAsDecimal, timesCompounded, performanceFee)
    if (timesCompounded > 0) {
        apyAsDecimal = (1 + aprAsDecimal / timesCompounded) ** (timesCompounded * daysAsDecimalOfYear) - 1
    }
    if (performanceFee) {
        const performanceFeeAsDecimal = performanceFee / 100
        const takenAsPerformanceFee = apyAsDecimal * performanceFeeAsDecimal
        apyAsDecimal -= takenAsPerformanceFee
        console.log("getApy2", performanceFeeAsDecimal, takenAsPerformanceFee, apyAsDecimal)

    }
    return apyAsDecimal
}