import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cascading from '../assests/css/all.module.css';

import { useSelector, useDispatch } from 'react-redux';

import { FaArrowRight } from "react-icons/fa";
import RangeSlider from 'react-range-slider-input';

import { ALLOCATE_DETAILS } from '../constants'

import { toFixedNumber } from '../lib/FixedNumber';

import { GettotalAllocationFloor, GetMaxBonus, GetPoolTotalAllocation } from '../hooks/useyeildBooster';
import { isEmpty } from '../lib/isEmpty';
function RangeAllocation(props) {
    const { record } = props
    const dispatch = useDispatch()
    const { nftpooldata } = useSelector((state) => (state.nftpool))
    const { xtokenbalance } = useSelector((state) => (state.xtoken))
    const { allocateDetails } = useSelector((state) => (state.yield))
    const [points, SetPoints] = useState('')
    const [max, SetMax] = useState('')
    const [poolInfo, setPoolInfo] = useState({})
    const [xbalance, Setxbalance] = useState(0)
    useEffect(() => {
        let poolInfo = nftpooldata.find((val) => (val?.poolAddress?.toLowerCase() == record?.poolAddress?.toLowerCase()))
        setPoolInfo(poolInfo)
    }, [nftpooldata, record])

    // useEffect(() => {
    //     let newAllocate = allocateDetails?.reduce((total = xtokenbalance, currentValue) => {
    //         console.log(currentValue.type == 'allocate', "currentValue.type == 'allocate'")
    //         if (currentValue.type == 'allocate') {
    //             console.log(currentValue.type == 'allocate', "currentValue.type == 'allocate'", parseFloat(total) + parseFloat(currentValue.amount))
    //             return total = parseFloat(total) - parseFloat(currentValue.amount);
    //         } else {
    //             return total = parseFloat(total) + parseFloat(currentValue.amount);
    //         }
    //     }, xtokenbalance)
    //     console.log(newAllocate, 'newAllocate')
    //     Setxbalance(newAllocate)
    // }, [allocateDetails, xtokenbalance])

    const handleChange = (e) => {
        try {
            let AllocateDetails = [...allocateDetails]
            let index = AllocateDetails.findIndex((val) => (val.tokenId == record.tokenId && val.poolAddress == record.poolAddress))
            let AllocateDetail = {}
            if (index != -1) {
                AllocateDetail = { ...AllocateDetails[index] }
            }
            SetPoints(e[1])
            console.log(e[1],'handleChange__RangeAllocation',record.boostPoints)
            if (parseFloat(e[1]) < parseFloat(record.boostPoints)) {
                AllocateDetail['tokenId'] = record.tokenId
                AllocateDetail['amount'] = record.boostPoints - parseFloat(e[1])
                AllocateDetail['poolAddress'] = record.poolAddress
                AllocateDetail['type'] = 'deallocate'
            } else if (parseFloat(e[1]) > parseFloat(record.boostPoints)) {
                AllocateDetail['tokenId'] = record.tokenId
                AllocateDetail['amount'] = e[1] - parseFloat(record.boostPoints)
                AllocateDetail['poolAddress'] = record.poolAddress
                AllocateDetail['type'] = 'allocate'
            }
            else if(parseFloat(e[1]) == parseFloat(record.boostPoints)){
                if(index != -1){
                    AllocateDetails.splice(index,1)
                    dispatch({
                        type: ALLOCATE_DETAILS,
                        payload: AllocateDetails
                    })
                    return true
                }
            }
            console.log(AllocateDetail, 'AllocateDetail')
            if (index != -1) {
                AllocateDetails[index] = AllocateDetail
            } else {
                if(!isEmpty(AllocateDetail)){
                    AllocateDetails.push(AllocateDetail)
                }
            }
            dispatch({
                type: ALLOCATE_DETAILS,
                payload: AllocateDetails
            })
        } catch (err) {
            console.log(err, 'handleChange__err')
        }
    }

    const SetMaxBonus = () => {
        try {
            SetPoints(max)
            let AllocateDetails = [...allocateDetails]
            let index = AllocateDetails.findIndex((val) => (val.tokenId == record.tokenId))
            let AllocateDetail = {}
            if (index != -1) {
                AllocateDetail = { ...AllocateDetails[index] }
            }
            AllocateDetail['tokenId'] = record.tokenId
            AllocateDetail['amount'] = parseFloat(max) - parseFloat(record.boostPoints)
            AllocateDetail['poolAddress'] = record.poolAddress
            AllocateDetail['type'] = 'allocate'
            if (index != -1) {
                AllocateDetails[index] = AllocateDetail
            } else {
                AllocateDetails.push(AllocateDetail)
            }
            dispatch({
                type: ALLOCATE_DETAILS,
                payload: AllocateDetails
            })
        } catch (err) {
            console.log(err, 'SetMaxBonus__err')
        }
    }

    useEffect(() => {
        SetPoints(record.boostPoints)
        SetMaxBonus2()
    }, [record, poolInfo])

    const SetMaxBonus2 = async () => {
        try {
            // let index = allocateDetails.findIndex((val) => (val.tokenId == record.tokenId && val.poolAddress == record.poolAddress))

            // if (index == -1) {
                console.log(record.poolAddress,'SetMaxBonus__poolAddress')
                let poolAllocation = await GetPoolTotalAllocation(record.poolAddress)
                poolAllocation = parseFloat(poolAllocation)
                let lpSupply = parseFloat(poolInfo.lpSupply)
                let multiper = toFixedNumber(poolInfo.maxBoostMultiplier * 10 ** 4)
                let maxBoostMultiplier = toFixedNumber(poolInfo.maxBoostMultiplier * 10 ** 4)
                let lpAmount = toFixedNumber(record.amount * 10 ** 18)
                let totalAllocationFloor = await GettotalAllocationFloor()
                totalAllocationFloor = parseFloat(totalAllocationFloor)
                let MaxAllocation = GetMaxBonus(multiper, lpSupply, maxBoostMultiplier, lpAmount, poolAllocation, totalAllocationFloor)
                console.log(MaxAllocation, 'SetMaxBonus2')
                let checkMax = MaxAllocation > xtokenbalance ? xtokenbalance : MaxAllocation
                console.log(checkMax, 'SetMaxBonus2')
                SetMax(checkMax)
            // }
        } catch (err) {
            console.log(err, 'SetMaxBonus__err')
        }
    }
    return (
        <>
            <div className='d-flex align-items-center justify-content-between'>
                <p className='mb-0 small'>{record?.boostPoints} RICE</p>
                <FaArrowRight />
                <div className='d-flex align-items-center justify-content-between'>
                    <input
                        className={`${cascading.input_table} form-control form-control-sm`}
                        value={points}
                    />
                    <p className='mb-0 ps-2 small'>RICE</p>
                </div>
            </div>
            <RangeSlider
                className={`${cascading.single_thumb} mt-3`}
                defaultValue={[0, parseFloat(record.boostPoints)]}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
                min='0'
                value={[0, parseFloat(points)]}
                step={0.00001}
                max={parseFloat(max)}
                onInput={(e) => {
                    if(max > 0){
                        console.log(e, 'RangeSlider')
                        handleChange(e)
                    }
                }}
            />
            <div className='text-end mt-2' >
                <button className={`${cascading.maxbonusbtn}`} disabled={max == record.boostPoints} onClick={() => { SetMaxBonus() }}>Get Max Bonus</button>
            </div>

        </>
    )
}
export default RangeAllocation
