import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/all.module.css';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BiRightArrowAlt } from "react-icons/bi";
import { FaMinus, FaPlus } from "react-icons/fa6";
import Countdown from 'react-countdown';

//modals
import ComingSoonmodal from '../modals/comingSoonmodal';
import Allocatemodal from '../modals/allocatemodal';
import Deallocatemodal from '../modals/deallocatemodal';
import Walletmodal from '../modals/walletmodal';

//hooks
import { GetUsageAllocations, getXLaunchAddress, GetDeallocationTime, GetLaunchpadshare, GetDeallocateFee, GetDeallocateCooldown } from '../hooks/useXtoken';

//lib
import { isEmpty } from '../lib/isEmpty';


export default function Allocationlaunchpad(props) {

    const [allocatemodal, setAllocatemodal] = useState(false);
    const [deallocatemodal, setDeallocatemodal] = useState(false);
    const [modalcoming, setmodalcoming] = useState(false);
    const [showmodal, setShowmodal] = useState(false);
    const [isconnect, setIsconnect] = useState(true);
    const [allocBal, setAllocBal] = useState(0);
    const [coolDown, setCoolDown] = useState(0);
    const [launchShare, setLaunchShare] = useState(0);
    const [deallocateFee, setDeallocateFee] = useState(0);
    const [totalShare, setTotalShare] = useState(0);


    console.log("Sales", props.sales, props.receiptAdd, props.allocBal, props.coolDown, props.coolDownTime, props.launchShare, props.totalShare, props.deallocateFee)

    console.log("Timestamp", Date.now() - (coolDown))
    // useEffect(() => {
    //     getAllocations()
    //     allocationTime()
    //     launchSharePer()
    //     DeallocatingFee()

    // }, [coolDown])


    // const getAllocations = async () => {
    //     let allocate = await GetUsageAllocations(getXLaunchAddress())
    //     console.log("allocate_bal", allocate)
    //     setAllocBal(allocate)
    // }


    // const allocationTime = async () => {
    //     let deallocateTime = await GetDeallocationTime()
    //     console.log("deallocateTime1", deallocateTime)
    //     setCoolDown(deallocateTime?.deallocationCooldown)
    // }


    // const launchSharePer = async () => {
    //     let share = await GetLaunchpadshare()
    //     console.log("share", share?.launchShare)
    //     setLaunchShare(share?.launchShare)
    //     setTotalShare(share?.TotalAllocations)
    // }

    // const DeallocatingFee = async()=>{
    //     let fee = await GetDeallocateFee()
    //     console.log("fee", fee)
    //     setDeallocateFee(fee)
    // }


    const Completionist = () => <span className={`${cascading.themetime}`}>00D 00h 00m 00s</span>;


    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }


    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return Completionist();
        } else {
            // Render a countdown
            return <span className={`${cascading.themetime}`}>{formatTime(days)}D {formatTime(hours)}h {formatTime(minutes)}m {formatTime(seconds)}s</span>;
        }
    };





    return (
        <div className={`${cascading.approvecard}`}>

            <div className={`${cascading.innercard} ${cascading.dashcard}`}>
                <p className={`${cascading.head} ${cascading.headfelx}`}>
                    Your Allocation
                    {sessionStorage.getItem("accountInfo") &&
                        <div>
                            <button className={`btn me-2 ${cascading.stakebtnicon}`} onClick={() => { setDeallocatemodal(true); }} disabled={props?.sales?.length == 0}>
                                <FaMinus />
                            </button>

                            <button className={`btn ${cascading.stakebtnicon}`} onClick={() => { setAllocatemodal(true); }} disabled={props?.sales?.length == 0}>
                                {/* <button className={`btn ${cascading.stakebtnicon}`} onClick={() => { setmodalcoming(true) }}> */}
                                <FaPlus />
                            </button>
                        </div>
                    }

                </p>
                <p className={`${cascading.desc}`}>
                    Earn real yield weekly from protocol earnings with RICE.
                </p>
                {sessionStorage.getItem("accountInfo") ?

                    <div className={`${cascading.innercard} ${cascading.innercardalloc} mt-3`}>

                        <>
                            <div className='mt-2'>
                                <p className={`${cascading.labelname}`}>Allocated</p>
                                <p className={`${cascading.value}`}>{isEmpty(props.allocBal) ? 0 : props.allocBal} RICE</p>
                            </div>
                            <div className='mt-2'>
                                <p className={`${cascading.labelname}`}>Launchpad share</p>
                                <p className={`${cascading.value}`}>{isNaN(props.launchShare) ? 0 : props.launchShare?.toFixed(2)}%</p>
                            </div>
                            <div className='mt-2'>
                                <p className={`${cascading.labelname}`}>Deallocation cooldown</p>
                                <p className={`${cascading.value}`}>
                                    {console.log(new Date(props.coolDownTime * 1000), 'Deallocation', props.coolDownTime)}
                                    <Countdown
                                        date={isNaN(props.coolDownTime) ? 0 : new Date(props.coolDownTime * 1000)}
                                        renderer={renderer}
                                    /></p>
                            </div>

                        </>


                    </div>
                    :
                    <div className='text-center my-4'>

                        <button className={`btn ${cascading.connect_btn_new} mx-auto`} onClick={() => setShowmodal(true)}>
                            Connect Wallet
                        </button>

                    </div>
                }





            </div>

            {allocatemodal && <Allocatemodal onHide={() => setAllocatemodal(false)} SaleData={props.sales} totalShare={props.totalShare} launchshare={props.launchShare} getreceiptAdd= {()=>{props.getreceiptAdd()}}/>}
            {deallocatemodal && <Deallocatemodal onHide={() => setDeallocatemodal(false)} SaleData={props.sales} totalShare={props.totalShare} launchshare={props.launchShare} getreceiptAdd= {()=>{props.getreceiptAdd()}}/>}
            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}

            {showmodal && <Walletmodal show={showmodal} onHide={() => setShowmodal(false)} />}


        </div>
    )
}
