import React, { useState, useEffect } from 'react';
import cascading from '../assests/css/all.module.css';
import Footer from '../common/footer';
import Balancecard from '../components/balancecard';

import pfimg from '../assests/images/roundtable.png';
import productimg from '../assests/images/gmbl.png'
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoIosInformationCircleOutline } from "react-icons/io"
import Claimcard from '../components/claimcard';
import Endcard from "../components/endcard";
import Stagecard from "../components/stagecard";
import WhitelistStagecard from '../components/whitelistStagecard'
import Chartcard from "../components/chartcard";
import { useSelector, useDispatch } from 'react-redux';
import Header from '../common/header';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import Countdown from 'react-countdown';


import coinicon from "../../src/assests/images/coinicon.png"

//lib
import { padToDigits } from '../lib/DateTimeHelper'
import { getWalletAddress } from '../lib/localStorage'
import { toFixedNumber } from '../lib/FixedNumber';
import { isEmpty } from '../lib/isEmpty';

//hooks
import {
    getSaleInfoCard, checkbalnceTokens, UseTokenInfo, Getliquiditytokenamount, Getunsoldtoken,
    isSaleLive, isUpcoming, UpcomingDiffernce, Salediffernce, GetSalePerAccount, getAllocationInfo,
    getAffliateInfo, UsegetChildrenOf, checkIsApproved, GetBuytokeninfo, UsegetParentOf
} from '../hooks/useContract'
import { roundToSignificant } from '../hooks/useCommon'
import { getChainId } from '../hooks/useWeb3'
import { BuyToken } from '../hooks/useBuy';
import { getTokenPricesFromFarm } from '../hooks/usePools'
import { GetPairInfo } from '../hooks/useFactory'
import { multicall } from '../hooks/useFarms';

//config
import { CHAINS, ZEROTH_ADDRESS } from '../config/env'
import { LAUNCH_DATA } from '../constants';
import { getTokens } from '../hooks/useTokens';
import ERC20_ABI from '../config/abi/ERC20.json';

import BigNumber from 'bignumber.js'


export default function Launchpaddetail() {

    const [AffliateInfo, setAffliateInfo] = useState({})
    const [saleInfo, setSaleInfo] = useState({})
    const [userData, setUserData] = useState({})
    const [getChildren, setGetChildren] = useState([])
    const [checkbalance, setCheckbalance] = useState(0)
    const [charttokendecimal, setCharttokendecimal] = useState(18)
    const [isSaleEnd, setIsSaleEnd] = useState(false);
    const [chartstatus, setChartstatus] = useState(false);
    const [allocations, setAllocations] = useState([])
    const [approvebutton, setApprovebutton] = useState(false);
    const [buyTokenDecimal, setBuyTokenDecimal] = useState(18)
    const [checkdeposittoken, setCheckdeposittoken] = useState(0)
    const [Avgcontribution, setAvgcontribution] = useState('')
    const [buyToken, setBuyToken] = useState('')
    const [progress, setProgress] = useState(1)
    // const [priceInusd,setPriceInUsd] = useState(0)
    // const [salepriceInusd,setSalePriceInUsd] = useState(0)

    const [totalRaisedUsd, setTotalRaisedUsd] = useState(0)
    const [salePrice, setSalePrice] = useState(0)
    const [circMarketCap, setCircMarketCap] = useState(0)
    const [FDV, setFDV] = useState(0)
    const [usdSymbol, setUsdSymbol] = useState('')
    const [parentAdd, setParentAdd] = useState('')

    const { state } = useLocation()
    const { saleAddress, walletAddress } = useParams()
    const walletDetails = useSelector((state) => (state.wallet))
    console.log("LaunchpadDetail_state", state, saleAddress)


    const dispatch = useDispatch()


    const [detailcard, setDetailcard] = useState([
        {
            label: "TOTAL RAISED",
            value: `${saleInfo.earnedCap} ($7,676.443)`,
            img: require("../assests/images/home_icon.png"),
            key: 'total'

        },
        {
            label: "$TROVE PRICE",
            value: "0.00000226 ETH ($0.0384)",
            img: require("../assests/images/totalalloc.png"),
            key: 'available'

        },
        {
            label: "CIRC. MARKETCAP",
            value: "$11,513,184",
            img: require("../assests/images/pools_icon.png"),
            key: 'allocate'

        },
        {
            label: "FDV",
            value: "$38,377,214",
            img: require("../assests/images/folder.png"),
            key: 'redeem'

        }
    ])


    const [stagedetails, setStagedetails] = useState([{
        img: require('../assests/images/roundtable.png'),
        names: "How price is determined",
        desc: [{
            para: 'You can contribute with USDC.e in exchange for GMBL and xGMBL tokens, which are to be claimed a few days after the sale (exact date TBA).'
        },
        {
            para: '3,250,000 GMBL and 1,750,000 xGMBL (out of a 100,000,000 total supply) will be allocated to the presale.'
        },
        {
            para: 'Price is fixed to $0.15.'
        }]
    },
    {
        img: require('../assests/images/roundtable.png'),
        names: "Three-stage sale",
        desc: [{
            para: 'This presale will start on August 27th @ 12pm UTC, and will take place in three different stages:'
        },
        {
            para: 'STAGE 1: During the first 12h, only whitelisted addresses can participate with a guaranteed capped allocation.'
        },
        {
            para: 'STAGE 2: During the following 12h, only whitelisted addresses can participate, with a 5x higher allocation cap.'
        },
        {
            para: 'STAGE 3: Starting August 28th @ 12pm UTC, other participants can purchase the remaining tokens on a first-come, first-served basis. This stage will last for 24 hours'
        }]
    },
    {
        img: require('../assests/images/roundtable.png'),
        names: "Claiming process",
        desc: [{
            para: 'The public sale will last until August 29th @ 12pm UTC'
        },
        {
            para: 'Shortly after, a LBP will take place on the Swerve platform.'
        },
        {
            para: 'Tokens from the presale and LBP participants will be claimable at the same time liquidity is seeded, shortly after the end of the LBP.'
        },
        {
            para: 'In order to align the long-term objectives of the protocol, the sale will be distributed in 65% of GMBL and 35% of xGMBL.'
        }]
    }])



    const [loaded, setisloaded] = useState(true);

    const [isreferred, setIsreferred] = useState(false);


    const showLoader = () => {
        setisloaded(true);
        document.getElementsByTagName("body")[0].classList.add("overflow_loading")
    }

    const hideLoader = () => {
        setisloaded(false);
        document.getElementsByTagName("body")[0].classList.remove("overflow_loading")
    }
    useEffect(() => {
        showLoader();
        // setIsreferred(false);
        refreshData();

        // LaunchSaleData()
    }, [])

    useEffect(() => {
        if (!isEmpty(saleInfo)) {
            fetchDatas()
            fetchPrice()
        }
    }, [saleInfo])


    useEffect(() => {
        console.log(isSaleLive(saleInfo.startTime, saleInfo.endTime, saleInfo.isPresaleOpen), 'useEffect')
        if (isUpcoming(saleInfo.startTime)) {
            setProgress(1)
        }
        else if (!isUpcoming(saleInfo.startTime) && isSaleLive(saleInfo.startTime, saleInfo.endTime, saleInfo.isPresaleOpen)) {
            setProgress(2)
        }
        else if (saleInfo.isClaimable == true) {
            setProgress(4)
        }
        else {
            setProgress(3)
        }
    }, [saleInfo])

    // const LaunchSaleData = async () =>{
    //     const totalproxydata = LaunchData?.filter(data => data.saleAddress == window.location.pathname.split('/')[2])
    //     console.log("totalproxydata",totalproxydata)
    // }

    useEffect(() => {
        if (getWalletAddress() && !isEmpty(parentAdd) && !isEmpty(walletAddress)) {
            console.log(parentAdd !== ZEROTH_ADDRESS && parentAdd?.toLowerCase() !== walletAddress?.toLowerCase(), 'getWalletAddress', parentAdd)
            if (parentAdd !== ZEROTH_ADDRESS && parentAdd?.toLowerCase() !== walletAddress?.toLowerCase()) {
                setIsreferred(true)
            }
        }

    }, [parentAdd])


    console.log(isreferred, "isreffered")



    const refreshData = async () => {

        try {
            // showLoader()

            console.log('refreshData')
            let saleDetail = await getSaleInfoCard(saleAddress);
            var saleData = { ...saleDetail }
            saleData.presaleRate = parseFloat(saleData.presaleRate) / 10 ** 18
            saleData.pancakeRate = parseFloat(saleData.pancakeRate) / 10 ** 18
            setSaleInfo(saleData);
            console.log("notdb", saleData);



            const AffliateInfo = await getAffliateInfo(saleAddress);
            console.log("AffliateInfo", AffliateInfo)
            setAffliateInfo(AffliateInfo)

            let getChildrendata
            if (getWalletAddress()) {
                getChildrendata = await UsegetChildrenOf(saleAddress, getWalletAddress());
                setGetChildren(getChildrendata)
                console.log("getChildrengetChildren", getChildrendata, getChildrendata.length);
            }



            const balance = await checkbalnceTokens(saleDetail?.tokenAddress, saleAddress);
            console.log(" >>>>", parseFloat(balance) / 10 ** parseInt(saleDetail?.decimals));
            setCheckbalance(parseFloat(balance) / 10 ** parseInt(saleDetail?.decimals))
            console.log("saleDetail", saleDetail);
            const token = await UseTokenInfo(saleDetail?.tokenAddress);
            let decimal;
            console.log("tokentokentoken", token);
            setCharttokendecimal(18)


            let liqtoken = await Getliquiditytokenamount(saleAddress);
            console.log("liqtoken", liqtoken);
            console.log(sessionStorage.getItem("accountInfo"), "MaxVC balance : 2 ")
            //   setLiqtoken(liqtoken / 10 ** 18)
            let unsoldtoken = await Getunsoldtoken(saleAddress);
            console.log("liqtoken1", liqtoken);

            //   setUnsoldtoken(unsoldtoken / 10 ** 18);
            var saleData = { ...saleDetail }
            saleData.presaleRate = parseFloat(saleData.presaleRate) / 10 ** 18
            saleData.pancakeRate = parseFloat(saleData.pancakeRate) / 10 ** 18

            setSaleInfo(saleData);
            let saleEnd = isSaleLive(parseFloat(saleDetail.startTime), parseFloat(saleDetail.endTime), parseFloat(saleDetail.isPresaleOpen))
            console.log("saleEnd", saleEnd)
            setIsSaleEnd(saleEnd);
            setChartstatus(true);
            GetBuytokeninfo(saleDetail?.useWithToken)
            console.log(sessionStorage.getItem("accountInfo"), "MaxVC balance : 2 ")
            let user
            if (sessionStorage.getItem("accountInfo")) {
                user = await GetSalePerAccount(sessionStorage.getItem("accountInfo"), saleAddress);
                console.log("MaxVC balance : 2 ", user)
                setUserData(user);
                if (saleDetail?.isClaimable) {
                    const allocated = await getAllocationInfo(user.actualBalance, saleDetail.vestingInterval, saleDetail.vestingPercent, user.initialClaim);
                    setAllocations(allocated);
                }
                if (!saleDetail?.buytype) {
                    approveFunction(sessionStorage.getItem("accountInfo"), saleDetail?.useWithToken, saleDetail?.saleAddress)
                }
                // if (!saleDetail?.LaunchpadType) {
                //     handlecheckfinalize()
                // }

            }
            avgcontribution();
            let obj = {
                saleInfo: saleData,
                AffliateInfo: AffliateInfo,
                getChildrendata: getChildrendata,
                UseTokenInfo: token,
                userData: user
            }
            dispatch({
                type: LAUNCH_DATA,
                payload: obj
            })
            hideLoader();
            // if(parentAdd !== ZEROTH_ADDRESS)
            //     {
            //         setisloaded(true);
            //         setIsreferred(true);
            //         // setTimeout(function(){
            //         //     setIsreferred(false);
            //         // setisloaded(false);


            //         // },2000);

            //     }
        }
        catch (e) {
            console.log("error on detail page", e);
        }
    }


    const approveFunction = async (accountInfo, tokenAddress, saleAddress) => {
        const approval = await checkIsApproved(accountInfo, tokenAddress, saleAddress);
        console.log("approval", approval);
        setApprovebutton(approval)
    }

    const handlecheckfinalize = async () => {
        let tokenAmount = 0;
        let amountineth = parseFloat(state?.earnedCap / 10 ** 18) * parseFloat(state?.liquidityPercent) / 100;
        var pricePerToken = parseFloat((state?.earnedCap / 10 ** 18) * 10 ** 8) / parseFloat(state?.hardCap / 10 ** 18); // pricePerToken
        var valuePerBNB = 1 * 10 ** 8 / pricePerToken; // valuePerBNB

        tokenAmount = valuePerBNB;

        if (state?.decimals != "18") {
            var price = (tokenAmount * 1) / 10 ** (18 - parseInt(state?.decimals) + 0);
            tokenAmount = price;
        }

        let total = amountineth * tokenAmount;
        setCheckdeposittoken(total)

    }



    const avgcontribution = async () => {
        console.log("avgcontribution", saleInfo, parseFloat(saleInfo.presaleRate), parseFloat(saleInfo.earnedCap) / 10 ** buyTokenDecimal, parseFloat(saleInfo.participants))
        var contribution = parseFloat(saleInfo.participants) > 0 ? (saleInfo.presaleRate * (saleInfo.earnedCap / 10 ** buyTokenDecimal) / parseFloat(saleInfo.participants)).toFixed(2) : ""
        console.log("contribution", contribution, parseFloat(saleInfo.presaleRate), (parseFloat(saleInfo.earnedCap) / 10 ** buyTokenDecimal), parseFloat(saleInfo.participants), (parseFloat(saleInfo.earnedCap) / 10 ** buyTokenDecimal) / parseFloat(saleInfo.participants))
        setAvgcontribution(contribution)
    }

    const GetBuytokeninfo = async (value) => {
        try {
            if (value === "0x0000000000000000000000000000000000000000") {

                setBuyToken("BNB");

                setBuyTokenDecimal(18)
            } else {

                const token = await UseTokenInfo(saleInfo && saleInfo?.useWithToken);
                setBuyToken(token?.symbol);
                setBuyTokenDecimal(Number(token?.decimals))

            }
        }
        catch (err) {
            console.log("err", err)
        }
    }


    const Completionist = () => <span className={`${cascading.themetime}`}>00D 00h 00m 00s</span>;


    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }


    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return Completionist();
        } else {
            // Render a countdown
            return <span className={`${cascading.themetime}`}>{formatTime(days)}D {formatTime(hours)}h {formatTime(minutes)}m {formatTime(seconds)}s</span>;
        }
    };

    const TotalPriceCal = async (token) => {
        try {
            let farms = JSON.parse(localStorage.getItem("Farms"))
            const prices = getTokenPricesFromFarm(farms)
            console.log(prices, 'fetchDatas')
            let priceInusd = 0

            if (token == ZEROTH_ADDRESS) {

                priceInusd = prices[getTokens()[0].address.toLowerCase()]
                return priceInusd
            } else {
                let usdAddress = getTokens().find((val) => (val.symbol == 'BUSD')).address

                let pairaddress = await GetPairInfo(token, usdAddress)
                if (pairaddress !== ZEROTH_ADDRESS) {
                    const calls = [
                        // Balance of token in the LP contract
                        {
                            address: token,
                            name: 'balanceOf',
                            params: [pairaddress],
                        },
                        // Balance of quote token on LP contract
                        {
                            address: usdAddress,
                            name: 'balanceOf',
                            params: [pairaddress],
                        },
                    ]
                    const [
                        tokenBalanceLP,
                        quoteTokenBlanceLP,
                    ] = await multicall(ERC20_ABI, calls)
                    priceInusd = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP));
                    return priceInusd
                }
                else {
                    return priceInusd
                }

            }
        } catch (err) {
            console.log(err, 'TotalPriceCal_err')
        }
    }

    const fetchDatas = async () => {
        try {


            let CardDetail = detailcard
            console.log(CardDetail, 'CardDetail')
            for (let i = 0; i < CardDetail.length; i++) {
                console.log("salePrice", CardDetail[i], saleInfo)

                const priceInusd = await TotalPriceCal(saleInfo.useWithToken)
                // setPriceInUsd(priceInusd)
                const salepriceInusd = await TotalPriceCal(saleInfo.tokenAddress)
                // setSalePriceInUsd(salepriceInusd)
                let TotalRaised = parseInt(saleInfo.earnedCap) / 10 ** 18
                let TotalRaisedinUsd = parseInt(saleInfo.earnedCap) / 10 ** 18 * priceInusd


                if (CardDetail[i].key == 'total') {
                    CardDetail[i].value = saleInfo &&
                        // `${isNaN(TotalRaised) ? 0 : TotalRaised == Infinity ? 0 : TotalRaised} ${buyToken} $${roundToSignificant(TotalRaisedinUsd, 10)}`
                        `${isNaN(TotalRaised) ? 0 : TotalRaised == Infinity ? 0 : TotalRaised} ${saleInfo.symbol}`

                }
                let salePrice = parseInt(saleInfo.earnedCap) / 10 ** 18 / parseInt(saleInfo.hardCap) / 10 ** 18
                console.log("salePrice1", roundToSignificant(toFixedNumber(salePrice), 6), parseInt(saleInfo.earnedCap) / 10 ** 18, parseInt(saleInfo.hardCap) / 10 ** 18)




                if (CardDetail[i].key == 'available') {
                    console.log(CardDetail[i].key == "available", 'available')
                    CardDetail[i].value = `$${roundToSignificant(TotalRaisedinUsd / (parseInt(saleInfo.hardCap) / 10 ** 18), 10)}`
                    // `${isNaN(salePrice) ? 0 :salePrice == Infinity ? 0 : 
                    //     roundToSignificant(salePrice) < 0.001 ? '<0.001' :roundToSignificant(salePrice) } ${buyToken} $0.0384`
                    CardDetail[i].label = saleInfo && ` $${saleInfo.name} PRICE`
                }

                if (CardDetail[i].key == 'allocate') {
                    let currentPrice = TotalRaisedinUsd / (parseInt(saleInfo.hardCap) / 10 ** 18)
                    let circulatePrice = ((parseInt(saleInfo.hardCap) / 10 ** 18) - (parseInt(saleInfo.earnedCap) / 10 ** 18))
                    CardDetail[i].value = saleInfo && `$${roundToSignificant(currentPrice * circulatePrice, 10)}`
                }
                if (CardDetail[i].key == 'redeem') {
                    CardDetail[i].value = saleInfo && (salepriceInusd * parseInt(saleInfo.hardCap) / 10 ** 18)
                }
                if (i == CardDetail.length - 1) {
                    setDetailcard(CardDetail)
                }
            }
            // consolelog('XtokenTotal',XtokenTotal,true)

        } catch (err) {
            console.log('fetchDatas__err', err, true)
        }

    }

    const fetchPrice = async () => {
        try {
            let usdSymbol = getTokens().find((val) => (val.symbol == 'BUSD')).symbol
            setUsdSymbol(usdSymbol)
            console.log("salePrice", saleInfo)
            const priceInusd = await TotalPriceCal(saleInfo.useWithToken)
            const salepriceInusd = await TotalPriceCal(saleInfo.tokenAddress)

            let TotalRaised = parseInt(saleInfo.earnedCap) / 10 ** 18
            let TotalRaisedinUsd = parseInt(saleInfo.earnedCap) / 10 ** 18 * priceInusd
            setTotalRaisedUsd(TotalRaisedinUsd)

            let salePrice = TotalRaisedinUsd / (parseInt(saleInfo.hardCap) / 10 ** 18)
            setSalePrice(salePrice)

            let currentPrice = TotalRaisedinUsd / (parseInt(saleInfo.hardCap) / 10 ** 18)
            let circulatePrice = ((parseInt(saleInfo.hardCap) / 10 ** 18) - (parseInt(saleInfo.earnedCap) / 10 ** 18))
            let circMarketCap = currentPrice * circulatePrice
            setCircMarketCap(circMarketCap)

            let fdv = saleInfo && (salepriceInusd * parseInt(saleInfo.hardCap) / 10 ** 18)
            setFDV(fdv)

        } catch (err) {
            console.log('fetchPrice__err', err, true)
        }

    }

    useEffect(() => {
        getParent()
    }, [walletDetails, walletAddress])

    const getParent = async () => {
        const parentAdd = await UsegetParentOf(window.location.pathname.split('/')[2], getWalletAddress())
        console.log("parentAdd", parentAdd)
        setParentAdd(parentAdd)
    }

    return (
        <>
            <div className={loaded || isreferred ? 'loader_div' : 'loader_div d-none'} id="loader_div">
                {(loaded && !isreferred) &&
                    <>
                        <div className="circle">
                            {/* <img src={coinicon} className='img-fluid' /> */}
                        </div>
                        <div className="circle_img">
                            <img src={coinicon} className='img-fluid' />
                        </div>
                    </>
                }

                {
                    (isreferred) ?
                    <p className={`${cascading.themeloader}`}>This user is already referred by other user.</p> :''

                }
            </div>


            <div className={loaded || isreferred ? 'logo_overlay' : 'logo_overlay_none'}>



                <Header />

                <div className={`${cascading.innerpages} ${cascading.launchpaddetail}`}>
                    <div className={`container`}>
                        <div className={`row ${cascading.toprow}`}>
                            <p className={`${cascading.breadcrum}`}>Launchpad &#8250; <span>{saleInfo.symbol}</span> </p>
                            <div className={`col-lg-8`}>
                                <div className={`${cascading.pfsec}`}>
                                    <img src={saleInfo.logo} alt='img' className={`img-fluid ${cascading.pfimg}`} />
                                    <p className={`${cascading.name}`}>{saleInfo.symbol}</p>
                                </div>
                                <p className={`${cascading.desc} mt-3`}>
                                    {saleInfo.description}
                                    {/* The GMBL token is the native token of gmbl.computer, the first decentralized casino where all casino earnings go to users who stake the token, xGMBL.
                                The GMBL roadmap includes more games, a sportsbook, and slots - aiming to offer all the games traditional casinos offer, while sharing all profits with
                                stakers of $xGMBL. */}
                                </p>
                                <div>
                                    <Link to={saleInfo && saleInfo?.social && saleInfo?.social[0]} className={`${cascading.extlink}`} target='_blank'><FaExternalLinkAlt fill='#fff' />  Visit {saleInfo.symbol}</Link>
                                </div>
                            </div>
                            <div className={`offset-lg-1 col-lg-3 text-center`}>
                                <img src={saleInfo.banner} alt='img' className={`img-fluid ${cascading.productimg}`} />
                            </div>


                        </div>
                        {/* <div className={` ${cascading.inforow} mt-4 mx-0`}>
                            <div className={` ${cascading.svgsec}`}>
                                <IoIosInformationCircleOutline fill='#5ab437' />
                            </div>
                            <div>
                                <p className={`${cascading.infodesc}`}> Please ensure you understand the public sale mechanics and terms before proceeding, deposited amounts CANNOT be withdrawn. The token will be sold for a fully diluted valuation (FDV) of 15M, fixing a $0.15 price per GMBL. Your allocation will
                                    be made up of 65% GMBL and 35% xGMBL, its escrowed counterpart.</p>
                            </div>

                        </div> */}
                        <div className={`row ${cascading.statusrow} mt-4 `}>
                            <div className={!isUpcoming(saleInfo.startTime) ? `col-lg-3 mb-3 ${cascading.disabledclick} ${cascading.progressclick}` : `col-lg-3 mb-3  ${cascading.progressclick}`}
                                onClick={() => {
                                    if (isUpcoming(saleInfo.startTime)) {
                                        setProgress(1)
                                    }
                                }}>
                                <div className={progress == 1 ? `${cascading.statusline} ${cascading.active}` : `${cascading.statusline}`}></div>
                                <p className={`${cascading.statuslabel} `}>Whitelist stage</p>
                            </div>


                            <div className={!isSaleLive(saleInfo.startTime, saleInfo.endTime, saleInfo.isPresaleOpen) ? `col-lg-3 mb-3 ${cascading.disabledclick} ${cascading.progressclick}` : `col-lg-3 mb-3  ${cascading.progressclick}`}
                                onClick={() => {
                                    if (isSaleLive(saleInfo.startTime, saleInfo.endTime, saleInfo.isPresaleOpen)) {
                                        setProgress(2)
                                    }
                                }
                                }>
                                <div className={progress == 2 ? `${cascading.statusline} ${cascading.active}` : `${cascading.statusline}`}></div>
                                <p className={`${cascading.statuslabel}`}>Public stage</p>
                            </div>


                            <div className={!isSaleEnd ? `col-lg-3 mb-3 ${cascading.disabledclick} ${cascading.progressclick}` : `col-lg-3 mb-3  ${cascading.progressclick}`}
                                onClick={() => {
                                    if (isSaleEnd && saleInfo.isClaimable == false) {
                                        setProgress(3)
                                    }
                                }}>
                                <div className={progress == 3 ? `${cascading.statusline} ${cascading.active}` : `${cascading.statusline}`}></div>
                                <p className={`${cascading.statuslabel}`}>End</p>
                            </div>


                            <div className={!saleInfo.isClaimable == true ? `col-lg-3 mb-3 ${cascading.disabledclick} ${cascading.progressclick}` : `col-lg-3 mb-3  ${cascading.progressclick}`}
                                onClick={() => {
                                    if (saleInfo.isClaimable == true) {
                                        setProgress(4)
                                    }
                                }}>
                                <div className={progress == 4 ? `${cascading.statusline} ${cascading.active}` : `${cascading.statusline}`}></div>
                                <p className={`${cascading.statuslabel} `}>Claims</p>
                            </div>

                        </div>

                        {
                            progress == 1 &&
                            <>
                                <div className={`row ${cascading.pricesec} mt-4 mb-4`}>
                                    <div className='col-12'>
                                        <div className={`d-flex align-items-center justify-content-between flex_col_mob ${cascading.leftsec}`}>
                                            <p className={`${cascading.name}`}>Remaining time</p>

                                            <p className={`${cascading.statusname}`}></p>


                                            {saleInfo && isUpcoming(saleInfo.startTime) ?
                                                <p className='mb-0 text-center d-flex align-items-center'>
                                                    <span className='pe-1 text-white'>Sale Starts In</span>

                                                    <span className='pe-1'></span>  <Countdown
                                                        date={Date.now() + (UpcomingDiffernce(saleInfo.startTime))}
                                                        renderer={renderer}
                                                    />
                                                </p> :
                                                saleInfo && isSaleLive(saleInfo.startTime, saleInfo.endTime, saleInfo.isPresaleOpen) ?
                                                    <p className='mb-0 text-center d-flex align-items-center'>
                                                        <span className='pe-1 text-white'>Sale Ends In</span>


                                                        <span className='pe-1'></span>  <Countdown
                                                            date={Date.now() + Salediffernce(saleInfo?.endTime)}
                                                            renderer={renderer}
                                                        />
                                                    </p> : <p className='mb-0 text-center d-flex align-items-center'>

                                                        <span className='pe-1 text-white'>Sale Ended</span>
                                                        <span className='pe-1 text-white'>Not Live</span>
                                                    </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className={`row ${cascading.balancesec}`}>

                                    {detailcard.map((e) => (

                                        <div className={`col-lg-3 mb-4`} >
                                            <Balancecard datas={e} />

                                        </div>


                                    ))}


                                </div>



                                <div className='row'>
                                    <div className='col-12 col-md-6 col-lg-6 mb-3'>

                                        <div className={`row ${cascading.pricesec} mt-4 w-100`}>
                                            <div className={`col-lg-12 ${cascading.leftsec}`}>
                                                <p className={`${cascading.statusname}`}>Closed - Claims will open soon</p>
                                                {/* <p className={`${cascading.name}`}>TBA</p> */}
                                                <div className={`${cascading.valuerows}`}>
                                                    <div className={`row ${cascading.valueinner}`}>
                                                        <div className={`col-8`}>
                                                            <p className={`${cascading.value}`}>
                                                                ${roundToSignificant(totalRaisedUsd, 6)}
                                                                {totalRaisedUsd > 1000 ? 'K' : totalRaisedUsd > 100000 ? 'L' : totalRaisedUsd > 1000000 ? 'M' : ''}/
                                                                {toFixedNumber(saleInfo.hardCap / 10 ** 18)}
                                                                {toFixedNumber(saleInfo.hardCap / 10 ** 18) > 1000 ? 'K' : toFixedNumber(saleInfo.hardCap / 10 ** 18) > 100000 ? 'L' : toFixedNumber(saleInfo.hardCap / 10 ** 18) > 1000000 ? 'M' : ''}
                                                                {buyToken}</p>
                                                            <p className={`${cascading.labelvalue}`}>Total raised / Hardcap</p>
                                                        </div>
                                                        <div className={`col-4`}>
                                                            <p className={`${cascading.value}`}>${roundToSignificant(salePrice, 6)}</p>
                                                            <p className={`${cascading.labelvalue}`}>{saleInfo.name} price</p>
                                                        </div>
                                                    </div>
                                                    <div className={`row ${cascading.valueinner}`}>
                                                        <div className={`col-8`}>
                                                            <p className={`${cascading.value}`}>${roundToSignificant(circMarketCap, 6)}   {circMarketCap > 1000 ? 'K' : circMarketCap > 100000 ? 'L' : circMarketCap > 1000000 ? 'M' : ''}</p>
                                                            <p className={`${cascading.labelvalue}`}>Circ. marketcap</p>
                                                        </div>
                                                        <div className={`col-4`}>
                                                            <p className={`${cascading.value}`}>${FDV}  {FDV > 1000 ? 'K' : FDV > 100000 ? 'L' : FDV > 1000000 ? 'M' : ''}</p>
                                                            <p className={`${cascading.labelvalue}`}>FDV</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                    </div>
                                    <div className='col-12 col-md-6 col-lg-6 mb-3'>
                                        {console.log("Stagecard_props", saleInfo, userData, buyToken)}
                                        <WhitelistStagecard buyToken={buyToken} refreshData={() => { refreshData() }} saleInfo={saleInfo} userData={userData}  saleAddress={saleInfo.saleAddress} />
                                    </div>
                                    {/* <div className='col-12 col-md-6 col-lg-7 mb-3'>
                                        <Chartcard />
                                    </div> */}
                                </div>

                            </>

                        }

                        {
                            progress == 2 &&
                            <>
                                <div className={`row ${cascading.pricesec} mt-4 mb-4`}>
                                    <div className='col-12'>
                                        <div className={`d-flex align-items-center justify-content-between ${cascading.leftsec}`}>
                                            <p className={`${cascading.name}`}>Remaining time</p>

                                            <p className={`${cascading.statusname}`}></p>
                                            {saleInfo && isUpcoming(saleInfo.startTime) ?
                                                <p className='mb-0 text-center d-flex align-items-center'>
                                                    <span className='pe-1 text-white'>Sale Starts In</span>

                                                    <span className='pe-1'></span>  <Countdown
                                                        date={Date.now() + (UpcomingDiffernce(saleInfo.startTime))}
                                                        renderer={renderer}
                                                    />
                                                </p> :
                                                saleInfo && isSaleLive(saleInfo.startTime, saleInfo.endTime, saleInfo.isPresaleOpen) ?
                                                    <p className='mb-0 text-center d-flex align-items-center'>
                                                        <span className='pe-1 text-white'>Sale Ends In</span>


                                                        <span className='pe-1'></span>  <Countdown
                                                            date={Date.now() + Salediffernce(saleInfo?.endTime)}
                                                            renderer={renderer}
                                                        />
                                                    </p> : <p className='mb-0 text-center d-flex align-items-center'>

                                                        <span className='pe-1'>Sale Ended</span>
                                                        <span className='pe-1'>Not Live</span>
                                                    </p>}
                                        </div>




                                    </div>
                                </div>

                                <div className={`row ${cascading.balancesec}`}>
                                    {detailcard.map((e) => (

                                        <div className={`col-lg-3 mb-4`} >
                                            <Balancecard datas={e} />

                                        </div>

                                    ))}


                                </div>

                                <div className='row'>
                                    <div className='col-12 col-md-6 col-lg-5 mb-3'>
                                        <Stagecard iswhitelist={true} buyToken={buyToken} refreshData={() => { refreshData() }} saleInfo={saleInfo} userData={userData}/>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-7 mb-3'>
                                        <Chartcard />
                                    </div>
                                </div>

                            </>

                        }


                        {
                            progress == 3 &&

                            <div className={`row ${cascading.pricesec} mt-4`}>
                                <div className={`col-lg-4 ${cascading.leftsec}`}>
                                    <p className={`${cascading.statusname}`}>Closed - Claims will open soon</p>
                                    {/* <p className={`${cascading.name}`}>TBA</p> */}
                                    <div className={`${cascading.valuerows}`}>
                                        <div className={`row ${cascading.valueinner}`}>
                                            <div className={`col-8`}>
                                                <p className={`${cascading.value}`}>
                                                    ${roundToSignificant(totalRaisedUsd, 6)}
                                                    {totalRaisedUsd > 1000 ? 'K' : totalRaisedUsd > 100000 ? 'L' : totalRaisedUsd > 1000000 ? 'M' : ''}/
                                                    {toFixedNumber(saleInfo.hardCap / 10 ** 18)}
                                                    {toFixedNumber(saleInfo.hardCap / 10 ** 18) > 1000 ? 'K' : toFixedNumber(saleInfo.hardCap / 10 ** 18) > 100000 ? 'L' : toFixedNumber(saleInfo.hardCap / 10 ** 18) > 1000000 ? 'M' : ''}
                                                    {buyToken}</p>
                                                <p className={`${cascading.labelvalue}`}>Total raised / Hardcap</p>
                                            </div>
                                            <div className={`col-4`}>
                                                <p className={`${cascading.value}`}>${roundToSignificant(salePrice, 6)}</p>
                                                <p className={`${cascading.labelvalue}`}>{saleInfo.name} price</p>
                                            </div>
                                        </div>
                                        <div className={`row ${cascading.valueinner}`}>
                                            <div className={`col-8`}>
                                                <p className={`${cascading.value}`}>${roundToSignificant(circMarketCap, 6)}   {circMarketCap > 1000 ? 'K' : circMarketCap > 100000 ? 'L' : circMarketCap > 1000000 ? 'M' : ''}</p>
                                                <p className={`${cascading.labelvalue}`}>Circ. marketcap</p>
                                            </div>
                                            <div className={`col-4`}>
                                                <p className={`${cascading.value}`}>${FDV}  {FDV > 1000 ? 'K' : FDV > 100000 ? 'L' : FDV > 1000000 ? 'M' : ''}</p>
                                                <p className={`${cascading.labelvalue}`}>FDV</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {console.log(saleInfo, 'saleInfo1')}
                                <div className={`offset-lg-1 col-lg-7`}>
                                    {saleInfo && <Endcard saleInfo={saleInfo}  buyToken={buyToken}/>}

                                </div>

                            </div>
                        }


                        {
                            progress == 4 &&

                            <div className={`row ${cascading.pricesec} mt-4`}>
                                <div className={`col-lg-4 ${cascading.leftsec}`}>
                                    <p className={`${cascading.statusname}`}>Ended</p>
                                    <p className={`${cascading.name}`}>Contribution are now claimble</p>
                                    <div className={`${cascading.valuerows}`}>
                                        <div className={`row ${cascading.valueinner}`}>
                                            <div className={`col-8`}>
                                                <p className={`${cascading.value}`}>
                                                    ${roundToSignificant(totalRaisedUsd, 6)}
                                                    {totalRaisedUsd > 1000 ? 'K' : totalRaisedUsd > 100000 ? 'L' : totalRaisedUsd > 1000000 ? 'M' : ''}/
                                                    {toFixedNumber(saleInfo.hardCap / 10 ** 18)}
                                                    {toFixedNumber(saleInfo.hardCap / 10 ** 18) > 1000 ? 'K' : toFixedNumber(saleInfo.hardCap / 10 ** 18) > 100000 ? 'L' : toFixedNumber(saleInfo.hardCap / 10 ** 18) > 1000000 ? 'M' : ''}
                                                    {buyToken}</p>
                                                <p className={`${cascading.labelvalue}`}>Total raised / Hardcap</p>
                                            </div>
                                            <div className={`col-4`}>
                                                <p className={`${cascading.value}`}>${roundToSignificant(salePrice, 6)}</p>
                                                <p className={`${cascading.labelvalue}`}>{saleInfo.name} price</p>
                                            </div>
                                        </div>
                                        <div className={`row ${cascading.valueinner}`}>
                                            <div className={`col-8`}>
                                                <p className={`${cascading.value}`}>${roundToSignificant(circMarketCap, 6)}   {circMarketCap > 1000 ? 'K' : circMarketCap > 100000 ? 'L' : circMarketCap > 1000000 ? 'M' : ''}</p>
                                                <p className={`${cascading.labelvalue}`}>Circ. marketcap</p>
                                            </div>
                                            <div className={`col-4`}>
                                                <p className={`${cascading.value}`}>${FDV}  {FDV > 1000 ? 'K' : FDV > 100000 ? 'L' : FDV > 1000000 ? 'M' : ''}</p>
                                                <p className={`${cascading.labelvalue}`}>FDV</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {console.log(saleInfo, 'saleInfo1')}
                                <div className={`offset-lg-1 col-lg-7`}>
                                    {saleInfo && <Claimcard saleInfo={saleInfo}  buyToken={buyToken}/>}

                                </div>

                            </div>
                        }

                        <div className={`${cascading.stagesec} mt-4`}>
                            <div className={`${cascading.toprow}`}>
                                <p className={`${cascading.label}`}>Info</p>
                                <p className={`${cascading.labeldesc}`}>Understanding the Sale Process</p>
                            </div>
                            <div className={`row`}>
                                {stagedetails.map((e) => (
                                    <div className={`col-md-6 col-lg-4`}>
                                        <div className={`${cascading.stagedetail}`}>
                                            <img src={saleInfo.logo} alt='img' className={`img-fluid ${cascading.stageimgs}`} />
                                            <p className={`${cascading.stagename}`}>{e.names}</p>
                                            {e.desc.map((e) => (
                                                <p className={`${cascading.stagedesc}`} >{e.para}</p>
                                            ))}



                                        </div>

                                    </div>
                                ))}


                            </div>

                        </div>
                        <div className={` ${cascading.inforow} ${cascading.warning} mt-4 mx-0`}>
                            <div className={` ${cascading.svgsec}`}>
                                <IoIosInformationCircleOutline fill='#FD2B2B' />
                            </div>
                            <div>
                                <p className={`${cascading.infodesc}`}> WasabiSwap is a suite of decentralized contracts built to support Arbitrum native builders. As a permissionless protocol, WasabiSwap bears no responsibility for any tokens purchased using its contracts. All users are taking full responsibility that they are aware of the
                                    relevant risks involved, and that they are participating for a token that is completely independent and not associated with WasabiSwap in any way. Social media posts and visible information on the WasabiSwap app in no way counts as an endorsement of a protocol by
                                    the WasabiSwap team, and nothing posted or shared in any WasabiSwap media is a recommendation or financial advice.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </div>
        </>
    )
}

