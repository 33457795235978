import { CURRENT_CHAIN_ID, UseWeb3 ,useWeb3} from "./useWeb3"
import BigNumber from "bignumber.js"

import positionABI from '../config/abi/positionHelper.json'
import contractAddress from '../config/constant/contract'

//lib
import { consolelog } from "../lib/consolelog"
import { getNFTPoolList, getWalletAddress } from "../lib/localStorage"

//hooks
import { EstGas } from "./useCommon"
import { multicall } from "./useMultiCall"
import { toFixedNumber } from "../lib/toFixedOf"
import { ZEROTH_ADDRESS } from "../config/env"
import { GetuserPositions } from "./useNFTpool"

//store
import store from "../store";

export const getPositionHelperAddress  =()=>{
    try{
        const CHAINID =  CURRENT_CHAIN_ID()
        let PositionHelperAddress = contractAddress.positionhelper[`${CHAINID}`]
        return PositionHelperAddress
    }catch(err){
        consolelog('getPositionHelperAddress__err',err,true)
    }
}

export const GetPositionHelpercontract = async()=>{
    const web3 = await useWeb3()
    try{
        let contract =  new web3.eth.Contract(positionABI,getPositionHelperAddress())
        return contract
    }catch(err){
        consolelog('usePositionHelpercontract__Err',err,true)
        return false
    }
}

export const AddLiquidityAndCreatePosition = async(tokenA,tokenB,amountADesired,amountBDesired,amountAMin,amountBMin,deadline,to,nftPool,lockDuration)=>{
    try{
        const web3 = await useWeb3();
        console.log(tokenA,tokenB,amountADesired,amountBDesired,amountAMin,amountBMin,deadline,to,nftPool,lockDuration,'AddLiquidityAndCreatePosition')
        const contract = await GetPositionHelpercontract()
        const account = getWalletAddress()
        let params = [web3.utils.toChecksumAddress(tokenA), web3.utils.toChecksumAddress(tokenB), amountADesired, amountBDesired, amountAMin, amountBMin,deadline, to,nftPool ,lockDuration]
        const { gasLimit, gasPrice } = await EstGas(params, positionABI, getPositionHelperAddress(), 'addLiquidityAndCreatePosition', account)
        const data = await contract.methods.addLiquidityAndCreatePosition(web3.utils.toChecksumAddress(tokenA), web3.utils.toChecksumAddress(tokenB), amountADesired, amountBDesired, amountAMin, amountBMin, deadline,web3.utils.toChecksumAddress(to),nftPool,lockDuration).send({ from: getWalletAddress(), gasLimit: gasLimit, gasPrice: gasPrice })

        if(data){
            let poolList = getNFTPoolList()
            GetuserPositions(poolList,store.dispatch)
            return data
        }
    }catch(err){
        console.log(err,'AddLiquidityAndCreatePosition__err')
        return false
    }
}

export const AddLiquidityETHAndCreatePosition = async(token,amountTokenDesired,amountTokenMin,amountETHMin,deadline,to,nftPool,lockDuration,amountETH)=>{
    try{
        const web3 = await useWeb3();
        console.log(token,amountTokenDesired,amountTokenMin,amountETHMin,deadline,to,nftPool,lockDuration,amountETH,'AddLiquidityETHAndCreatePosition')
        const contract = await GetPositionHelpercontract()
        const account = getWalletAddress()
        let params = [web3.utils.toChecksumAddress(token),amountTokenDesired , amountTokenMin, amountETHMin, deadline, to,nftPool ,lockDuration]
        const { gasLimit, gasPrice } = await EstGas(params, positionABI, getPositionHelperAddress(), 'addLiquidityETHAndCreatePosition', account)
        const data = await contract.methods.addLiquidityETHAndCreatePosition(web3.utils.toChecksumAddress(token),amountTokenDesired , amountTokenMin, amountETHMin, deadline, to,nftPool ,lockDuration).send({ from: getWalletAddress(),value: amountETH.toString(), gasLimit: gasLimit, gasPrice: gasPrice })
        if(data){
            let poolList = getNFTPoolList()
            GetuserPositions(poolList,store.dispatch)
            return data
        }
    }catch(err){
        console.log(err,'AddLiquidityETHAndCreatePosition__err')
        return false
    }
}