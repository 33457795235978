import { isEmpty } from "../lib/isEmpty";

export const validateAllocation = (value,xtokenbalance) => {
    console.log("validateAllocation_value",value,value.amount)
    let errors = {};

    if (isEmpty(value.amount)) {
        errors.amount = "Amount field is required"
    }else if(parseFloat(value.amount) > parseFloat(xtokenbalance) ){
        errors.amount = "Insufficient balance"
    }

    return errors;
}


export const validateDeallocation = (value) => {
    console.log("validateAllocation_value",value,value.amount, value.allocBal)
    let errors = {};

    if (isEmpty(value.amount)) {
        errors.amount = "Amount field is required"
    } 
    else if (value.amount > value.allocBal) {
        errors.amount = "Amount should not be greater than Allocate balance"
    }

    return errors;
}
